import { DateBox } from 'devextreme-react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FranjaHoraria from '../../../01.-Domain/Entities/FranjaHoraria';
import './ConfiguracionView.scss';

interface ControlHorarioProps {
  horario: FranjaHoraria;
}

const ControlHorario: React.FC<ControlHorarioProps> = (props) => {
  return (
    <Row>
      <Col md={6}>
        Inicio: <DateBox disabled defaultValue={props.horario.inicio} name="horaInicioPlanta" type="time" />
      </Col>
      <Col md={6}>
        Fin: <DateBox disabled defaultValue={props.horario.fin} name="horaFinPlanta" type="time" />
      </Col>
    </Row>
  );
};

export default ControlHorario;
