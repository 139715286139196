import { DateBox } from 'devextreme-react';

export interface DateBoxProps {
  id: number;
  nombre: string;
  defaultValue: string | Date;
  disabled?: boolean;
  onValueChange: (e: any) => void;
  children?: any;
  tipo: 'date' | 'time' | 'datetime';
  texto?: string;
}

function DateBoxComponent(props) {
  return (
    <>
      {props.texto}
      <DateBox
        name={props.nombre}
        disabled={props.disabled}
        onValueChanged={(e: any) => props.onValueChange(e)}
        defaultValue={props.defaultValue}
        type={props.tipo}
      />
    </>
  );
}

export default DateBoxComponent;
