import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import notFound from '../../../assets/images/PageNotFound.png';

const NoMatchView: React.FC = () => (
  <>
    <Row className="justify-content-center">
      <Col md={5}>
        <img src={notFound} alt="NotFoundPage" title="Not Found" />
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col className="text-center">
        <Link to="/">
          <Button variant="success" size="lg">
            Ir a Inicio
          </Button>
        </Link>
      </Col>
    </Row>
  </>
);

export default NoMatchView;
