import Obra from '../../01.-Domain/Entities/Obra';
import Planificacion from '../../01.-Domain/Entities/Planificacion';
import Trabajo from '../../01.-Domain/Entities/Trabajo';
import Way from '../../01.-Domain/Entities/Way';
import ITrabajoService from '../ServicesInterfaces/ITrabajoService';
import GISService from './GISService';
import PlanificacionService from './PlanificacionService';
import { plantaService } from './PlantaService';

class TrabajoService implements ITrabajoService {
  private _trabajoPlanificado: Trabajo[];

  public get trabajoPlanificado(): Trabajo[] {
    return this._trabajoPlanificado;
  }

  public set trabajoPlanificado(v: Trabajo[]) {
    this._trabajoPlanificado = v;
  }

  constructor() {
    this.trabajoPlanificado = [];
  }

  /**
   * Elimina un trabajo de la lista y devuelve el elemento borrado
   * @param trabajo Trabajo
   * @returns Trabajo
   */
  async EliminarTrabajo(trabajo: Trabajo): Promise<Trabajo[]> {
    const tr = this.trabajoPlanificado.find((x: Trabajo) => x.tpObraId === trabajo.obraId);
    if (tr) {
      tr.trabajoPredecesor = null;
      tr.tpObraDescripcion = '';
      tr.tpObraId = null;
    }

    return this.trabajoPlanificado.splice(this.trabajoPlanificado.indexOf(trabajo), 1);
  }

  async ActualizarTiempoTransporte(obra) {
    const way: Way = new Way(plantaService.plantaActual.location, obra.location);
    return await GISService.GetETA(way);
  }

  /**
   * Crea un objeto Trabajo por cada obra
   * @param obras Obra[]
   * @returns Promise<Boolean>
   */
  async AddTrabajo(obras: Obra[]): Promise<Trabajo[]> {
    for (let index = 0; index < obras.length; index += 1) {
      let trabajo = new Trabajo();

      if (obras[index].location) {
        trabajo.tiempoTransporteMinutos = await this.ActualizarTiempoTransporte(obras[index]);
      }

      const trabajoOrigen = this.trabajoPlanificado.find((x: Trabajo) => x.obra.id === obras[index].id);
      if (trabajoOrigen) {
        // existe ya alguna obra, por lo que hay que copiar los datos
        trabajo = trabajoOrigen;
        trabajo.ordenTrabajoPredecesor = null;
        trabajo.trabajoPredecesor = null;
        trabajo.tpObraDescripcion = null;
        trabajo.tpObraId = null;
        trabajo.tramo = trabajoOrigen.tramo;
      } else {
        trabajo.obra = obras[index];
        trabajo.mezcla = obras[index].mezcla;
      }

      this.trabajoPlanificado.push(new Trabajo(trabajo));
    }

    for (let index = 0; index < this.trabajoPlanificado.length; index += 1) {
      this.trabajoPlanificado[index].id = index;
    }

    if (!PlanificacionService.planificacionActual) {
      PlanificacionService.planificacionActual = new Planificacion();
    }

    PlanificacionService.planificacionActual.trabajos = this.trabajoPlanificado;

    return new Promise((resolve) => {
      resolve(this.trabajoPlanificado);
    });
  }
}

export default new TrabajoService();
