import BaseEntity from './Base/BaseEntity';

export default class Mezcla extends BaseEntity {
  idOrigen: string;

  descripcion: string;

  granularidad: number;

  reciclado: number;

  tipoarido: number;

  constructor(obj?: any) {
    super();

    if (obj !== undefined) {
      this.id = obj.id;
      this.idOrigen = obj.idOrigen;
      this.descripcion = obj.descripcion;
      this.granularidad = obj.granularidad;
      this.reciclado = obj.reciclado;
      this.tipoarido = obj.tipoarido;
    } else {
      this.idOrigen = '';
      this.descripcion = '';
      this.granularidad = 0;
      this.reciclado = 0;
      this.tipoarido = 0;
    }
  }
}
