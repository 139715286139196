import EventoVehiculo from '../../01.-Domain/Entities/EventoVehiculo';
import TipoVehiculo from '../../01.-Domain/Entities/TipoVehiculo';
import VehiculoRepository from '../../03.-Infraestructure/Repositories/VehiculoRepository';
import IVehiculoService from '../ServicesInterfaces/IVehiculoService';

class VehiculoService implements IVehiculoService {
  async InsertarEvento(matricula: string, evento: EventoVehiculo): Promise<Boolean> {
    await VehiculoRepository.InsertarEvento(matricula, evento);

    return true;
  }

  async ActualizarVehiculos(vehiculos: TipoVehiculo[]) {
    await VehiculoRepository.ActualizarVehiculos(vehiculos);

    return true;
  }
}

export default new VehiculoService();
