import dayjs from 'dayjs';
import Graficas from '../../01.-Domain/Entities/Graficas/Graficas';
import Marcador from '../../01.-Domain/Entities/Marcador';
import Operacion from '../../01.-Domain/Entities/Operacion';
import PlanificacionOptimizada from '../../01.-Domain/Entities/PlanificacionOptimizada';
import VehiculoGenerico from '../../01.-Domain/Entities/VehiculoGenerico';
import { EstadoOperacionEnum } from '../../01.-Domain/Enums/EstadoOperacionEnum';
import { EtapaEnum } from '../../01.-Domain/Enums/EtapaEnum';
import PlanificacionRepository from '../../03.-Infraestructure/Repositories/PlanificacionRepository';
import PlantaRepository from '../../03.-Infraestructure/Repositories/PlantaRepository';
import SeguimientoRepository from '../../03.-Infraestructure/Repositories/SeguimientoRepository';
import ConfigProvider from '../../Config/ConfigProvider';
import ISeguimientoService from '../ServicesInterfaces/ISeguimientoService';
import ToastService from './Base/ToastService';
import IndicadoresService from './IndicadoresService';
import { plantaService } from './PlantaService';
const _ = require('lodash');

export type ToneladasViajeType = {
  ToneladasTotal: number;
  ToneladasExtendidas: number;
  ToneladasPendientes: number;
  ToneladasEnTransporte: number;
};

export type ObrasSeguimiento = {
  id: number;
  idOrigen: string;
  descripcion: string;
  viajeToneladas: number;
};

class SeguimientoService implements ISeguimientoService {
  private _planificacionEnSeguimiento: PlanificacionOptimizada;

  public get planificacionEnSeguimiento(): PlanificacionOptimizada {
    return this._planificacionEnSeguimiento;
  }

  public set planificacionEnSeguimiento(v: PlanificacionOptimizada) {
    this._planificacionEnSeguimiento = v;
  }

  private _vehiculos: VehiculoGenerico[];
  public get vehiculos(): VehiculoGenerico[] {
    return this._vehiculos;
  }
  public set vehiculos(v: VehiculoGenerico[]) {
    this._vehiculos = v;
  }

  private _graficasPlanSeguimiento: Graficas;
  public get graficasPlanSeguimiento(): Graficas {
    return this._graficasPlanSeguimiento;
  }
  public set graficasPlanSeguimiento(v: Graficas) {
    this._graficasPlanSeguimiento = v;
  }

  private _marcadoresObras: Marcador[];
  public get marcadoresObras(): Marcador[] {
    return this._marcadoresObras;
  }
  public set marcadoresObras(v: Marcador[]) {
    this._marcadoresObras = v;
  }

  private _marcadoresVehiculos: Marcador[];
  public get marcadoresVehiculos(): Marcador[] {
    return this._marcadoresVehiculos;
  }
  public set marcadoresVehiculos(v: Marcador[]) {
    this._marcadoresVehiculos = v;
  }

  private _marcadoresPlanta: Marcador[];
  public get marcadoresPlanta(): Marcador[] {
    return this._marcadoresPlanta;
  }
  public set marcadoresPlanta(v: Marcador[]) {
    this._marcadoresPlanta = v;
  }

  private _obrasSeguimiento: ObrasSeguimiento[];
  public get obrasSeguimiento(): ObrasSeguimiento[] {
    return this._obrasSeguimiento;
  }
  public set obrasSeguimiento(v: ObrasSeguimiento[]) {
    this._obrasSeguimiento = v;
  }

  public get obras(): ObrasSeguimiento[] {
    if (!this.planificacionEnSeguimiento) {
      return [];
    }

    const obras: ObrasSeguimiento[] = [];
    const operacionesUnicas: Operacion[] = [];

    this.planificacionEnSeguimiento.operaciones?.forEach((op: Operacion) => {
      if (!operacionesUnicas.find((x: Operacion) => x.obraId === op.obraId)) {
        obras.push({
          id: op.obraId,
          idOrigen: op.obraIdOrigen,
          descripcion: op.obraDescripcion,
          viajeToneladas: op.viajeToneladas
        });
        operacionesUnicas.push(op);
      }
    });

    return obras;
  }

  constructor() {
    this.planificacionEnSeguimiento = null;
    this.graficasPlanSeguimiento = null;
    this.vehiculos = [];
    this.marcadoresObras = [];
    this.marcadoresVehiculos = [];
    this.marcadoresPlanta = [];
    this.obrasSeguimiento = [];
  }

  async GetGraficasSeguimiento(): Promise<void> {
    const res = await PlanificacionRepository.GetGraficas(this.planificacionEnSeguimiento.id);
    this.graficasPlanSeguimiento = new Graficas(res);
  }

  async EliminarVehiculosPlan(idVehiculos: number[]): Promise<boolean> {
    try {
      const res = await SeguimientoRepository.EliminarVehiculoPlan(
        this.planificacionEnSeguimiento.id,
        idVehiculos
      );

      await this.GetPlanificacionEnSeguimiento();

      return res;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async GetVehiculosPlan(): Promise<VehiculoGenerico[]> {
    try {
      const res = await PlantaRepository.GetVehiculosPlanificacion(plantaService.plantaActual.id);
      this.vehiculos = [];
      res.forEach((vehiculo: VehiculoGenerico) => {
        this.vehiculos.push(new VehiculoGenerico(vehiculo));
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async GetPlanificacionEnSeguimiento(obraSeleccionada?: number): Promise<PlanificacionOptimizada> {
    try {
      this.LimpiarTrasFinalizar();
      if (!plantaService.plantaActual) {
        await plantaService.GetAll();
      }
      const res = await PlantaRepository.GetPlanificacionEnSeguimiento(plantaService.plantaActual.id);

      if (typeof res !== 'string') {
        if (obraSeleccionada) {
          res.operaciones = res.operaciones.filter((x: Operacion) => x.obraId === obraSeleccionada);
        }

        this.planificacionEnSeguimiento = new PlanificacionOptimizada(res);
        this.planificacionEnSeguimiento.operaciones.sort((a: Operacion, b: Operacion) =>
          a.inicioProgramado < b.inicioProgramado ? -1 : 1
        );

        this.planificacionEnSeguimiento.operaciones?.forEach((op: Operacion) => {
          if (!this.obrasSeguimiento.find((x: ObrasSeguimiento) => x.idOrigen === op.obraIdOrigen)) {
            this.obrasSeguimiento.push({
              id: op.obraId,
              idOrigen: op.obraIdOrigen,
              descripcion: op.obraDescripcion,
              viajeToneladas: op.viajeToneladas
            });
          }
        });

        await this.PostObtenerPlan();

        return res;
      }
    } catch (error) {
      console.log(error);
      throw new Error('Error cargando la planificación');
    }
  }

  /**
   * Obtiene los datos para las gráficas de toneladas
   * Si se pasa el IdOrigen de una obra, se obtienen los de la obra
   * si no, se obtiene los de la planificación completa
   * @param obraIdOrigen string
   * @returns ToneladasViajeType
   */
  ObtenerDatosGraficaToneladas(obraIdOrigen?: string): ToneladasViajeType {
    const toneladas = {} as ToneladasViajeType;
    toneladas.ToneladasTotal = 0;
    toneladas.ToneladasPendientes = 0;
    toneladas.ToneladasExtendidas = 0;
    toneladas.ToneladasEnTransporte = 0;

    let operaciones: Operacion[] = [];

    if (obraIdOrigen) {
      const obras = this.planificacionEnSeguimiento?.operaciones.filter(
        (x: Operacion) => x.obraIdOrigen === obraIdOrigen
      );
      operaciones = _.groupBy(obras, 'viajeId');
    } else {
      operaciones = _.groupBy(this.planificacionEnSeguimiento?.operaciones, 'viajeId');
    }

    _.forEach(operaciones, (op) => {
      toneladas.ToneladasTotal += op[0].viajeToneladas;

      var operacionExtendido = op.find(
        (x: Operacion) =>
          EtapaEnum[x.etapa] === EtapaEnum.Extendido &&
          EstadoOperacionEnum[x.estado] !== EstadoOperacionEnum.Pendiente
      );
      if (operacionExtendido) toneladas.ToneladasExtendidas += operacionExtendido.viajeToneladas;

      // Toneladas de las operaciones que están pendientes de extender (iniciado o finalizado transporte y pendiente extender)
      var transporteAcabado = op.find(
        (x: Operacion) =>
          EtapaEnum[x.etapa] === EtapaEnum.Transporte &&
          EstadoOperacionEnum[x.estado] !== EstadoOperacionEnum.Pendiente
      );
      var pendienteExtendido = op.find(
        (x: Operacion) =>
          EtapaEnum[x.etapa] === EtapaEnum.Extendido &&
          EstadoOperacionEnum[x.estado] === EstadoOperacionEnum.Pendiente
      );

      if (transporteAcabado && pendienteExtendido) {
        toneladas.ToneladasEnTransporte += op.find(
          (x: Operacion) => EtapaEnum[x.etapa] === EtapaEnum.Transporte
        ).viajeToneladas;
      }
    });

    toneladas.ToneladasPendientes =
      toneladas.ToneladasTotal - toneladas.ToneladasExtendidas - toneladas.ToneladasEnTransporte;

    return toneladas;
  }

  /**
   * Replanifica la planificación
   * @returns Promise<PlanificacionOptimizada>
   */
  async Replanificar(): Promise<PlanificacionOptimizada> {
    try {
      return await SeguimientoRepository.Replanificar(this.planificacionEnSeguimiento.id);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * Vacia y pone a null los objetos una vez finalizada la planificación
   */
  async LimpiarTrasFinalizar() {
    console.log('limpiando');
    this.planificacionEnSeguimiento = null;
    this.marcadoresObras = [];
    this.marcadoresVehiculos = [];
    this.marcadoresPlanta = [];
    this.graficasPlanSeguimiento = null;
  }

  /**
   * Una vez obtenida la planificación, obtiene los vehículos, las gráficas y
   * crea los marcadores de obras, vehículos y planta
   * @returns Promise<boolean>
   */
  async PostObtenerPlan(): Promise<boolean> {
    IndicadoresService.CargaIndicadoresSeguimiento(this.planificacionEnSeguimiento);

    await this.GetVehiculosPlan().catch(() => {
      ToastService.error('No se han cargado los vehículos, vuelva a recargar la página');
    });

    await this.GetGraficasSeguimiento().catch(() => {
      ToastService.warning('No se han podido cargar las gráficas de la planificación');
    });

    await this.ObtenerMarcadores();

    return true;
  }

  async ModificarPrioridadTrabajo(idTrabajo: number, prioridad: number): Promise<boolean> {
    try {
      const res = await SeguimientoRepository.ModificarPrioridadTrabajo(
        this.planificacionEnSeguimiento.id,
        idTrabajo,
        prioridad
      );

      await this.GetPlanificacionEnSeguimiento();

      return res;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * Finaliza la planificación actual y vacía los objetos relacionados
   * @returns Promise<boolean>
   */
  async FinalizarPlanificacion(): Promise<boolean> {
    try {
      const res = await SeguimientoRepository.FinalizarPlanificacion(this.planificacionEnSeguimiento.id);
      this.LimpiarTrasFinalizar();
      return res;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * Crea los marcadores de la planta, vehículos y obras
   */
  async ObtenerMarcadores() {
    this.marcadoresObras = [];
    this.marcadoresVehiculos = [];
    this.marcadoresPlanta = [];

    let index = 0;
    const colors = ['red', 'blue', 'brown', 'orange', 'darkGreen', 'gray', 'pink', 'violet', 'green'];

    // Agrupa las operaciones por obra para pintar los marcadores de las obras
    const operacionesAgrupadasPorObra: Operacion[] = _.groupBy(
      this.planificacionEnSeguimiento.operaciones,
      'obraId'
    );

    _.forEach(operacionesAgrupadasPorObra, (operacion) => {
      this.marcadoresObras.push(
        new Marcador(
          index,
          operacion[0].obraLocation
            ? [operacion[0].obraLocation.latitud, operacion[0].obraLocation.longitud]
            : null,
          colors[index],
          null,
          operacion[0].obraIdOrigen
        )
      );

      const operacionesAgrupadasPorViaje = _.groupBy(operacion, (x: Operacion) => x.viajeId);
      _.forEach(operacionesAgrupadasPorViaje, (x: Operacion[]) => {
        const operacionesSinFabricacion = x.filter((x) => EtapaEnum[x.etapa] !== EtapaEnum.Fabricacion);

        /**
         * Obtiene de los viajes el que tenga el transporte iniciado o terminado y además, alguna operacion iniciada o finalizada
         */
        if (
          operacionesSinFabricacion.some(
            (x) =>
              EtapaEnum[x.etapa] === EtapaEnum.Transporte &&
              EstadoOperacionEnum[x.estado] !== EstadoOperacionEnum.Pendiente
          ) &&
          operacionesSinFabricacion.some(
            (x) =>
              // EstadoOperacionEnum[x.estado] === EstadoOperacionEnum.Pendiente &&
              EstadoOperacionEnum[x.estado] !== EstadoOperacionEnum.Finalizada
          )
        ) {
          const vehiculo = this.vehiculos.find(
            (x: VehiculoGenerico) => x.id === operacionesSinFabricacion[0].vehiculoGenerico?.id
          );

          if (
            vehiculo &&
            !this.marcadoresVehiculos.find((x: Marcador) => x.matricula === vehiculo.matricula)
          ) {
            if (
              dayjs(vehiculo.hora).isAfter(
                dayjs().subtract(ConfigProvider.Max_Time_Vehicle_Position, 'minute')
              )
            ) {
              this.marcadoresVehiculos.push(
                new Marcador(
                  index,
                  vehiculo.latitud ? [vehiculo.latitud, vehiculo.longitud] : null,
                  // Si el vehículo está de retorno a la planta, pinta el marcador en negro
                  this.PintaVehiculoSegunEstado(operacion, vehiculo, colors, index),
                  vehiculo.matricula,
                  `Mat: ${vehiculo.matricula} - Obra: ${operacion[0].obraIdOrigen}`
                )
              );
            }
          }
        }
      });

      index++;
    });

    // Rellenar marcadores de planta
    const planta = plantaService.plantaActual;
    this.marcadoresPlanta.push(
      new Marcador(0, [planta.location.latitud, planta.location.longitud], null, null, planta.nombre)
    );
  }

  /**
   * Devuelve el tipo de icono correspondiente
   * @param operacion Operacion[]
   * @param vehiculo VehiculoGenerico
   * @param colors string[]
   * @param index number
   * @returns string
   */
  private PintaVehiculoSegunEstado(
    operacion: Operacion[],
    vehiculo: VehiculoGenerico,
    colors: string[],
    index: number
  ): string {
    const retornoColor = 'black';

    return operacion.filter(
      (x: Operacion) =>
        EtapaEnum[x.etapa] === EtapaEnum.Retorno &&
        this.EtapaIniciada(x.estado) &&
        x.vehiculoGenerico.id === vehiculo.id
    ).length > 0
      ? retornoColor
      : colors[index];
  }

  /**
   * Devuelve true o false dependiendo si la operación está iniciada
   * @param estadoOperacion string
   * @returns boolean
   */
  public EtapaIniciada(estadoOperacion: string): boolean {
    return EstadoOperacionEnum[estadoOperacion] === EstadoOperacionEnum.Iniciada;
  }
}

export default new SeguimientoService();
