import Chart from 'react-apexcharts/src/react-apexcharts';
import { Row, Col } from 'react-bootstrap';
import SerieGraficas from '../../../01.-Domain/Entities/Graficas/SerieGrafica';
import { ObtenerDatosGraficas, ObtenerGraficaToneladas } from './SeriesGrafica';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import SeguimientoService from '../../../02.-Application/Services/SeguimientoService';
import { GraficaEnum } from '../../../01.-Domain/Enums/GraficaEnum';

type Props = {
  graficasTransporte?: SerieGraficas;
  graficasTransporteOriginal?: SerieGraficas;
  graficasExtendido?: SerieGraficas;
  graficasExtendidoOriginal?: SerieGraficas;
  width?: number;
  tab: number;
};

const GraficasComponent: React.FC<Props> = (props) => {
  const location = useLocation();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [path, setPath] = useState(location.pathname);
  const [datosGraficaPlan] = useState(CalcularTmPlanificacion());
  const [datosGraficaObra, setDatosGraficaObra] = useState(CalcularTmObra());

  useEffect(() => {
    setPath(location.pathname);
  }, []);

  useEffect(() => {
    setDatosGraficaObra(CalcularTmObra());
    setLoaded(true);
  }, [props.graficasExtendido]);

  function CalcularTmPlanificacion() {
    const tmPlanificacion = SeguimientoService.ObtenerDatosGraficaToneladas();
    return ObtenerGraficaToneladas(
      tmPlanificacion.ToneladasTotal,
      tmPlanificacion.ToneladasExtendidas,
      tmPlanificacion.ToneladasEnTransporte
    );
  }

  function CalcularTmObra() {
    const tmObra = SeguimientoService.ObtenerDatosGraficaToneladas(props.graficasExtendido?.name);
    return ObtenerGraficaToneladas(
      tmObra.ToneladasTotal,
      tmObra.ToneladasExtendidas,
      tmObra.ToneladasEnTransporte
    );
  }

  return (
    <>
      {loaded && (
        <Row>
          <Col md="12">
            {/* GRAFICA EXTENDIDO DE PLANTA */}
            {path === '/seguimiento' && props.tab === 0 && (
              <>
                <h3>Toneladas Totales a Extender: {Math.trunc(datosGraficaPlan.totales)}</h3>
                <Chart
                  options={datosGraficaPlan.options}
                  series={datosGraficaPlan.series}
                  type="pie"
                  width={250}
                />
              </>
            )}

            {/* GRAFICAS EXTENDIDO DE OBRAS */}
            {path === '/seguimiento' && props.tab === 1 && (
              <>
                {props.graficasExtendido && (
                  <>
                    <h3>
                      Obra: {props.graficasExtendido?.name}. Toneladas Totales a Extender:{' '}
                      {Math.trunc(datosGraficaObra.totales)}
                    </h3>

                    <Chart
                      options={datosGraficaObra.options}
                      series={datosGraficaObra.series}
                      type="pie"
                      width={250}
                    />
                  </>
                )}
              </>
            )}

            {/* GRAFICA DE FABRICACIÓN */}
            {props.graficasTransporte && (
              <Chart
                width="600"
                options={ObtenerDatosGraficas(GraficaEnum.TRANSPORTE, props.graficasTransporte).options}
                series={
                  ObtenerDatosGraficas(
                    GraficaEnum.TRANSPORTE,
                    props.graficasTransporte,
                    props.graficasTransporteOriginal
                  ).series
                }
                type="line"
              />
            )}

            {/* GRAFICA DE EXTENDIDO DE OBRA */}
            {props.graficasExtendido && (
              <Chart
                width="600"
                options={ObtenerDatosGraficas(GraficaEnum.EXTENDIDO, props.graficasExtendido).options}
                series={
                  ObtenerDatosGraficas(
                    GraficaEnum.EXTENDIDO,
                    props.graficasExtendido,
                    props.graficasExtendidoOriginal
                  ).series
                }
                type="line"
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default GraficasComponent;
