import { NumberBox } from 'devextreme-react';

export interface NumberBoxProps {
  id: number;
  nombre: string;
  title: string;
  placeHolder?: string;
  rowClass: string;
  colText: string;
  defaultValue: number;
  min?: number;
  showSpinButton?: boolean;
  onValueChange: (e: any) => void;
  children?: any;
}

function NumberBoxComponent(props) {
  return (
    <NumberBox
      name={props.nombre}
      placeholder={props.placeHolder}
      onValueChanged={(e: any) => props.onValueChange(e)}
      defaultValue={props.defaultValue}
      min={props.min}
      showSpinButtons={props.showSpinButton}>
      {props.children}
    </NumberBox>
  );
}

export default NumberBoxComponent;
