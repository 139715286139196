import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { DateBox } from 'devextreme-react';
import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import FranjaHoraria from '../../../01.-Domain/Entities/FranjaHoraria';
import { ValidacionFechas } from './libs';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export interface Props {
  show: boolean;
  cerrarPopup: () => void;
  GuardarFranja: (franjas: FranjaHoraria[]) => void;
}

const PopupJornada: React.FC<Props> = (props) => {
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setdateEnd] = useState<Date | null>(null);
  const [franjas, setFranjas] = useState<FranjaHoraria[]>([]);

  const IncluirFranja = () => {
    const error = ValidacionFechas(dateStart, dateEnd, franjas);

    if (!error) {
      const fr: FranjaHoraria = {
        id: franjas.length,
        inicio: dateStart,
        fin: dateEnd
      };

      setFranjas(franjas.concat(fr));
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const GetDay = (fr: FranjaHoraria) => (
    <li key={fr.id}>{`${dayjs(fr.inicio).format('HH:mm')} // ${dayjs(fr.fin).format('HH:mm')}`}</li>
  );

  const DevolverFranjas = () => {
    franjas.forEach((franja: FranjaHoraria) => {
      franja.inicio = new Date(franja.inicio);
      franja.fin = new Date(franja.fin);
    });

    props.GuardarFranja(franjas);
    props.cerrarPopup();
  };

  return (
    <Modal
      style={{ top: '10em' }}
      show={props.show}
      onHide={props.cerrarPopup}
      backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Gestión Franjas</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '200px', paddingTop: '2em' }}>
        <Row>
          <Col md={5}>
            <DateBox
              defaultValue={dateStart}
              onValueChange={(date: any) => setDateStart(date)}
              type="time"
            />
          </Col>
          <Col md={5}>
            <DateBox defaultValue={dateEnd} onValueChange={(date: any) => setdateEnd(date)} type="time" />
          </Col>
          <Col md={2} className="mt-2">
            <span
              className="material-icons block green600"
              style={{ cursor: 'pointer' }}
              onClick={IncluirFranja}
              aria-hidden>
              check_circle
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <ol>{franjas.map((fr) => GetDay(fr))}</ol>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={DevolverFranjas}>
          Añadir Franjas y cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupJornada;
