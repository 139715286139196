import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, TextBox, ValidationGroup, Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/data-grid';
import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import AutenticationService from '../../../02.-Application/Services/AutenticationService';
import './login.scss';

type Props = {
  show: boolean;
  cerrarPopup: () => void;
};

/**
 * Si me envia las obras con todos sus viajes, puedo ver si alguna obra tiene un viaje en extendido
 * @param props
 * @returns
 */
const PopupLogin: React.FC<Props> = (props) => {
  const [usuario, setUsuario] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [validatedUser, setValidatedUser] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const texto = 'Iniciar Sesión';

  function handleUsuarioChange(e: any) {
    setUsuario(e.value);
  }

  function handlePasswordChange(e: any) {
    setPassword(e.value);
  }

  async function Acceder(e: any) {
    setOpen(true);
    if (e.preventDefault) {
      e.preventDefault();
    }

    await AutenticationService.Autenticate(usuario, password)
      .then((res: any) => {
        if (!res) {
          setValidatedUser(true);
          return;
        }

        props.cerrarPopup();
      })
      .finally(() => {
        setOpen(false);
      });
  }

  return (
    <Modal onHide={props.cerrarPopup} show={props.show} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{texto}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress size={90} variant="indeterminate" color="primary" />
        </Backdrop>
        <form onSubmit={Acceder}>
          <ValidationGroup>
            <Row className="d-flex justify-content-around">
              <Col md={11} className="my-3">
                <TextBox
                  onValueChanged={handleUsuarioChange}
                  value={usuario}
                  placeholder="Usuario"
                  showClearButton
                  name="usuario">
                  <Validator>
                    <RequiredRule message="Introduzca un nombre" />
                  </Validator>
                </TextBox>
              </Col>
              <Col md={11} className="my-3">
                <TextBox
                  onValueChanged={handlePasswordChange}
                  value={password}
                  showClearButton
                  placeholder="Contraseña"
                  mode="password"
                  name="password">
                  <Validator>
                    <RequiredRule message="Introduzca una contraseña" />
                  </Validator>
                </TextBox>
              </Col>
              {validatedUser && (
                <Col md={11}>
                  <span className="errorLogin">
                    El usuario o la contraseña no son correctos. No se puede entrar en la aplicación
                  </span>
                </Col>
              )}
              <Col md={11} className="mt-4">
                <Button useSubmitBehavior text={texto} type="default" stylingMode="contained" />
              </Col>
            </Row>
          </ValidationGroup>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PopupLogin;
