import dayjs from 'dayjs';
import FranjaHoraria from '../../01.-Domain/Entities/FranjaHoraria';
import Planta from '../../01.-Domain/Entities/Planta';
import PlantaRepository from '../../03.-Infraestructure/Repositories/PlantaRepository';
import IPlantaService from '../ServicesInterfaces/IPlantaService';
import AutenticationService from './AutenticationService';

class PlantaService implements IPlantaService {
  private _plantaActual: Planta;
  public get plantaActual(): Planta {
    return this._plantaActual;
  }
  public set plantaActual(v: Planta) {
    this._plantaActual = v;
  }

  private _plantas: Planta[];
  public get plantas(): Planta[] {
    return this._plantas;
  }
  public set plantas(v: Planta[]) {
    this._plantas = v;
  }

  constructor() {
    this._plantaActual = null;
    this._plantas = [];
  }

  async GetAll(): Promise<Boolean> {
    if (this.plantas.length) {
      return;
    }

    try {
      if (this.plantas.length === 0) {
        this.plantas = [];
        const res: Planta[] = await PlantaRepository.getAll();
        const plantas: Planta[] = [];
        res.forEach((p: Planta) => {
          plantas.push(new Planta(p));
        });

        const habilitadas: Planta[] = AutenticationService.plantasHabilitadas;

        plantas.forEach((pl: Planta) => {
          const habilitada = habilitadas.find((x: Planta) => x.nombre === pl.nombre);
          if (habilitada) {
            pl.instalacion = habilitada.instalacion;
            if (!this.plantas.includes(pl)) {
              this.plantas.push(pl);
            }
          }
        });

        sessionStorage.setItem('plantas', JSON.stringify(plantaService.plantas));
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  AsignarInstalacion(planta: Planta) {
    let plantaHabilitada = AutenticationService.plantasHabilitadas.find((x) => x.nombre === planta.nombre);
    planta.instalacion = plantaHabilitada.instalacion;
  }

  async SeleccionarPlanta(id: number) {
    this.plantaActual = this.plantas.find((x: Planta) => x.id === id);

    this.AsignarInstalacion(this.plantaActual);
    sessionStorage.setItem('plantaActual', JSON.stringify(plantaService.plantaActual));
  }

  async ActualizarPlanta(): Promise<boolean> {
    try {
      const config = this.plantaActual.configuracionPlanta;

      if (dayjs(config.horaInicioMananaObras, 'HH:mm:ss', true).isValid()) {
        config.horaInicioMananaObras = dayjs(config.horaInicioMananaObras).format('HH:mm:ss');
      }

      if (dayjs(config.horaFinMananaObras, 'HH:mm:ss', true).isValid()) {
        config.horaFinMananaObras = dayjs(config.horaFinMananaObras).format('HH:mm:ss');
      }

      if (dayjs(config.horaInicioTardeObras, 'HH:mm:ss', true).isValid()) {
        config.horaInicioTardeObras = dayjs(config.horaInicioTardeObras).format('HH:mm:ss');
      }

      if (dayjs(config.horaFinTardeObras, 'HH:mm:ss', true).isValid()) {
        config.horaFinTardeObras = dayjs(config.horaFinTardeObras).format('HH:mm:ss');
      }

      this.plantaActual.horario.forEach((fr: FranjaHoraria) => {
        if (dayjs(fr.inicio, 'HH:mm:ss', true).isValid()) {
          fr.inicio = dayjs(fr.inicio).format('HH:mm:ss');
        }

        if (dayjs(fr.fin, 'HH:mm:ss', true).isValid()) {
          fr.fin = dayjs(fr.fin).format('HH:mm:ss');
        }
      });

      const res = new Planta(await PlantaRepository.ActualizarConfiguracion(this.plantaActual));
      this.AsignarInstalacion(res);
      let index = this.plantas.findIndex((x: Planta) => x.id === this.plantaActual.id);
      this.plantas.splice(index, 1, new Planta(res));
      this.plantaActual = new Planta(res);

      sessionStorage.setItem('plantas', JSON.stringify(plantaService.plantas));
      sessionStorage.setItem('plantaActual', JSON.stringify(res));

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

export const plantaService = new PlantaService();
