import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface Props {
  show: boolean;
  texto: string;
  titulo: string;
  confirmar: () => void;
  cancelar: () => void;
}

const ConfirmPopUp: React.FC<Props> = (props) => {
  return (
    <Modal style={{ top: '10em' }} show={props.show} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{props.titulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '100px' }}>
        <span>{props.texto}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.confirmar}>
          Confirmar
        </Button>
        <Button variant="secondary" onClick={props.cancelar}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPopUp;
