import BaseEntity from './Base/BaseEntity';

export default class TipoVehiculo extends BaseEntity {
  nombre: string;
  descripcion: string;
  capacidad: number;
  plantaId: number;
  compensacion: number;
  costeHora: number;

  constructor(obj?: any) {
    super();

    if (obj) {
      this.id = obj.id;
      this.nombre = obj.nombre;
      this.descripcion = obj.descripcion;
      this.capacidad = obj.capacidad;
      this.plantaId = obj.plantaId;
      this.compensacion = obj.compensacion;
      this.costeHora = obj.costeHora;
    } else {
      this.nombre = '';
      this.descripcion = '';
      this.capacidad = 0;
      this.plantaId = null;
      this.compensacion = null;
      this.costeHora = null;
    }
  }
}
