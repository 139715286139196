import PlanificacionOptimizada from '../../01.-Domain/Entities/PlanificacionOptimizada';
import ISeguimientoRepository from '../../01.-Domain/RepositoriesInterfaces/ISeguimientoRepository';
import GenericRepository from '../Base/GenericRepository';

class SeguimientoRepository
  extends GenericRepository<PlanificacionOptimizada>
  implements ISeguimientoRepository
{
  constructor() {
    super('Seguimiento');
  }

  async EliminarVehiculoPlan(idPlan: number, idVehiculos: number[]) {
    const res = await this.post([{ key: idPlan, value: 'eliminarVehiculosPlanificacion' }], idVehiculos);
    return res;
  }

  async Replanificar(idPlan: number) {
    const res = await this.post([{ key: idPlan, value: 'replanificar' }]);
    return res;
  }

  async ModificarPrioridadTrabajo(idPlan: number, idTrabajo: number, prioridad: number) {
    const res = await this.post(
      [
        { key: idPlan, value: 'trabajo' },
        { key: idTrabajo, value: 'ModificarPrioridad' }
      ],
      prioridad
    );

    return res;
  }

  async FinalizarPlanificacion(id: number) {
    const res = this.post([{ key: id, value: 'finalizar' }]);
    return res;
  }
}

export default new SeguimientoRepository();
