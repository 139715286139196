import Mezcla from '../../01.-Domain/Entities/Mezcla';
import IMezclaRepository from '../../01.-Domain/RepositoriesInterfaces/IMezclaRepository';
import GenericRepository from '../Base/GenericRepository';

class MezclaRepository extends GenericRepository<Mezcla> implements IMezclaRepository {
  constructor() {
    super('Mezcla');
  }

  async GetMezclas(): Promise<Mezcla[]> {
    return this.getAll();
  }
}

export default new MezclaRepository();
