import { ListItemButton } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  url?: string;
  name: string;
  primaryText?: string;
  icon: any;
  optionSelected: () => void;
}

export const ListItemComponent: React.FC<Props> = (props) => {
  const [itemClass, setitemClass] = useState<string>(null);
  const navigate = useNavigate();

  const handleClickNav = (navigateTo: string) => {
    navigate(navigateTo, { replace: true });
    props.optionSelected();
  };

  function handleMouseEnter() {
    setitemClass('itemHovered');
  }

  return (
    <ListItemButton
      className={itemClass}
      onMouseEnter={() => handleMouseEnter()}
      onClick={() => handleClickNav(props.url)}>
      <ListItem className="itemHovered" style={{ width: 'auto' }} button>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.primaryText} />
      </ListItem>
    </ListItemButton>
  );
};

export default ListItemComponent;
