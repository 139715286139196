import L from 'leaflet';

function GetIcon(ico: string, clase?: string) {
  return L.icon({
    iconUrl: require(`../../../assets/images/obra/${ico}.png`),
    className: clase,
    iconSize: [25, 25]
  });
}

function GetVehiculoIcon(ico: string, clase?: string) {
  return L.icon({
    iconUrl: require(`../../../assets/images/truck/${ico}.png`),
    className: clase,
    iconSize: [25, 25]
  });
}

function GetPlantaIcon(ico: string, clase?: string) {
  return L.icon({
    iconUrl: ico,
    className: clase,
    iconSize: [25, 25]
  });
}

const url: string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const attribution: string =
  // 'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/13/4400/2686/256/png8?apiKey={jfwDePsoSXqjRLXOcdCszx3g-s74sSlYgGUFQwxM5HE}';
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

export { GetIcon, GetVehiculoIcon, GetPlantaIcon, url, attribution };
