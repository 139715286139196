import Permisos from '../../01.-Domain/Entities/Autentication/Permisos';
import Ventana from '../../01.-Domain/Entities/Autentication/Ventana';
import { RolEnum } from '../../01.-Domain/Enums/RolEnum';
import AutenticationRepository from '../../03.-Infraestructure/Repositories/AutenticationRepository';
import IAutenticationService from '../ServicesInterfaces/IAutenticationService';
import Planta from '../../01.-Domain/Entities/Planta';
import InstalacionesResponse from '../../01.-Domain/Entities/Autentication/InstalacionesResponse';
import DataInstalaciones from '../../01.-Domain/Entities/Autentication/DataInstalaciones';
import Elementos from '../../01.-Domain/Entities/Autentication/Elementos';

class AutenticationService implements IAutenticationService {
  private _permisos: Permisos;
  public get permisos(): Permisos {
    return this._permisos;
  }
  public set permisos(v: Permisos) {
    this._permisos = v;
  }

  private _plantasHabilitadas: Planta[];
  public get plantasHabilitadas(): Planta[] {
    return this._plantasHabilitadas;
  }
  public set plantasHabilitadas(v: Planta[]) {
    this._plantasHabilitadas = v;
  }

  constructor() {
    this.permisos = null;
    this._plantasHabilitadas = [];
  }

  SetToken() {
    this.permisos = JSON.parse(sessionStorage.getItem('permisos'));
  }

  async ObtenerPermisos(token: string): Promise<boolean> {
    if (!this.permisos) {
      const res = await AutenticationRepository.ObtenerPermisos(token).catch(() => {
        return false;
      });

      this.permisos = new Permisos(res);
      sessionStorage.setItem('permisos', JSON.stringify(this.permisos));
      return true;
    }
  }

  async Autenticate(user: string, pass: string): Promise<boolean> {
    const res = await AutenticationRepository.Autenticate(user, pass);

    if (res && res.userInfo) {
      sessionStorage.setItem('token', res.sessionInfo.jwt);
      const plantas: InstalacionesResponse = await this.ObtenerPermisosPlanta(res.sessionInfo.jwt);

      plantas.data.forEach((element: DataInstalaciones) => {
        if (element.elementos.length) {
          element.elementos.forEach((pt: Elementos) => {
            var planta: Planta = new Planta();
            planta.id = pt.id;
            planta.nombre = pt.codigo;
            planta.instalacion = element.nombre;

            this.plantasHabilitadas.push(planta);
          });
        }
      });
      await this.ObtenerPermisos(res.sessionInfo.jwt);
      return res && res.userInfo !== null;
    }
  }

  async ObtenerPermisosPlanta(jwt: string): Promise<InstalacionesResponse> {
    return await AutenticationRepository.ObtenerPermisosPlanta(jwt);
  }

  /**
   * Devuelve true si el usuario tiene permisos para entrar en la ventana
   * @param path string
   * @returns boolean
   */
  TienePermisoVentana(path: string) {
    let ventana = '';

    switch (path) {
      case 'planificacion':
        ventana = 'PLANIF';
        break;
      case 'planificacionoptimizada':
        ventana = 'PLANIF_OPT';
        break;
      case 'seguimiento':
        ventana = 'SEGUIMIENTO';
        break;
      case 'configuracionplanta':
        ventana = 'CONF_PLANTA';
        break;
      default:
        break;
    }

    return (
      this.permisos?.data.ventanas.find(
        (x: Ventana) => x.nombreVentana.toLowerCase() === ventana.toLowerCase()
      ) !== undefined
    );
  }

  /**
   * Devuelve true si el usuario tiene permisos de modificación en la ventana solicitada
   * @param path string
   * @returns boolean
   */
  TienePermisoModificacion(path: string): boolean {
    const permiso = this.permisos.data.ventanas.find(
      (x: Ventana) => x.nombreVentana.toLowerCase() === path
    );

    return permiso.permisoModificacion === 'S';
  }

  /**
   * Devuelve true o false si el usuario tiene permisos para ver los botones
   * @returns boolean
   */
  MostrarBotonesSeguimiento(): boolean {
    return this.permisos.data.grupoAutorizacion !== RolEnum.RESP_PLANT;
  }

  /**
   * Devuelve true cuando el usuario tiene permisos para planificar
   * @returns boolean
   */
  PuedePlanificar(): boolean {
    return this.permisos?.data.ventanas.find((x: Ventana) => x.nombreVentana === 'PLANIF') !== undefined;
  }

  /**
   * Devuelve true si el usuario es responsable de planta
   * @returns boolean
   */
  EsResponsablePlanta(): boolean {
    return this.permisos?.data.grupoAutorizacion === RolEnum.RESP_PLANT;
  }
}

export default new AutenticationService();
