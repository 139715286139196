import dayjs from 'dayjs';
import { EstadoPlanificacionEnum } from '../../Enums/EstadoPlanificacionEnum';
import BaseEntity from '../Base/BaseEntity';
import { GridObject } from './IndicadoresGrid';

export default class IndicadoresGlobales extends BaseEntity {
  costeTotal: number;
  costeTotalSeguimiento: number;
  costeTotalDiferencia: number;
  horaInicio: Date;
  horaInicioSeguimiento: Date;
  horaInicioDiferencia: Date;
  horaFin: Date;
  horaFinSeguimiento: Date;
  horaFinDiferencia: Date;
  toneladas: number;
  toneladasSeguimiento: number;
  toneladasDiferencia: number;
  estado: string;

  constructor(obj?: IndicadoresGlobales[]) {
    super();

    if (obj.length) {
      obj.forEach((indicadores: IndicadoresGlobales) => {
        if (EstadoPlanificacionEnum[indicadores.estado] === EstadoPlanificacionEnum[0]) {
          this.id = indicadores.id;
          this.costeTotal = indicadores.costeTotal;
          this.horaInicio = indicadores.horaInicio;
          this.horaFin = indicadores.horaFin;
          this.toneladas = indicadores.toneladas;
          this.estado = indicadores.estado;
        }

        if (EstadoPlanificacionEnum[indicadores.estado] === EstadoPlanificacionEnum[1]) {
          this.costeTotalSeguimiento = indicadores.costeTotal;
          this.horaInicioSeguimiento = indicadores.horaInicio;
          this.horaFinSeguimiento = indicadores.horaFin;
          this.toneladasSeguimiento = indicadores.toneladas;

          this.costeTotalDiferencia = this.costeTotal - this.costeTotalSeguimiento;
          this.toneladasDiferencia = this.toneladas - this.toneladasSeguimiento;
          //this.horaInicioDiferencia = dayjs(this.horaInicio).diff(dayjs(this.horaInicioSeguimiento));
        }
      });
    } else {
      this.costeTotal = 0;
      this.costeTotalSeguimiento = 0;
      this.costeTotalDiferencia = 0;
      this.horaInicio = null;
      this.horaInicioSeguimiento = null;
      this.horaInicioDiferencia = null;
      this.horaFin = null;
      this.horaFinSeguimiento = null;
      this.horaFinDiferencia = null;
      this.toneladas = 0;
      this.toneladasSeguimiento = 0;
      this.toneladasDiferencia = 0;
      this.estado = null;
    }
  }

  GetIndicadoresGlobalesDataGrid(indicadoresGlobales): any[] {
    const actuales = [];
    let contador = 0;

    Object.keys(indicadoresGlobales).forEach((actual) => {
      const o: GridObject = new GridObject();
      o.id = contador;
      contador++;

      switch (actual) {
        case 'horaInicio':
        case 'horaFin': {
          o.parametro = actual === 'horaInicio' ? 'Hora Inicio' : 'Hora Fín';
          o.valor = dayjs(indicadoresGlobales[actual]).format('HH:mm');

          if (indicadoresGlobales[`${actual}Seguimiento`]) {
            o.valorSeguimiento = dayjs(indicadoresGlobales[`${actual}Seguimiento`]).format('HH:mm');
            o.diferencia = `${dayjs(indicadoresGlobales[actual]).diff(
              dayjs(indicadoresGlobales[`${actual}Seguimiento`]),
              'm',
              false
            )} m.`;
          }

          actuales.push(o);
          break;
        }
        case 'costeTotal': {
          o.parametro = 'Coste Total';
          o.valor = `${indicadoresGlobales[actual].toFixed(1).toLocaleString('ES-es')} €`;

          if (indicadoresGlobales[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresGlobales[`${actual}Seguimiento`]
              .toFixed(1)
              .toLocaleString('ES-es')} €`;

            o.diferencia = `${(
              indicadoresGlobales[`${actual}Seguimiento`] - indicadoresGlobales[actual]
            ).toFixed(1)} €`;
          }

          actuales.push(o);
          break;
        }
        case 'toneladas': {
          o.parametro = actual;

          o.parametro = 'Toneladas';
          o.valor = `${indicadoresGlobales[actual]} Tm`;

          if (indicadoresGlobales[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresGlobales[`${actual}Seguimiento`].toFixed(1)} Tn`;
            o.diferencia = `${
              indicadoresGlobales[actual] - indicadoresGlobales[`${actual}Seguimiento`].toFixed(1)
            } Tn`;
          }

          actuales.push(o);
          break;
        }

        default:
          break;
      }
    });
    return actuales;
  }
}
