import TipoVehiculo from '../../01.-Domain/Entities/TipoVehiculo';
import PlantaRepository from '../../03.-Infraestructure/Repositories/PlantaRepository';
import ITipoVehiculoService from '../ServicesInterfaces/ITipoVehiculoService';
import { plantaService } from './PlantaService';

class TipoVehiculoService implements ITipoVehiculoService {
  private _tipoVehiculos: TipoVehiculo[];

  public get tipoVehiculos(): TipoVehiculo[] {
    return this._tipoVehiculos;
  }

  public set tipoVehiculos(v: TipoVehiculo[]) {
    this._tipoVehiculos = v;
  }

  constructor() {
    this._tipoVehiculos = [];
  }

  async GetAll(): Promise<Boolean> {
    if (this.tipoVehiculos.length === 0) {
      this.tipoVehiculos = [];
      const res = await PlantaRepository.GetTipoVehiculos(plantaService.plantaActual.id);

      res.forEach((t: TipoVehiculo) => {
        this._tipoVehiculos.push(new TipoVehiculo(t));
      });

      return true;
    }
  }
}

export default new TipoVehiculoService();
