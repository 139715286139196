import dayjs from 'dayjs';
import { NumberToTimeFormat } from '../../02.-Application/Services/DateTimeService';
import { EstadoOperacionEnum } from '../Enums/EstadoOperacionEnum';
import { EtapaEnum } from '../Enums/EtapaEnum';
import BaseEntity from './Base/BaseEntity';
import Location from './Location';
import VehiculoGenerico from './VehiculoGenerico';
export default class Operacion extends BaseEntity {
  estado: string;
  etapa: string;
  inicioProgramadoOriginal: Date;
  finProgramadoOriginal: Date;
  inicioProgramado: Date;
  finProgramado: Date;
  inicioReal: Date;
  finReal: Date;
  inicioCambio: Date;
  finCambio: Date;
  mezclaDescripcion: string;
  mezclaIdOrigen: string;
  obraId: number;
  obraDescripcion: string;
  obraIdOrigen: string;
  obraLocation: Location;
  planificacionDescripcion: string;
  planificacionFechaInicio: Date;
  planificacionId: number;
  posicion: number;
  vehiculoGenerico: VehiculoGenerico;
  viajeId: number;
  posicionViaje: number;
  viajeToneladas: number;
  inicioParadaVehiculo: Date;
  finParadaVehiculo: Date;
  inicioParadaObra: Date;
  finParadaObra: Date;
  inicioParadaPlanta: Date;
  finParadaPlanta: Date;
  prioridadTrabajo: number;
  trabajoId: number;
  costeTramo: number;
  costeMaquinaria: number;
  costePersonal: number;
  inicioDescanso: Date;
  finDescanso: Date;
  tiempoDestino: string;

  constructor(obj?: Operacion) {
    super();

    if (obj) {
      this.id = obj.id;
      this.estado = EstadoOperacionEnum[obj.estado];
      this.etapa = EtapaEnum[obj.etapa];
      this.inicioProgramadoOriginal = obj.inicioProgramadoOriginal;
      this.finProgramadoOriginal = obj.finProgramadoOriginal;
      this.inicioProgramado = obj.inicioProgramado;
      this.finProgramado = obj.finProgramado;
      this.inicioReal = obj.inicioReal;
      this.finReal = obj.finReal;
      this.inicioCambio = obj.inicioCambio;
      this.finCambio = obj.finCambio;
      this.mezclaDescripcion = obj.mezclaDescripcion;
      this.mezclaIdOrigen = obj.mezclaIdOrigen;
      this.obraId = obj.obraId;
      this.obraDescripcion = obj.obraDescripcion;
      this.obraIdOrigen = obj.obraIdOrigen;
      this.obraLocation = new Location(obj.obraLocation);
      this.planificacionDescripcion = obj.planificacionDescripcion;
      this.planificacionFechaInicio = obj.planificacionFechaInicio;
      this.planificacionId = obj.planificacionId;
      this.posicion = obj.posicion;
      this.vehiculoGenerico = obj.vehiculoGenerico ? new VehiculoGenerico(obj.vehiculoGenerico) : null;
      this.inicioParadaVehiculo = obj.inicioParadaVehiculo ? obj.inicioParadaVehiculo : null;
      this.finParadaVehiculo = obj.finParadaVehiculo ? obj.finParadaVehiculo : null;
      this.inicioParadaObra = obj.inicioParadaObra ? obj.inicioParadaObra : null;
      this.finParadaObra = obj.finParadaObra ? obj.finParadaObra : null;
      this.inicioParadaPlanta = obj.inicioParadaPlanta ? obj.inicioParadaPlanta : null;
      this.finParadaPlanta = obj.finParadaPlanta ? obj.finParadaPlanta : null;
      this.viajeId = obj.viajeId;
      this.posicionViaje = obj.posicionViaje;
      this.viajeToneladas = obj.viajeToneladas;
      this.prioridadTrabajo = obj.prioridadTrabajo;
      this.trabajoId = obj.trabajoId;
      this.costeMaquinaria = obj.costeMaquinaria;
      this.costeTramo = obj.costeTramo;
      this.costePersonal = obj.costePersonal;
      this.inicioDescanso = obj.inicioDescanso;
      this.finDescanso = obj.finDescanso;
      if (
        (EtapaEnum[obj.etapa] === 'Transporte' && EstadoOperacionEnum[obj.estado] === 'Iniciada') ||
        (EtapaEnum[obj.etapa] === 'Retorno' && EstadoOperacionEnum[obj.estado] === 'Iniciada')
      ) {
        this.tiempoDestino = ObtenerTiempoRestante(obj.finProgramado);
      }
    } else {
      this.estado = EstadoOperacionEnum.Pendiente.toString();
      this.etapa = null;
      this.inicioProgramadoOriginal = null;
      this.finProgramadoOriginal = null;
      this.inicioProgramado = null;
      this.finProgramado = null;
      this.inicioReal = null;
      this.finReal = null;
      this.inicioCambio = null;
      this.finCambio = null;
      this.mezclaDescripcion = '';
      this.mezclaIdOrigen = '';
      this.obraId = 0;
      this.obraDescripcion = '';
      this.obraIdOrigen = '';
      this.obraLocation = null;
      this.planificacionDescripcion = '';
      this.planificacionFechaInicio = null;
      this.planificacionId = 0;
      this.posicion = 0;
      this.vehiculoGenerico = null;
      this.viajeId = null;
      this.posicionViaje = null;
      this.viajeToneladas = 0;
      this.inicioParadaVehiculo = null;
      this.finParadaVehiculo = null;
      this.inicioParadaObra = null;
      this.finParadaObra = null;
      this.inicioParadaPlanta = null;
      this.finParadaPlanta = null;
      this.prioridadTrabajo = 0;
      this.trabajoId = 0;
      this.costeMaquinaria = 0;
      this.costeTramo = 0;
      this.costePersonal = 0;
      this.inicioDescanso = null;
      this.finDescanso = null;
    }
  }
}

/**
 * Obtiene el tiempo que falta para completar un trayecto desde la hora actual
 * hasta la hora de fín programada
 * @param horaProgramadaLlegada Date
 * @returns string
 */
function ObtenerTiempoRestante(horaProgramadaLlegada: Date) {
  if (new Date(horaProgramadaLlegada).getDay() === new Date().getDay() + 1) {
    horaProgramadaLlegada = dayjs(horaProgramadaLlegada).subtract(1, 'day').toDate();
  }
  const tiempo = dayjs(horaProgramadaLlegada).diff(dayjs(), 'minute');
  let tiempoRestante = tiempo < 0 ? '0' : NumberToTimeFormat(tiempo);
  return tiempoRestante.toString();
}
