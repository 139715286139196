import EstadoGrid from '../../01.-Domain/Entities/EstadoGrid';
import IEstadoGridRepository from '../../01.-Domain/RepositoriesInterfaces/IEstadoGridRepository';
import GenericRepository from '../Base/GenericRepository';

class EstadoGridRepository extends GenericRepository<EstadoGrid> implements IEstadoGridRepository {
  constructor() {
    super('EstadoGrid');
  }

  async ObtenerEstadoByRol(rol: string): Promise<EstadoGrid> {
    const res = await this.get([{ key: 'ObtenerConfiguracionGrid' }], [{ key: 'rol', value: rol }]);

    return res;
  }

  async GuardarEstado(estado: EstadoGrid): Promise<boolean> {
    const res = await this.post([{ value: 'GuardarConfiguracionGrid' }], estado);

    return res;
  }
}

export default new EstadoGridRepository();
