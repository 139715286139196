export class GridObject {
  public id: number;
  public parametro: string;
  public valor: any;
  public valorSeguimiento: any;
  public diferencia: any;

  constructor(obj?: any) {
    if (obj) {
      this.parametro = obj.parametro;
      this.valor = obj.valor;
      this.valorSeguimiento = obj.valorSeguimiento;
      this.diferencia = obj.diferencia;
    }
  }
}
