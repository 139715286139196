import GenericRepository from '../Base/GenericRepository';
import IGISRepository from '../../01.-Domain/RepositoriesInterfaces/IGISRepository';
import GIS from '../../01.-Domain/Entities/GIS';

class GISRepository extends GenericRepository<GIS> implements IGISRepository {
  constructor() {
    super('Gis');
  }
}

export default new GISRepository();
