import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logoPavasal from '../../../assets/images/LogoPavasal.png';

interface Props {
  setOpen: () => void;
  user?: string;
}

export const DrawerDocker: React.FC<Props> = (props) => {
  const theme = useTheme();

  const DrawerHeader = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFC72F',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }));

  const handleDrawerClose = () => {
    props.setOpen();
  };

  return (
    <DrawerHeader>
      <Container fluid>
        <Row>
          <Col md="auto" style={{ padding: 0, margin: 0 }}>
            <img style={{ zoom: '80%' }} src={logoPavasal} alt="ITI" title="Logo_ITI" />
          </Col>
        </Row>
        {props.user && (
          <Row>
            <Col md="auto" className="ml-5">
              <h3>{props.user}</h3>
            </Col>
            <Col md="auto" className="ml-5">
              <h5>{props.user}</h5>
            </Col>
            )
          </Row>
        )}
      </Container>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </DrawerHeader>
  );
};

export default DrawerDocker;
