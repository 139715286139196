import BaseEntity from './Base/BaseEntity';

export default class Tramo extends BaseEntity {
  instalacion: string;
  nombreTramo: string;
  importe: number;
  km: string;

  constructor(obj?: any) {
    super();

    if (obj) {
      this.id = obj.id_Tramo;
      this.instalacion = obj.instalacion;
      this.nombreTramo = obj.nombreTramo;
      this.importe = obj.importeFormateado;
      this.km = obj.km;
    } else {
      this.instalacion = null;
      this.nombreTramo = null;
      this.importe = null;
      this.km = null;
    }
  }
}
