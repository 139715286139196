import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import IHttpService from '../../ServicesInterfaces/Base/IHttpService';

export class HttpService implements IHttpService {
  private readonly instance: AxiosInstance;

  private _root = '';

  set root(valor: string) {
    this._root = valor;
    this.instance.defaults.baseURL = valor;
  }

  get root() {
    return this._root;
  }

  constructor() {
    this.instance = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
    this.addInterceptors();
  }

  async get(url: string): Promise<AxiosInstance> {
    return this.instance
      .get(url)
      .then((axiosResponse) => this.processResponse(axiosResponse))
      .catch((error) => this.processHttpError(error));
  }

  async delete(url: string): Promise<AxiosInstance> {
    return this.instance
      .delete(url)
      .then((axiosResponse) => this.processResponse(axiosResponse))
      .catch((error) => this.processHttpError(error));
  }

  async post(url: string, params: any): Promise<AxiosInstance> {
    return this.instance
      .post(url, params)
      .then((axiosResponse) => this.processResponse(axiosResponse))
      .catch((error) => this.processHttpError(error));
  }

  async put(url: string, params: any): Promise<AxiosInstance> {
    return this.instance
      .put(`${url}/${params.Id}`, params)
      .then(async (axiosResponse) => this.processResponse(axiosResponse))
      .catch((error) => this.processHttpError(error));
  }

  addInterceptors() {
    this.instance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        config.headers.Authorization = String('Bearer ' + sessionStorage.getItem('token'));
        return config;
      },
      (err: any) => {
        console.log('AXIOS REQUEST ERRROR');
        return Promise.reject(err);
      }
    );
  }

  private processResponse(response: AxiosResponse): Promise<any> {
    return Promise.resolve(response.data);
  }

  private processHttpError(error: any) {
    console.log(error);
    return Promise.reject(error.response);
  }
}

export const httpService = new HttpService();
