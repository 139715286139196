import 'devextreme/dist/css/dx.light.css';
import { loadMessages, locale } from 'devextreme/localization';
import esMessages from 'devextreme/localization/messages/es.json';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import DrawerComponent from '../../Components/Drawer/DrawerComponent';
import ConfiguracionPlantaView from '../ConfiguracionPlanta/ConfiguracionPlantaView';
import LoginView from '../Login/LoginView';
import NoMatchView from '../NoMatchView/NoMatchView';
import PlanificacionView from '../Planificacion/PlanificacionView';
import PlanificacionOptimizadaView from '../PlanificacionOptimizada/PlanificacionOptimizadaView';
import SeguimientoView from '../Seguimiento/SeguimientoView';
import MainView from '../Main/Main';
import AutenticationService from '../../../02.-Application/Services/AutenticationService';
import { plantaService } from '../../../02.-Application/Services/PlantaService';
import Planta from '../../../01.-Domain/Entities/Planta';

const App: React.FC = () => {
  const [loadDrawer, setloadDrawer] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);

  loadMessages(esMessages);
  locale('es');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const plantaActual: Planta = new Planta(JSON.parse(sessionStorage.getItem('plantaActual')));
    const plantas: Planta[] = JSON.parse(sessionStorage.getItem('plantas'));

    plantaService.plantaActual = plantaActual ? plantaActual : null;
    plantaService.plantas = plantas ? plantas : [];

    if (sessionStorage.getItem('token') === null && location.pathname !== '/') {
      navigate('/', { replace: false });
    } else {
      AutenticationService.SetToken();
      setloadDrawer(true);

      if (location.pathname !== '/') {
        const permisoVentana = AutenticationService.TienePermisoVentana(
          location.pathname.toLowerCase().substring(1)
        );
        if (!permisoVentana) {
          navigate('/', { replace: true });
        }
      }
    }

    setLoad(true);
  }, [location]);

  useEffect(() => {
    setloadDrawer(sessionStorage.getItem('token') !== null);
  }, [location]);

  return (
    <>
      {load && (
        <div className="general MainWindow">
          {loadDrawer && <DrawerComponent />}
          <Routes>
            <Route
              path="/planificacion"
              element={
                <PlanificacionView
                  permisos={AutenticationService.TienePermisoVentana(
                    location.pathname.toLowerCase().substring(1)
                  )}
                />
              }
            />
            <Route
              path="/PlanificacionOptimizada"
              element={
                <PlanificacionOptimizadaView
                  permisos={AutenticationService.TienePermisoVentana(
                    location.pathname.toLowerCase().substring(1)
                  )}
                />
              }
            />
            <Route
              path="/seguimiento"
              element={
                <SeguimientoView
                  permisos={AutenticationService.TienePermisoVentana(
                    location.pathname.toLowerCase().substring(1)
                  )}
                />
              }
            />
            <Route
              path="/configuracionPlanta"
              element={
                <ConfiguracionPlantaView
                  permisos={AutenticationService.TienePermisoVentana(
                    location.pathname.toLowerCase().substring(1)
                  )}
                />
              }
            />
            <Route path="/main" element={<MainView />} />
            <Route path="/" element={<LoginView />} />
            <Route path="*" element={<NoMatchView />} />
          </Routes>
        </div>
      )}
    </>
  );
};

export default App;
