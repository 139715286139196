export const columnas = [
  {
    dataField: 'id',
    caption: 'Id',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'etapa',
    caption: 'Etapa',
    dataType: 'string',
    allowEditing: false
  },
  {
    dataField: 'obraId',
    caption: 'ObraFK',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'obraIdOrigen',
    caption: 'Obra',
    dataType: 'string',
    allowEditing: false
  },
  {
    dataField: 'obraDescripcion',
    caption: 'Descripcion',
    dataType: 'string',
    allowEditing: false,
    visible: false
  },
  {
    dataField: 'vehiculoGenerico.id',
    caption: 'VehículoFK',
    dataType: 'number',
    alignment: 'left',
    allowEditing: false,
    visible: false
  },
  {
    dataField: 'vehiculoGenerico.matricula',
    caption: 'Matrícula',
    dataType: 'string',
    allowEditing: true,
    allowClearing: true
  },
  {
    dataField: 'estado',
    caption: 'Estado',
    dataType: 'string',
    width: 80,
    allowEditing: false
  },
  {
    dataField: 'posicion',
    caption: 'Pos. OP.',
    dataType: 'number',
    alignment: 'left',
    width: 80,
    allowEditing: false
  },
  {
    dataField: 'posicionViaje',
    caption: 'Pos. Viaje',
    dataType: 'number',
    alignment: 'left',
    width: 80,
    allowEditing: false
  },
  {
    caption: 'Programado',
    alignment: 'center',
    columns: [
      {
        dataField: 'inicioProgramado',
        caption: 'Ini',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false,
        sortOrder: 'asc'
      },
      {
        dataField: 'finProgramado',
        caption: 'Fin',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'inicioProgramadoOriginal',
        caption: 'Ini. Orig.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'finProgramadoOriginal',
        caption: 'Fin Orig.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      }
    ]
  },
  {
    dataField: 'tiempoDestino',
    caption: 'Tpo Pte',
    dataType: 'string',
    allowEditing: false
  },
  {
    dataField: 'inicioReal',
    caption: 'Ini. Real.',
    dataType: 'datetime',
    format: 'HH:mm',
    allowEditing: false
  },
  {
    dataField: 'finReal',
    caption: 'Fin Real',
    dataType: 'datetime',
    format: 'HH:mm',
    allowEditing: false
  },
  {
    caption: 'Vehículo',
    alignment: 'center',
    columns: [
      {
        dataField: 'inicioParadaVehiculo',
        caption: 'In. Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'finParadaVehiculo',
        caption: 'Fin Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'inicioDescanso',
        caption: 'Ini. Desc.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'finDescanso',
        caption: 'Fin Desc.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      }
    ]
  },
  {
    caption: 'Obra',
    alignment: 'center',
    columns: [
      {
        dataField: 'inicioParadaObra',
        caption: 'In. Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'finParadaObra',
        caption: 'Fin Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      }
    ]
  },
  {
    caption: 'Planta',
    alignment: 'center',
    columns: [
      {
        dataField: 'inicioParadaPlanta',
        caption: 'In. Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'finParadaPlanta',
        caption: 'Fin Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'inicioCambio',
        caption: 'Ini. Cbio',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'finCambio',
        caption: 'Fin Cbio',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      }
    ]
  },
  {
    dataField: 'viajeToneladas',
    caption: 'tn.',
    dataType: 'number',
    alignment: 'left',
    allowEditing: false
  },
  {
    dataField: 'prioridadTrabajo',
    caption: 'Prio.',
    dataType: 'number'
  },
  {
    dataField: 'trabajoId',
    caption: 'TrabajoFK',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'mezclaIdOrigen',
    caption: 'Mezcla',
    dataType: 'string',
    width: 100,
    allowEditing: false
  },
  {
    dataField: 'mezclaDescripcion',
    caption: 'Descripción',
    dataType: 'string',
    width: 200,
    visible: false,
    allowEditing: false
  }
];
