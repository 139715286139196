import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import EventoVehiculo from '../../../01.-Domain/Entities/EventoVehiculo';
import Operacion from '../../../01.-Domain/Entities/Operacion';
import VehiculoGenerico from '../../../01.-Domain/Entities/VehiculoGenerico';
import { EstadoOperacionEnum } from '../../../01.-Domain/Enums/EstadoOperacionEnum';
import { EtapaEnum } from '../../../01.-Domain/Enums/EtapaEnum';
import { EventoEnum } from '../../../01.-Domain/Enums/EventoEnum';
import { default as toastService } from '../../../02.-Application/Services/Base/ToastService';
import { plantaService } from '../../../02.-Application/Services/PlantaService';
import SeguimientoService, { ObrasSeguimiento } from '../../../02.-Application/Services/SeguimientoService';
import VehiculoService from '../../../02.-Application/Services/VehiculoService';
import Grid from '../../Components/DataGrid/Grid';
import { columnas, columnasInicioFinExtendido } from './ColumnasVehiculos';

type Props = {
  show: boolean;
  cerrarPopup: () => void;
  cerrarSinCambios: () => void;
  extendido: EventoEnum;
};

/**
 * Si me envia las obras con todos sus viajes, puedo ver si alguna obra tiene un viaje en extendido
 * @param props
 * @returns
 */
const PopupFinExtendido: React.FC<Props> = (props) => {
  const [vehiculos, setVehiculos] = useState<VehiculoGenerico[]>([]);
  const [vehiculoSeleccionado, setvehiculoSeleccionado] = useState<VehiculoGenerico>(null);
  const [obraSeleccionada, setObraSeleccionada] = useState<ObrasSeguimiento>(null);
  const [btnDeshabilitado, setbtnDeshabilitado] = useState<boolean>(true);
  const [plantaIdOrigen, setPlantaIdOrigen] = useState<string>('');

  useEffect(() => {
    if (!SeguimientoService.planificacionEnSeguimiento) {
      console.log('No hay planificacion en seguimiento');
      return;
    }

    setPlantaIdOrigen(plantaService.plantaActual.nombre);
    GetData();
  }, [obraSeleccionada]);

  function GetData() {
    // Obtener las operaciones de la obra seleccionada
    const operacionesDeObra = SeguimientoService.planificacionEnSeguimiento.operaciones.filter(
      (x: Operacion) => x.obraIdOrigen === obraSeleccionada?.idOrigen
    );

    const vehiculosObra: VehiculoGenerico[] = [];
    // Se cargan los vehículos de las obras que estén en extendido
    operacionesDeObra
      .filter(
        (x: Operacion) =>
          EstadoOperacionEnum[x.estado] === EstadoOperacionEnum.Iniciada &&
          EtapaEnum[x.etapa] === EtapaEnum.Extendido
      )
      .forEach((op: Operacion) => {
        if (!vehiculosObra.find((x: VehiculoGenerico) => x?.matricula === op.vehiculoGenerico?.matricula)) {
          if (op.vehiculoGenerico) {
            vehiculosObra.push(op.vehiculoGenerico);
          }
        }
      });

    setVehiculos(vehiculosObra);
  }

  async function FinalizarExtendido() {
    const evento = new EventoVehiculo(props.extendido, obraSeleccionada.idOrigen, null, plantaIdOrigen);

    await VehiculoService.InsertarEvento(vehiculoSeleccionado.matricula, evento)
      .then((result) => {
        if (result) {
          toastService.success('Evento insertado correctamente');
          GetData();
          props.cerrarPopup();
        } else {
          toastService.error('Ha habido un error insertando el evento');
        }
      })
      .catch(() => {
        toastService.error('Ha habido un error insertando el evento');
      });
  }

  const handleSelectionChange = (e: any) => {
    setvehiculoSeleccionado(e.selectedRowsData[0]);
    setbtnDeshabilitado(!vehiculoSeleccionado && !obraSeleccionada);
  };

  /**
   * Al seleccionar una obra, se muestran
   * @param e any
   * @returns
   */
  const handleObraChange = (e: any) => {
    setObraSeleccionada(e.selectedRowKeys[0]);
    setbtnDeshabilitado(!vehiculoSeleccionado && !obraSeleccionada);
  };

  const GridObraInicializado = (e: any) => {
    if (obraSeleccionada) {
      e.component.selectRows(obraSeleccionada, true);
    }
  };

  const GridVehiculoInicializado = (e: any) => {
    if (vehiculoSeleccionado) {
      e.component.selectRows(vehiculoSeleccionado, true);
    }
  };

  return (
    <Modal
      size="lg"
      onHide={() => props.cerrarSinCambios()}
      show={props.show}
      backdrop="static"
      keyboard={false}
      style={{ top: '3em' }}>
      <Modal.Header closeButton>
        <Modal.Title>Fin Extendido</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Grid
              altura={1600}
              showCheckBox="always"
              columns={columnasInicioFinExtendido}
              source={SeguimientoService.obras}
              editable={false}
              enableColumnChooser={false}
              summaryColumnCount="idOrigen"
              selectionChanged={handleObraChange}
              gridInitialized={GridObraInicializado}
              selectionMode="single"></Grid>
          </Col>
        </Row>
        <Row>
          <Col>
            <Grid
              altura={1600}
              showCheckBox="always"
              columns={columnas}
              source={vehiculos}
              editable={false}
              enableColumnChooser={false}
              summaryColumnCount="matricula"
              selectionChanged={handleSelectionChange}
              gridInitialized={GridVehiculoInicializado}
              selectionMode="single"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={btnDeshabilitado} variant="primary" onClick={() => FinalizarExtendido()}>
          Fin Extendido
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupFinExtendido;
