import Location from '../../01.-Domain/Entities/Location';
import Obra from '../../01.-Domain/Entities/Obra';
import Operacion from '../../01.-Domain/Entities/Operacion';
import ObraRepository from '../../03.-Infraestructure/Repositories/ObraRepository';
import IObraService from '../ServicesInterfaces/IObraService';
import { plantaService } from './PlantaService';
import SeguimientoService from './SeguimientoService';
const _ = require('lodash');
class ObraService implements IObraService {
  private _obras: Obra[];

  public get obras(): Obra[] {
    return this._obras;
  }

  public set obras(v: Obra[]) {
    this._obras = v;
  }

  constructor() {
    this._obras = [];
  }

  async GetAll(): Promise<boolean> {
    this.obras = [];

    try {
      const res = await ObraRepository.GetAll();
      res.forEach((element: Obra) => {
        if (!element.location || (element.location.latitud === 0 && element.location.longitud === 0)) {
          element.location = new Location(plantaService.plantaActual.location);
        }
        this.obras.push(new Obra(element));
      });

      return true;
    } catch (res: any) {
      return false;
    }
  }

  async GuardarUbicacionObra(idObra: number, location: Location): Promise<Obra> {
    try {
      const res = await ObraRepository.GuardarUbicacionObra(idObra, location);

      // Actualiza la obra editada con la nueva posición
      this.obras.find((x: Obra) => x.id === res.id).location = res.location;

      return res;
    } catch (res: any) {
      return null;
    }
  }

  async ModificarCantidadObras(obras: Obra[]): Promise<any> {
    const responses = await Promise.all(
      obras.map(async (obra: any) => {
        await ObraRepository.ModificarCantidadObra(obra.id, obra.tm);
      })
    );

    return responses;
  }

  ToneladasTotalesObra(obraId: number): number {
    let tm: number = 0;

    const operacionesDeObra = SeguimientoService.planificacionEnSeguimiento.operaciones.filter(
      (x: Operacion) => x.obraId === obraId
    );

    const agrupadasPorViaje = _.groupBy(operacionesDeObra, (x: Operacion) => x.viajeId);

    _.forEach(agrupadasPorViaje, (x: Operacion) => {
      tm += x[0].viajeToneladas;
    });

    return Number(tm.toFixed(2));
  }
}
export const obraService = new ObraService();
