import { Button, Popup } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Obra from '../../../01.-Domain/Entities/Obra';
import { obraService } from '../../../02.-Application/Services/ObraService';
import Grid from '../../Components/DataGrid/Grid';

type Props = {
  show: boolean;
  CerrarPopup: () => void;
  IncluirObras: (listaObras: Obra[]) => void;
};

const PopupObras: React.FC<Props> = (props) => {
  const [incluidas, setIncluidas] = useState<Obra[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [obrasDisponibles, setObrasDisponibles] = useState<Obra[]>([]);

  useEffect(() => {
    setObrasDisponibles(obraService.obras);
  }, [obraService.obras]);

  const handleSelectionChange = (e: any) => {
    if (e.selectedRowKeys.length > 0) {
      setDisableButton(false);
      setIncluidas(e.selectedRowKeys);
    }
  };

  const GetColumnas = () => [
    {
      dataField: 'id',
      caption: 'Id',
      dataType: 'number',
      visible: false
    },
    {
      dataField: 'idOrigen',
      caption: 'Id',
      dataType: 'string',
      width: '12%'
    },
    {
      dataField: 'descripcion',
      caption: 'Descripcion',
      dataType: 'string',
      width: '70%'
    },
    {
      dataField: 'toneladas',
      caption: 'Tm',
      dataType: 'string',
      width: '20%'
    }
  ];

  return (
    <Popup
      visible={props.show}
      onHiding={props.CerrarPopup}
      dragEnabled={false}
      showCloseButton={true}
      showTitle={true}
      title="Selección de Obras"
      container=".dx-viewport"
      height={window.innerHeight - 200}>
      <Row>
        <Col>
          <Grid
            showCheckBox="always"
            columns={GetColumnas()}
            altura={350}
            source={obrasDisponibles}
            editable={false}
            enableColumnChooser={false}
            summaryColumnCount="idOrigen"
            selectionChanged={handleSelectionChange}
          />
        </Col>
        <Col className="p-3" md={{ span: 2, offset: 10 }}>
          <Button
            text="Añadir"
            disabled={disableButton}
            type="default"
            stylingMode="contained"
            onClick={() => props.IncluirObras(incluidas)}
          />
        </Col>
      </Row>
    </Popup>
  );
};

export default PopupObras;
