import SerieGrafica from './SerieGrafica';

export default class Graficas {
  public fabricacion: SerieGrafica;
  public transporte: SerieGrafica;
  public transporteOriginal: SerieGrafica;
  public extendido: SerieGrafica[] = [];
  public extendidoOriginal: SerieGrafica[] = [];

  constructor(obj?: Graficas) {
    if (obj) {
      this.fabricacion = new SerieGrafica(obj.fabricacion);
      this.transporte = new SerieGrafica(obj.transporte);
      this.transporteOriginal = new SerieGrafica(obj.transporteOriginal);
      obj.extendido.map((x: SerieGrafica) => this.extendido.push(new SerieGrafica(x)));
      obj.extendidoOriginal.map((x: SerieGrafica) => this.extendidoOriginal.push(new SerieGrafica(x)));
    } else {
      this.fabricacion = null;
      this.extendido = null;
      this.extendidoOriginal = null;
    }
  }
}
