import dayjs from 'dayjs';
import { DateBox, NumberBox, TextBox, Validator } from 'devextreme-react';
import { CustomRule } from 'devextreme-react/data-grid';
import { RequiredRule } from 'devextreme-react/form';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PlanificacionService from '../../../02.-Application/Services/PlanificacionService';

const FormularioPlan: React.FC = () => {
  const errorNulo = 'Este campo no puede ser nulo';
  const fechaNoValida = 'La fecha no es válida';

  const handleChange = (e: any) => {
    switch (e.component.option('name')) {
      case 'vehiculosPequenos':
        PlanificacionService.planificacionActual.numeroVehiculosPequenos = e.value;
        break;
      case 'vehiculosGrandes':
        PlanificacionService.planificacionActual.numeroVehiculosGrandes = e.value;
        break;
      case 'nombre':
        PlanificacionService.planificacionActual.descripcion = e.value;
        break;
      case 'fecha':
        PlanificacionService.planificacionActual.fechaInicio = e.value;
        break;
      default:
        break;
    }
  };

  return (
    <Row>
      <Col md={4}>
        <Row>
          <Col md={12} className="d-flex">
            <span>Nombre</span>
          </Col>
          <Col md={12}>
            <TextBox
              defaultValue={
                PlanificacionService.planificacionActual
                  ? PlanificacionService.planificacionActual.descripcion
                  : ''
              }
              name="nombre"
              onValueChanged={handleChange}
              showClearButton>
              <Validator>
                <RequiredRule message={errorNulo} />
              </Validator>
            </TextBox>
          </Col>
        </Row>
      </Col>

      <Col md={2}>
        <Row>
          <Col md={12} className="d-flex">
            <span>Fecha</span>
          </Col>
          <Col md={12}>
            <DateBox
              type="date"
              name="fecha"
              defaultValue={
                PlanificacionService.planificacionActual
                  ? dayjs(PlanificacionService.planificacionActual.fechaInicio).toDate()
                  : null
              }
              onValueChanged={handleChange}
              showClearButton>
              <Validator>
                <RequiredRule message={errorNulo} />
                <CustomRule
                  type="custom"
                  message={fechaNoValida}
                  validationCallback={(e: any) => !dayjs(e.value).isBefore(dayjs().subtract(1, 'day'))}
                />
              </Validator>
            </DateBox>
          </Col>
        </Row>
      </Col>
      <Col md={2}>
        <Row>
          <Col md={12} className="d-flex">
            <span>Vehículos Pequeños</span>
          </Col>
          <Col md={12}>
            <NumberBox
              placeholder="Vehículos Pequeños"
              name="vehiculosPequenos"
              defaultValue={
                PlanificacionService.planificacionActual
                  ? PlanificacionService.planificacionActual.numeroVehiculosPequenos
                  : null
              }
              onValueChanged={handleChange}
              min={0}>
              <Validator>
                <RequiredRule message={errorNulo} />
              </Validator>
            </NumberBox>
          </Col>
        </Row>
      </Col>
      <Col md={2}>
        <Row>
          <Col md={12} className="d-flex">
            <span>Vehículos Grandes</span>
          </Col>
          <Col md={12}>
            <NumberBox
              placeholder="Vehículos Grandes"
              name="vehiculosGrandes"
              defaultValue={
                PlanificacionService.planificacionActual
                  ? PlanificacionService.planificacionActual.numeroVehiculosGrandes
                  : null
              }
              onValueChanged={handleChange}
              min={0}>
              <Validator>
                <RequiredRule message={errorNulo} />
              </Validator>
            </NumberBox>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormularioPlan;
