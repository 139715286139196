const columnas = [
  {
    dataField: 'id',
    caption: 'Id',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'obra.id',
    caption: 'Obra FK',
    dataType: 'number',
    visible: false,
    alignment: 'left'
  },
  {
    dataField: 'obra.idOrigen',
    caption: 'Obra',
    dataType: 'string',
    alignment: 'left'
  },
  {
    dataField: 'coste',
    caption: 'Coste obra (€)',
    dataType: 'string',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.coste ? `${Number(e.coste).toFixed(1)} €` : 0;
    }
  },
  {
    dataField: 'costeSeguimiento',
    caption: 'Coste Seg. (€)',
    dataType: 'string',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.costeSeguimiento ? `${Number(e.costeSeguimiento).toFixed(1)} €` : 0;
    }
  },
  {
    dataField: 'costeDiferencia',
    caption: 'Coste Dif (€)',
    dataType: 'string',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.costeDiferencia ? `${Number(e.costeDiferencia).toFixed(1)} €` : 0;
    }
  },
  {
    dataField: 'paradas',
    caption: 'Paradas',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'paradasSeguimiento',
    caption: 'Paradas Seg.',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'paradasDiferencia',
    caption: 'Paradas Dif.',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'horasExtra',
    caption: 'Horas extra',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'horasExtraSeguimiento',
    caption: 'Horas extra Seg.',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'horasExtraDiferencia',
    caption: 'Horas extra Dif.',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'productivo',
    caption: 'Productivo (%)',
    dataType: 'number',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.productivo ? e.productivo.toFixed(1) : 0;
    }
  },
  {
    dataField: 'productivoSeguimiento',
    caption: 'Productivo Seg. (%)',
    dataType: 'number',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.productivoSeguimiento ? e.productivoSeguimiento.toFixed(1) : 0;
    }
  },
  {
    dataField: 'productivoDiferencia',
    caption: 'Productivo Dif. (%)',
    dataType: 'number',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.productivoDiferencia ? e.productivoDiferencia.toFixed(1) : 0;
    }
  },
  {
    dataField: 'parada',
    caption: 'Parada (%)',
    dataType: 'number',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.parada ? e.parada.toFixed(1) : null;
    }
  },
  {
    dataField: 'paradaSeguimiento',
    caption: 'Parada Seg. (%)',
    dataType: 'number',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.paradaSeguimiento ? e.paradaSeguimiento.toFixed(1) : 0;
    }
  },
  {
    dataField: 'paradaDiferencia',
    caption: 'Parada Dif. (%)',
    dataType: 'number',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.paradaDiferencia ? e.paradaDiferencia.toFixed(1) : 0;
    }
  }
];

const columnasPlanificacionOptimizada = [
  {
    dataField: 'id',
    caption: 'Id',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'obra.id',
    caption: 'Obra FK',
    dataType: 'number',
    visible: false,
    alignment: 'left'
  },
  {
    dataField: 'obra.idOrigen',
    caption: 'Obra',
    dataType: 'string',
    alignment: 'left'
  },
  {
    dataField: 'coste',
    caption: 'Coste obra (€)',
    dataType: 'string',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.coste ? `${Number(e.coste).toFixed(1)} €` : 0;
    }
  },
  {
    dataField: 'paradas',
    caption: 'Paradas',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'horasExtra',
    caption: 'Horas extra',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'productivo',
    caption: 'Productivo (%)',
    dataType: 'number',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.productivo ? e.productivo.toFixed(1) : 0;
    }
  },
  {
    dataField: 'parada',
    caption: 'Parada (%)',
    dataType: 'number',
    alignment: 'left',
    calculateCellValue: (e: any) => {
      return e.parada ? e.parada.toFixed(1) : 0;
    }
  }
];

export { columnas, columnasPlanificacionOptimizada };
