import { DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import React from 'react';
import { Col } from 'react-bootstrap';

type Props = {
  indicadores: any[];
  ancho: number;
  columnaSeguimientoVisible: boolean;
  texto: string;
};

const exportConfig = {
  enabled: true,
  fileName: 'Indicadores'
};

const TablaIndicador: React.FC<Props> = (props) => {
  return (
    <Col md={props.ancho} className="pb-3">
      <p style={{ alignContent: 'center', fontWeight: 'bold', fontSize: '1.2em' }}>{props.texto}</p>
      <DataGrid
        showRowLines
        dataSource={props.indicadores}
        allowColumnReordering={false}
        rowAlternationEnabled={false}
        showColumnLines
        export={exportConfig}
        showBorders>
        <Column dataField="parametro" caption="Parámetro" alignment="left" />
        <Column dataField="valor" caption="Optimizada" alignment="left" />
        <Column
          visible={props.columnaSeguimientoVisible}
          dataField="valorSeguimiento"
          caption="Seguimiento"
          alignment="left"
        />
        <Column
          visible={props.columnaSeguimientoVisible}
          dataField="diferencia"
          caption="Diferencia"
          alignment="left"
        />
      </DataGrid>
    </Col>
  );
};

export default TablaIndicador;
