import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Operacion from '../../../01.-Domain/Entities/Operacion';
import VehiculoGenerico from '../../../01.-Domain/Entities/VehiculoGenerico';
import toastService from '../../../02.-Application/Services/Base/ToastService';
import SeguimientoService from '../../../02.-Application/Services/SeguimientoService';
import Grid from '../../Components/DataGrid/Grid';

type Props = {
  show: boolean;
  vehiculos: VehiculoGenerico[];
  cerrarPopup: (vehiculosEliminados: boolean) => void;
};

const PopupVehiculos: React.FC<Props> = (props) => {
  const [vehiculosEliminados, setVehiculosEliminados] = useState<number[]>([]);

  async function EliminarVehiculo() {
    await SeguimientoService.EliminarVehiculosPlan(vehiculosEliminados)
      .then((result) => {
        if (result) {
          toastService.success('Vehículos eliminado correctamente');
          props.cerrarPopup(true);
        } else {
          toastService.error('Ha habido un error eliminando los vehículos');
        }
      })
      .catch(() => {
        toastService.error('Ha habido un error eliminando los vehículos');
      });
  }

  const handleSelectionChange = (e: any) => {
    if (e.selectedRowKeys.length > 0) {
      const ids = [];
      e.selectedRowKeys.forEach((element: Operacion) => {
        ids.push(element.id);
      });

      setVehiculosEliminados(ids);
    }
  };

  const GetColumnas = () => [
    {
      dataField: 'id',
      caption: 'Id',
      dataType: 'number',
      visible: false
    },
    {
      dataField: 'matricula',
      caption: 'Matrícula',
      dataType: 'string'
    },
    {
      dataField: 'conductor',
      caption: 'Conductor',
      dataType: 'string'
    },
    {
      dataField: 'tipoVehiculo.nombre',
      caption: 'Tipo',
      dataType: 'string'
    }
  ];

  return (
    <Modal
      size="lg"
      onHide={() => props.cerrarPopup(false)}
      show={props.show}
      backdrop="static"
      keyboard={false}
      style={{ top: '3em' }}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar vehículos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Grid
              altura={window.innerHeight - 300}
              showCheckBox="always"
              columns={GetColumnas()}
              source={SeguimientoService.vehiculos}
              editable={false}
              enableColumnChooser={false}
              summaryColumnCount="idOrigen"
              selectionChanged={handleSelectionChange}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => EliminarVehiculo()}>
          Borrar Seleccionados
        </Button>
        <Button variant="secondary" onClick={() => props.cerrarPopup(false)}>
          cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupVehiculos;
