import Tabs from 'devextreme-react/tabs';
import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import Graficas from '../../../01.-Domain/Entities/Graficas/Graficas';
import IndicadoresGlobales from '../../../01.-Domain/Entities/Indicadores/IndicadoresGlobales';
import IndicadoresObra from '../../../01.-Domain/Entities/Indicadores/IndicadoresObra';
import IndicadoresPlanta from '../../../01.-Domain/Entities/Indicadores/IndicadoresPlanta';
import IndicadoresTransporte from '../../../01.-Domain/Entities/Indicadores/IndicadoresTransporte';
import {
  columnas,
  columnasPlanificacionOptimizada
} from '../../Views/PlanificacionOptimizada/ColumnasIndicadoresObra';
import Grid from '../DataGrid/Grid';
import GraficasComponent from './GraficasComponent';
import TablaIndicador from './TablaIndicador';

type Props = {
  indicadoresGlobales: IndicadoresGlobales;
  indicadoresPlanta: IndicadoresPlanta;
  indicadoresTransporte: IndicadoresTransporte;
  indicadoresObra: IndicadoresObra[];
  graficas?: Graficas;
  show: boolean;
  cerrarPopup: () => void;
};

const PopupIndicadores: React.FC<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [graficasReales, setgraficasReales] = useState(null);
  const [graficasTeoricas, setGraficasTeoricas] = useState(null);
  const onTabsSelectionChanged = (e) => {
    setSelectedIndex(e.itemIndex);
  };

  const [columnaSeguimientoVisible, setcolumnaSeguimientoVisible] = useState(false);
  const [anchoTabla, setAnchoTabla] = useState(6);

  useEffect(() => {
    if (props.indicadoresGlobales && props.indicadoresGlobales.costeTotalSeguimiento) {
      setcolumnaSeguimientoVisible(true);
      setAnchoTabla(12);
    }
  }, []);

  function ObraSeleccionada(e: any) {
    setgraficasReales(props.graficas.extendido.find((x) => x.name === e.key.obra.idOrigen));
    setGraficasTeoricas(props.graficas.extendidoOriginal.find((x) => x.name === e.key.obra.idOrigen));
  }

  return (
    <Modal onHide={props.cerrarPopup} show={props.show} keyboard={false} fullscreen style={{ top: '4em' }}>
      <Modal.Header closeButton>
        <Modal.Title>Indicadores</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          className="mb-1"
          onItemClick={onTabsSelectionChanged}
          selectedIndex={selectedIndex}
          dataSource={[{ text: 'Generales' }, { text: 'Obras' }]}
        />
        {selectedIndex === 0 && (
          <>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <Container fluid>
                    <Row>
                      {props.indicadoresGlobales && (
                        <TablaIndicador
                          ancho={anchoTabla}
                          columnaSeguimientoVisible={columnaSeguimientoVisible}
                          indicadores={props.indicadoresGlobales.GetIndicadoresGlobalesDataGrid(
                            props.indicadoresGlobales
                          )}
                          texto="Indicadores Globales"
                        />
                      )}

                      {props.indicadoresPlanta && (
                        <TablaIndicador
                          ancho={anchoTabla}
                          columnaSeguimientoVisible={columnaSeguimientoVisible}
                          indicadores={props.indicadoresPlanta.GetIndicadoresPlantaDataGrid(
                            props.indicadoresPlanta
                          )}
                          texto="Indicadores Planta"
                        />
                      )}

                      {props.indicadoresTransporte && (
                        <TablaIndicador
                          ancho={anchoTabla}
                          columnaSeguimientoVisible={columnaSeguimientoVisible}
                          indicadores={props.indicadoresTransporte.GetIndicadoresTransporteDataGrid(
                            props.indicadoresTransporte
                          )}
                          texto="Indicadores Transporte"
                        />
                      )}
                    </Row>
                  </Container>
                </Col>
                <Col md={6}>
                  {props.graficas && (
                    <GraficasComponent
                      width={700}
                      graficasTransporte={props.graficas.transporte}
                      graficasTransporteOriginal={props.graficas.transporteOriginal}
                      tab={selectedIndex}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </>
        )}
        {selectedIndex === 1 && (
          <>
            {/* Pestaña de los indicadores de las obras */}
            <Container fluid>
              <Row className="d-flex justify-content-around">
                <Col md="12" lg="12" xl="6" xxl="6">
                  {/* Tabla de indicadores de obras */}
                  <Grid
                    source={props.indicadoresObra}
                    columns={columnaSeguimientoVisible ? columnas : columnasPlanificacionOptimizada}
                    editable={false}
                    enableColumnChooser
                    onRowClickedEvent={(e: any) => ObraSeleccionada(e)}
                  />
                </Col>
                <Col style={{ display: 'flex' }} md="12" lg="12" xl="6" xxl="6">
                  {/* Grafícas de indicadores de obras */}
                  {props.graficas && (
                    <GraficasComponent
                      width={700}
                      graficasExtendido={graficasReales}
                      graficasExtendidoOriginal={graficasTeoricas}
                      tab={selectedIndex}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default PopupIndicadores;
