import IndicadoresGlobales from '../../01.-Domain/Entities/Indicadores/IndicadoresGlobales';
import IndicadoresObra from '../../01.-Domain/Entities/Indicadores/IndicadoresObra';
import IndicadoresPlanta from '../../01.-Domain/Entities/Indicadores/IndicadoresPlanta';
import IndicadoresTransporte from '../../01.-Domain/Entities/Indicadores/IndicadoresTransporte';
import PlanificacionOptimizada from '../../01.-Domain/Entities/PlanificacionOptimizada';
import IIndicadoresService from '../ServicesInterfaces/IIndicadoresService';
const _ = require('lodash');

class IndicadoresService implements IIndicadoresService {
  // Indicadores Seguimiento

  private _indicadoresGlobalesSeguimiento: IndicadoresGlobales;
  public get indicadoresGlobalesSeguimiento(): IndicadoresGlobales {
    return this._indicadoresGlobalesSeguimiento;
  }
  public set indicadoresGlobalesSeguimiento(v: IndicadoresGlobales) {
    this._indicadoresGlobalesSeguimiento = v;
  }

  private _indicadoresTransporteSeguimiento: IndicadoresTransporte;
  public get indicadoresTransporteSeguimiento(): IndicadoresTransporte {
    return this._indicadoresTransporteSeguimiento;
  }
  public set indicadoresTransporteSeguimiento(v: IndicadoresTransporte) {
    this._indicadoresTransporteSeguimiento = v;
  }

  private _indicadoresPlantaSeguimiento: IndicadoresPlanta;
  public get indicadoresPlantaSeguimiento(): IndicadoresPlanta {
    return this._indicadoresPlantaSeguimiento;
  }
  public set indicadoresPlantaSeguimiento(v: IndicadoresPlanta) {
    this._indicadoresPlantaSeguimiento = v;
  }

  private _indicadoresObraSeguimiento: IndicadoresObra[];
  public get indicadoresObraSeguimiento(): IndicadoresObra[] {
    return this._indicadoresObraSeguimiento;
  }
  public set indicadoresObraSeguimiento(v: IndicadoresObra[]) {
    this._indicadoresObraSeguimiento = v;
  }

  // Indicadores Optimizada

  private _indicadoresGlobales: IndicadoresGlobales;
  public get indicadoresGlobales(): IndicadoresGlobales {
    return this._indicadoresGlobales;
  }
  public set indicadoresGlobales(v: IndicadoresGlobales) {
    this._indicadoresGlobales = v;
  }

  private _indicadoresTransporte: IndicadoresTransporte;
  public get indicadoresTransporte(): IndicadoresTransporte {
    return this._indicadoresTransporte;
  }
  public set indicadoresTransporte(v: IndicadoresTransporte) {
    this._indicadoresTransporte = v;
  }

  private _indicadoresPlanta: IndicadoresPlanta;
  public get indicadoresPlanta(): IndicadoresPlanta {
    return this._indicadoresPlanta;
  }
  public set indicadoresPlanta(v: IndicadoresPlanta) {
    this._indicadoresPlanta = v;
  }

  private _indicadoresObra: IndicadoresObra[];
  public get indicadoresObra(): IndicadoresObra[] {
    return this._indicadoresObra;
  }
  public set indicadoresObra(v: IndicadoresObra[]) {
    this._indicadoresObra = v;
  }

  public CargaIndicadores(p: PlanificacionOptimizada) {
    this.indicadoresGlobales = new IndicadoresGlobales(p.indicadoresGlobales);
    this.indicadoresPlanta = new IndicadoresPlanta(p.indicadoresPlanta);
    this.indicadoresTransporte = new IndicadoresTransporte(p.indicadoresTransporte);

    this.indicadoresObra = [];

    p.indicadoresObras.forEach((element) => {
      this.indicadoresObra.push(new IndicadoresObra([element]));
    });

    this.indicadoresObra.sort((op1, op2) => {
      return op1.obra && op1.obra.id > op2.obra.id ? -1 : 1;
    });
  }

  public CargaIndicadoresSeguimiento(p: PlanificacionOptimizada) {
    this.indicadoresGlobalesSeguimiento = new IndicadoresGlobales(p.indicadoresGlobales);
    this.indicadoresPlantaSeguimiento = new IndicadoresPlanta(p.indicadoresPlanta);
    this.indicadoresTransporteSeguimiento = new IndicadoresTransporte(p.indicadoresTransporte);
    this.indicadoresObraSeguimiento = [];

    const agrupados = _.groupBy(p.indicadoresObras, (x) => x.obra && x.obra.id);

    _.forEach(agrupados, (x) => {
      this.indicadoresObraSeguimiento.push(new IndicadoresObra(x));
    });

    this.indicadoresObra.sort((op1, op2) => {
      return op1.obra && op1.obra.id > op2.obra.id ? -1 : 1;
    });
  }

  constructor() {
    this._indicadoresGlobales = null;
    this._indicadoresPlanta = null;
    this._indicadoresTransporte = null;
    this._indicadoresObra = [];

    this._indicadoresGlobalesSeguimiento = null;
    this._indicadoresPlantaSeguimiento = null;
    this._indicadoresTransporteSeguimiento = null;
    this._indicadoresObraSeguimiento = [];
  }
}

export default new IndicadoresService();
