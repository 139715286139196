import Tramo from '../../01.-Domain/Entities/Tramo';
import ITramoRepository from '../../01.-Domain/RepositoriesInterfaces/ITramoRepository';
import GenericRepository from '../Base/GenericRepository';

class TramoRepository extends GenericRepository<Tramo> implements ITramoRepository {
  constructor() {
    super('Tramo');
  }
}

export default new TramoRepository();
