import { useLeafletContext } from '@react-leaflet/core';
import L, { LatLng } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import Location from '../../../01.-Domain/Entities/Location';
import Obra from '../../../01.-Domain/Entities/Obra';
import { attribution, GetIcon, url } from '../../Components/Map/MapUtils';

export interface Props {
  show: boolean;
  cerrarPopup: () => void;
  posicionObraCambiada: (posicion: Location) => void;
  obra?: Obra;
}

const PopupPosicionObra: React.FC<Props> = (props) => {
  const [nuevaPosicion, setNuevaPosicion] = useState<LatLng>(null);

  useEffect(() => {
    if (props.obra) {
      setNuevaPosicion(new LatLng(props.obra.location.latitud, props.obra.location.longitud));
    }
  }, [props.obra]);

  const EstablecerPosicion = async () => {
    props.posicionObraCambiada(
      new Location({
        latitud: nuevaPosicion.lat,
        longitud: nuevaPosicion.lng
      })
    );
  };

  function SetMarkers() {
    const map = useLeafletContext().map;

    map.eachLayer((marker: any) => {
      if (marker._icon) {
        map.removeLayer(marker);
      }
    });

    L.marker(nuevaPosicion, { icon: GetIcon('black') }).addTo(map);

    useMapEvents({
      click(e: any) {
        setNuevaPosicion(e.latlng);
      }
    });

    return null;
  }

  return (
    <Modal
      style={{ top: '3em' }}
      show={props.show}
      onHide={props.cerrarPopup}
      backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Posición Obra</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '400px' }}>
        <span>Click para establecer posición</span>
        {nuevaPosicion && (
          <MapContainer center={nuevaPosicion} zoom={10} style={{ height: 370 }}>
            <TileLayer attribution={attribution} url={url} />

            <SetMarkers />
          </MapContainer>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={EstablecerPosicion}>
          Establecer posición
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupPosicionObra;
