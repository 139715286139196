import dayjs from 'dayjs';
import { GraficaEnum } from '../../../01.-Domain/Enums/GraficaEnum';
const es = require('apexcharts/dist/locales/es.json');

function ObtenerGraficaToneladas(tmTotal: number, tmExtendido: number, tmTransporte) {
  return {
    totales: tmTotal,
    series: [tmExtendido, tmTotal - tmExtendido - tmTransporte, tmTransporte],
    options: {
      chart: {
        width: 300,
        type: 'pie'
      },
      dataLabels: {
        formatter: function (value: number) {
          return `${value.toFixed(0)} %`;
        }
      },
      labels: ['Extendido', 'Pendiente', 'En Transporte'],
      legend: {
        show: true,
        position: 'right',
        itemMargin: {
          horizontal: 0,
          vertical: 0
        }
      }
    }
  };
}

function ObtenerDatosGraficas(tipoGrafica: GraficaEnum, graficas, graficasOriginal?) {
  return {
    series: ObtenerSeries(tipoGrafica, graficas, graficasOriginal),
    options: {
      chart: {
        locales: [es],
        defaultLocale: 'es',
        id: 'charFab',
        type: 'line'
      },
      annotations: {
        // columna vertical indicando la hora actual
        xaxis: [
          {
            x: new Date().getTime(),
            strokeDashArray: 5,
            borderColor: '#D93E04',
            label: {
              borderColor: '#775DD0',
              style: {
                color: '#fff',
                background: '#775DD0'
              },
              text: dayjs().format('HH:mm')
            }
          }
        ]
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: true,
          formatter: function (timestamp) {
            return dayjs(new Date(timestamp)).format('HH:mm');
          }
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
        itemMargin: {
          vertical: 10
        }
      },
      markers: {
        size: [4, 6]
      },
      tooltip: {
        enabled: true,
        followCursos: true,
        x: {
          formatter: function (value) {
            return `${dayjs(value).format('HH:mm')}`;
          }
        }
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            width: 200
          }
        }
      ]
    }
  };
}

function ObtenerSeries(tipoGrafica: GraficaEnum, datosExtReal, datosExtendidoTeorico?) {
  if (!datosExtReal) {
    return;
  }

  let textoReal: string = tipoGrafica === GraficaEnum.EXTENDIDO ? 'Ext. real' : 'Fab. real';
  let textoTeorico: string = tipoGrafica === GraficaEnum.EXTENDIDO ? 'Ext. teórico' : 'Fab. Teórica';

  const serie = [];
  const serieExtendidoReal = { name: `${datosExtReal.name} ${textoReal}`, data: [] };

  if (datosExtendidoTeorico) {
    const serieExtendidoTeorico = { name: `${datosExtendidoTeorico.name} ${textoTeorico}`, data: [] };

    datosExtendidoTeorico.elementos.map((el) => serieExtendidoTeorico.data.push([new Date(el.x), el.y]));
    datosExtReal.elementos.map((el) => serieExtendidoTeorico.data.push([new Date(el.x), null]));
    serie.push(serieExtendidoTeorico);

    datosExtendidoTeorico.elementos.map((el) => serieExtendidoReal.data.push([new Date(el.x), null]));
  }

  datosExtReal.elementos.map((el) => serieExtendidoReal.data.push([new Date(el.x), el.y]));
  serie.push(serieExtendidoReal);

  return serie;
}

export { ObtenerDatosGraficas, ObtenerGraficaToneladas };
