export default class EstadoGrid {
  rol: string;
  estado: string;

  constructor(obj?: EstadoGrid) {
    if (obj) {
      this.rol = obj.rol;
      this.estado = obj.estado;
    } else {
      this.rol = null;
      this.estado = null;
    }
  }
}
