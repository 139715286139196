import ElementoGrafica from './ElementoGrafica';

export default class SerieGrafica {
  public name: string;

  public elementos: ElementoGrafica[];

  constructor(obj?: SerieGrafica) {
    if (obj) {
      this.elementos = [];

      this.name = obj.name;
      obj.elementos.forEach((element: ElementoGrafica) => {
        this.elementos.push(new ElementoGrafica(element));
      });
    } else {
      this.name = '';
      this.elementos = [];
    }
  }
}
