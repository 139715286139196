import dayjs from 'dayjs';
import { EventoEnum } from '../Enums/EventoEnum';

export default class EventoVehiculo {
  fecha: string;
  tipo: EventoEnum;
  codigoObra: string;
  comentarios: string;
  identificadorPlanta: string;

  constructor(tipo: EventoEnum, codigoObra: string, comentarios: string = null, idOrigenPlanta: string) {
    this.fecha = dayjs().toISOString();
    this.tipo = tipo;
    this.codigoObra = codigoObra;
    this.comentarios = comentarios;
    this.identificadorPlanta = idOrigenPlanta;
  }
}
