import { Button } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import AutenticationService from '../../../02.-Application/Services/AutenticationService';
import SeguimientoService from '../../../02.-Application/Services/SeguimientoService';
import ConfirmPopUp from '../../Components/ConfirmPopUp/ConfirmPopUp';
import './Seguimiento.scss';

interface Props {
  AccionBtn: (accion: string) => void;
  horaActualizacion: string;
  time: string;
}

type OpcionesBtn = {
  id: number;
  visible: boolean;
  class: string;
  accion: string;
  texto: string;
  classTexto: string;
};

const AccionesToolbar: React.FC<Props> = (props) => {
  const [planDisponible, setPlanDisponible] = useState<boolean>(false);
  const [popupConfirmarFin, setPopupConfirmarFin] = useState<boolean>(false);

  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const [permisoModificacion, setPermisoModificacion] = useState<boolean>(true);
  const [permisoEdicion, setPermisoEdicion] = useState<boolean>(true);
  const [esRespPlanta, setEsRespPlanta] = useState<boolean>(true);

  useEffect(() => {
    setPath(location.pathname);

    setPermisoModificacion(AutenticationService.TienePermisoModificacion(path.substring(1)));
    setPermisoEdicion(AutenticationService.MostrarBotonesSeguimiento());
    setEsRespPlanta(AutenticationService.EsResponsablePlanta());
    setPlanDisponible(SeguimientoService.planificacionEnSeguimiento !== null);
  }, []);

  const Opciones: OpcionesBtn[] = [
    {
      id: 0,
      visible: planDisponible && permisoModificacion && !esRespPlanta,
      class: 'btnAccion',
      accion: 'replanificar',
      texto: 'Replanificar',
      classTexto: 'txtBtnAccion'
    },
    {
      id: 1,
      visible: planDisponible && permisoModificacion,
      class: 'btnAccion',
      accion: 'indicadores',
      texto: 'Indicadores',
      classTexto: 'txtBtnAccion'
    },
    {
      id: 2,
      visible: planDisponible && permisoModificacion && !esRespPlanta,
      class: 'btnAccion',
      accion: 'vehiculos',
      texto: 'Eliminar Vehículos',
      classTexto: 'txtBtnAccion'
    },
    {
      id: 3,
      visible: planDisponible && permisoEdicion && !esRespPlanta,
      class: 'btnAccion',
      accion: 'iniciarExtendido',
      texto: 'Iniciar Ext.',
      classTexto: 'txtBtnAccion'
    },
    {
      id: 4,
      visible: planDisponible && permisoEdicion && !esRespPlanta,
      class: 'btnAccion',
      accion: 'finalizarExtendido',
      texto: 'Fin Ext.',
      classTexto: 'txtBtnAccion'
    },
    {
      id: 5,
      visible: planDisponible && permisoEdicion && !esRespPlanta,
      class: 'btnAccion',
      accion: 'rechazarCarga',
      texto: 'Rechazar Carga',
      classTexto: 'txtBtnAccion'
    },
    {
      id: 6,
      visible: planDisponible,
      class: 'btnAccion',
      accion: 'seleccionObra',
      texto: 'Sel. Obra',
      classTexto: 'txtBtnAccion'
    },
    {
      id: 7,
      visible: planDisponible,
      class: 'btnAccion',
      accion: 'guardarEstado',
      texto: 'Guardar Config.',
      classTexto: 'txtBtnAccion'
    },
    {
      id: 8,
      visible: planDisponible && permisoModificacion && !esRespPlanta,
      class: 'btnAccion',
      accion: 'popupModificarToneladas',
      texto: 'Mod. Ton.',
      classTexto: 'txtBtnAccion'
    }
  ];

  function ConfirmaFinalizarPlan() {
    props.AccionBtn('finalizarPlan');
    setPopupConfirmarFin(false);
  }

  return (
    <Row>
      <ConfirmPopUp
        show={popupConfirmarFin}
        texto={'¿Desea finalizar la planificación?'}
        titulo="Finalizar Planficicación"
        confirmar={ConfirmaFinalizarPlan}
        cancelar={() => setPopupConfirmarFin(false)}
      />

      <Row className="d-flex">
        <Col md={12} xxl={10}>
          {Opciones.map((btn) => {
            return (
              <Button
                key={btn.id}
                text={btn.texto}
                visible={btn.visible}
                type="default"
                className={btn.class}
                stylingMode="contained"
                onClick={() => props.AccionBtn(btn.accion)}
              />
            );
          })}

          <Button
            text="Fin Plan."
            visible={planDisponible && permisoModificacion && !esRespPlanta}
            type="default"
            className="btnAccion"
            stylingMode="contained"
            onClick={() => setPopupConfirmarFin(true)}
          />
        </Col>

        {SeguimientoService.planificacionEnSeguimiento && (
          <Col xxl={2}>
            <Row className="justify-content-end">
              <Col className="d-flex align-items-end flex-row">
                <h2>{`${SeguimientoService.planificacionEnSeguimiento?.descripcion}`}</h2>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-end flex-row">
                Ultima Actualización: {props.horaActualizacion}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Row>
  );
};

export default AccionesToolbar;
