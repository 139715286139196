import Location from './Location';

export default class Way {
  Origen: Location;
  Destino: Location;

  constructor(locOrigen: Location, locDestino: Location) {
    this.Origen = locOrigen;
    this.Destino = locDestino;
  }
}
