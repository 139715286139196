import { DataGrid } from 'devextreme-react';
import {
  ColumnChooser,
  Editing,
  Grouping,
  GroupPanel,
  Scrolling,
  SearchPanel,
  Selection,
  Sorting,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import { CellPreparedEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import React, { createRef, useEffect, useState } from 'react';

export type GridProps = {
  source: any[];
  columns?: any[];
  showCheckBox?: 'always' | 'none' | 'onClick' | null;
  selectionMode?: 'multiple' | 'single' | null;
  editable?: boolean;
  altura?: number;
  enableColumnChooser?: boolean;
  summaryColumnSum?: string;
  summaryColumnCount?: string;
  gridInitialized?: (dataGrid: any) => void;
  cellClicked?: (event: any) => void;
  selectionChanged?: (event: any) => void;
  rowUpdating?: (event: any) => void;
  onSaved?: (event: any) => void;
  onRowPreparedEvent?: (event: any) => void;
  onRowClickedEvent?: (event: any) => void;
  editMode?: 'batch' | 'cell' | null;
};

const Grid: React.FC<GridProps> = (props) => {
  const [cols, setCols] = useState([]);
  const [alturaGrid, setAlturaGrid] = useState<number>(
    window.innerHeight - (props.altura ? props.altura : 300)
  );

  let dataGrid = React.createRef<DataGrid>();

  useEffect(() => setCols(cols), []);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setAlturaGrid(window.innerHeight - (props.altura ? props.altura : 300));
    });
  }, [window.innerHeight]);

  const headerFilterConfig = { visible: true, allowSearch: true };
  const exportConfig = {
    enabled: true,
    fileName: 'Planificación'
  };
  const filterPanelConfig = { visible: true };

  const handleCellClick = (e: any) => {
    if (props.cellClicked) {
      props.cellClicked(e.column);
    }
  };

  const handleSelectionChange = (e: any) => {
    if (props.selectionChanged) {
      props.selectionChanged(e);
    }
  };

  const RowUpdating = (e: any) => {
    if (props.rowUpdating) {
      props.rowUpdating(e);
    }
  };

  const Saved = (e: any) => {
    if (props.onSaved) {
      props.onSaved(e);
    }
  };

  function RowPrepared(e: RowPreparedEvent<any, unknown>) {
    if (props.onRowPreparedEvent) {
      props.onRowPreparedEvent(e);
    }
  }

  function CellPrepared(e: CellPreparedEvent<any, unknown>) {
    if (e.rowType === 'data' && e.data.inicioProgramado === null) {
      e.cellElement.style.background = '#FCE4EC';
    }
  }

  function RowClicked(e: any) {
    if (props.onRowClickedEvent) {
      props.onRowClickedEvent(e);
    }
  }

  function GridInitialized(e: any) {
    if (props.gridInitialized) {
      props.gridInitialized(e);
    }

    dataGrid = e;
  }

  const GetCheckMode = () => (props.showCheckBox ? props.showCheckBox : 'none');
  const GetColumnChooserEnable = () => props.enableColumnChooser === true;

  return (
    <DataGrid
      height={alturaGrid}
      ref={dataGrid}
      dataSource={props.source}
      columns={props.columns}
      columnAutoWidth
      allowColumnResizing
      columnResizingMode="widget"
      allowColumnReordering
      rowAlternationEnabled
      showBorders
      headerFilter={headerFilterConfig}
      filterPanel={filterPanelConfig}
      export={exportConfig}
      onInitialized={GridInitialized}
      onCellClick={handleCellClick} // Devuelve la columna de la celda donde se ha hecho click
      onSelectionChanged={handleSelectionChange} // Devuelve las filas seleccionadas
      onRowUpdating={RowUpdating}
      onCellPrepared={CellPrepared}
      onRowPrepared={RowPrepared}
      onRowClick={RowClicked}
      onSaved={Saved}>
      <Scrolling mode="virtual" />

      <Editing
        mode={props.editMode == null ? 'batch' : props.editMode}
        allowUpdating={props.editable}
        allowAdding={false}
        allowDeleting={false}
        useIcons
      />
      <Sorting mode="multiple" />
      <Selection
        mode={props.selectionMode == null ? 'multiple' : props.selectionMode}
        selectAllMode={props.showCheckBox}
        showCheckBoxesMode={GetCheckMode()}
      />
      <GroupPanel visible />
      <Grouping expandMode="rowClick" autoExpandAll="true" />
      <ColumnChooser
        mode="select"
        enabled={GetColumnChooserEnable()}
        height={500}
        width={500}
        allowSearch="true"
      />
      <SearchPanel visible />

      <Summary>
        <TotalItem column={props.summaryColumnCount} summaryType="count" />
        <TotalItem column={props.summaryColumnSum} summaryType="sum" />
      </Summary>
    </DataGrid>
  );
};

export default Grid;
