import dayjs from 'dayjs';
import { DataGrid } from 'devextreme-react';
import {
  Button,
  Column,
  ColumnChooser,
  Editing,
  Grouping,
  GroupPanel,
  Lookup,
  RangeRule,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
  Sorting,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';
import FranjaHoraria from '../../../01.-Domain/Entities/FranjaHoraria';
import Location from '../../../01.-Domain/Entities/Location';
import Mezcla from '../../../01.-Domain/Entities/Mezcla';
import Obra from '../../../01.-Domain/Entities/Obra';
import TipoVehiculo from '../../../01.-Domain/Entities/TipoVehiculo';
import Trabajo from '../../../01.-Domain/Entities/Trabajo';
import Tramo from '../../../01.-Domain/Entities/Tramo';
import toastService from '../../../02.-Application/Services/Base/ToastService';
import { NumberToTimeFormat } from '../../../02.-Application/Services/DateTimeService';
import MezclaService from '../../../02.-Application/Services/MezclaService';
import { obraService } from '../../../02.-Application/Services/ObraService';
import PlanificacionService from '../../../02.-Application/Services/PlanificacionService';
import { plantaService } from '../../../02.-Application/Services/PlantaService';
import TipoVehiculoService from '../../../02.-Application/Services/TipoVehiculoService';
import TrabajoService from '../../../02.-Application/Services/TrabajoService';
import { tramoService } from '../../../02.-Application/Services/TramoService';
import './Planificacion.scss';
import PopupJornada from './PopupJornada';
import PopupPosicionObra from './PopupPosicionObra';

export type GridProps = {
  sourceTrabajos: Trabajo[];
  showCheckBox?: 'always' | 'none' | 'onClick' | null;
  editable?: boolean;
  tipoVehiculo: TipoVehiculo[];
  mezclas: Mezcla[];
  habilitarOptimizado: (habilitar: boolean) => void;
  cellClicked?: (event: any) => void;
  selectionChanged?: (event: any) => void;
  rowUpdating?: (event: any) => void;
  EliminarTrabajo?: () => void;
  Editando: (value: boolean) => void;
};

const GridPlanificacion: React.FC<GridProps> = (props) => {
  const [verPopupJornadas, setverPopupJornadas] = useState<boolean>(false);
  const [verPopupPosicionObra, setverPopupPosicionObra] = useState<boolean>(false);
  const [obraSeleccionada, setObraSeleccionada] = useState<Obra>(null);
  const [editing, setEditing] = useState<boolean>(false);
  const [rowEdited, setRowEdited] = useState(null);

  const [rowSelected, setRowSelected] = useState<Obra | null>(null);
  const [alturaGrid, setAlturaGrid] = useState<number>(window.innerHeight - 260);

  const headerFilterConfig = { visible: true, allowSearch: true };
  const exportConfig = {
    enabled: true,
    fileName: 'Planificación'
  };
  const filterPanelConfig = { visible: true };

  /**
   * Establece la altura del grid
   * cuando se modifica el tamaño de la pantalla
   */
  useEffect(() => {
    window.addEventListener('resize', () => {
      setAlturaGrid(window.innerHeight - 600);
    });
  }, [window.innerHeight]);

  function AbrirPopupJornada(e: any) {
    setRowSelected(e.data);
    setverPopupJornadas(!verPopupJornadas);
  }

  /**
   * Localiza el trabajo que se está editando y se modifica el horario
   * @param franjas FranjaHoraria[]
   */
  function GuardaFranjaHorarias(franjas: FranjaHoraria[]) {
    const trabajo = TrabajoService.trabajoPlanificado.find((x: Trabajo) => x.id === rowSelected?.id);
    trabajo.horario = franjas;
  }

  /**
   * Al pulsar el botón de guardar en la columna de edición
   * se actualizan los campos Número de viajes y los
   * trabajos del plan
   * @param e
   */
  async function GuardadoTerminado(e: any) {
    // Si hay cambios pendientes de guardar, se modifica el trabajo
    if (e.changes.length > 0) {
      const trabajo = TrabajoService.trabajoPlanificado.find((x: Trabajo) => x.id === e.changes[0].data.id);
      if (!e.changes[0].data.tpObraId) {
        trabajo.trabajoPredecesor = null;
      }

      trabajo.numeroViajes = CalcularNumeroViajes(
        trabajo.cantidadExtendido,
        trabajo.tipoVehiculo.capacidad
      );

      trabajo.costeTramoTrans = e.changes[0].data.tramo.importe;
      trabajo.tramo = e.changes[0].data.tramo;

      PlanificacionService.planificacionActual.trabajos = TrabajoService.trabajoPlanificado;
    }

    // Se cancela la edición
    setEditing(false);
    props.Editando(false);
  }

  /**
   * Se guarda la nueva ubicación de la obra con la seleccionada en el mapa
   * @param obra Obra
   */
  async function PosicionObraCambiada(posicion: Location) {
    obraService
      .GuardarUbicacionObra(obraSeleccionada.id, posicion)
      .then(async (res) => {
        const trabajo: Trabajo = props.sourceTrabajos.find((x: Trabajo) => x.id === rowEdited.data.id);
        obraSeleccionada.location = posicion;

        await TrabajoService.ActualizarTiempoTransporte(obraSeleccionada).then((nuevaDistancia) => {
          // Actualiza el trabajo para poner la nueva localización a la obra
          trabajo.obra.location = res.location;
          trabajo.tiempoTransporteMinutos = nuevaDistancia;
          setverPopupPosicionObra(false);
          toastService.success('Posición de obra cambiada');
        });
      })
      .catch(() => {
        toastService.error('Ha ocurrido un error');
      });
  }

  /**
   * Si hay cambios en la tabla pendientes de guardar,
   * se inhabilita el optimizado hasta que no se guarden
   * @param e
   */
  function validatingRow(e: any) {
    props.habilitarOptimizado(!e.brokenRules.length);
  }

  /**
   * Al actualizar una fila, se localiza el trabajo y se actualizan
   * el campo descripción en tipo de vehículo y mezcla
   * @param e
   */
  function handleRowUpdating(e: any) {
    const trabajo: Trabajo = props.sourceTrabajos.find((x: Trabajo) => x.id === e.key.id);

    if (e.newData.tipoVehiculo) {
      trabajo.tipoVehiculo = TipoVehiculoService.tipoVehiculos.find(
        (x: TipoVehiculo) => x.id === e.newData.tipoVehiculo.id
      );
    }

    if (e.newData.mezcla) {
      trabajo.mezcla = MezclaService.mezclas.find((x: Mezcla) => x.id === e.newData.mezcla.id);
    }

    if (e.newData.tramo) {
      trabajo.tramoId = tramoService.tramos.find((x: Tramo) => x.id === e.newData.tramo.id).id;
      trabajo.tramo = tramoService.tramos.find((x: Tramo) => x.id === e.newData.tramo.id);
    }

    if (props.rowUpdating) {
      props.rowUpdating(e);
    }
  }

  /**
   * Abre un popup con un mapa para geolocalizar
   * la obra seleccionada
   * @param data Trabajo
   */
  function AbrirPopupGelocalizarObra(data: Trabajo): void {
    if (!editing) {
      return;
    }

    setObraSeleccionada(data.obra);
    setverPopupPosicionObra(!verPopupPosicionObra);
  }

  /**
   * Si la columna pinchada es la de geolocalizar, se abre el popup de geolocaliar obra
   * @param e
   * @returns
   */
  function handleCellClick(e: any) {
    if (props.cellClicked) {
      if (e.column && String(e.column.caption).toLowerCase().trim() === 'geolocalizar') {
        AbrirPopupGelocalizarObra(e.data);
        return;
      }

      props.cellClicked(e);
    }
  }

  /**
   * Al hacer doble click en una celda, se comprueba
   * si es la de la jornada. En ese caso, se abre
   * el popup de edición de jornada
   * @param e
   */
  function handleDoubleClick(e: any) {
    if (!editing) {
      return;
    }

    if (String(e.column.caption).trim().toLowerCase() === 'horario') {
      AbrirPopupJornada(e);
    }
  }

  /**
   * Se calcula el número de viajes
   * dependiendo de la cantidad a extender
   * y la capacidad de los camiones seleccionados
   * @param extendido number
   * @param capacidad number
   * @returns number
   */
  function CalcularNumeroViajes(extendido: number, capacidad: number): number {
    let nViajes = null;

    if (extendido && capacidad) {
      nViajes = extendido / capacidad;
    }

    return Number.isNaN(nViajes) ? null : Math.ceil(nViajes);
  }

  /** Celda del icono de geolocalizar obra */
  function CellGeolocalizarObra(e: any) {
    const obra: Obra = e.data.obra;

    if (
      obra.location.latitud === plantaService.plantaActual.location.latitud &&
      obra.location.longitud === plantaService.plantaActual.location.longitud
    ) {
      return (
        <button style={{ border: 0, backgroundColor: '#FFF' }}>
          <span className="material-icons red600">location_on</span>
        </button>
      );
    }

    return (
      <button style={{ border: 0, backgroundColor: '#FFF' }}>
        <span className="material-icons green600">location_on</span>
      </button>
    );
  }

  function HandleEditingStart(e: any) {
    setRowEdited(e);
    setEditing(true);
    props.Editando(true);
  }

  return (
    <>
      {verPopupJornadas && (
        <PopupJornada
          show={verPopupJornadas}
          cerrarPopup={() => setverPopupJornadas(!verPopupJornadas)}
          GuardarFranja={GuardaFranjaHorarias}
        />
      )}

      {verPopupPosicionObra && (
        <PopupPosicionObra
          show={verPopupPosicionObra}
          obra={obraSeleccionada}
          cerrarPopup={() => setverPopupPosicionObra(!verPopupPosicionObra)}
          posicionObraCambiada={PosicionObraCambiada}
        />
      )}

      <DataGrid
        height={alturaGrid}
        dataSource={props.sourceTrabajos}
        columnAutoWidth
        allowColumnResizing
        columnResizingMode="widget"
        allowColumnReordering
        rowAlternationEnabled
        showBorders
        headerFilter={headerFilterConfig}
        filterPanel={filterPanelConfig}
        export={exportConfig}
        onCellClick={handleCellClick} // Devuelve la columna de la celda donde se ha hecho click
        onSelectionChanged={(e: any) => {
          if (props.selectionChanged) {
            props.selectionChanged(e);
          }
        }} // Devuelve las filas seleccionadas
        onRowUpdating={handleRowUpdating}
        onRowValidating={validatingRow}
        onEditingStart={HandleEditingStart}
        onSaved={GuardadoTerminado}
        onEditCanceled={() => {
          setEditing(false);
          props.Editando(false);
        }}
        onCellDblClick={handleDoubleClick}>
        <Summary>
          <TotalItem column="obra.idOrigen" summaryType="count" />
        </Summary>

        <Editing
          refresh-mode="repaint"
          mode="row"
          allowUpdating={props.editable}
          allowAdding={false}
          allowDeleting={false}
          useIcons
        />

        <Column type="buttons" width={70} showInColumnChooser={false}>
          <Button name="edit" />
          <Button>
            <span
              onClick={props.EliminarTrabajo}
              aria-hidden
              className="material-icons displayContents trashBtn md-16 red600">
              delete
            </span>
          </Button>
        </Column>

        {/* Columna cell template para el icono para geolocalizar una obra */}
        <Column width={40} alignment="center" caption="geolocalizar" cellRender={CellGeolocalizarObra} />

        <Column
          dataField="id"
          caption="Id"
          visible={false}
          alignment="left"
          allowEditing={false}
          dataType="number"
        />

        <Column
          dataField="obra.id"
          caption="Obrafk"
          visible={false}
          alignment="left"
          allowEditing={false}
          dataType="number"
        />

        <Column
          dataField="obra.idOrigen"
          caption="Obra"
          alignment="left"
          allowEditing={false}
          dataType="string"
        />

        <Column
          dataField="obra.descripcion"
          caption="Desc."
          alignment="left"
          width={60}
          allowEditing={false}
          dataType="string"
        />

        <Column dataField="capataz" caption="Capataz" alignment="left" allowEditing dataType="string">
          <RequiredRule />
        </Column>

        <Column dataField="tpObraId" caption="T. Pred." alignment="left">
          <Lookup
            allowClearing
            dataSource={(e: any) => {
              const dataSourceConfiguration = {
                store: e.data
                  ? TrabajoService.trabajoPlanificado.filter((x: Trabajo) => x.id !== e.data.id)
                  : TrabajoService.trabajoPlanificado
              };
              return dataSourceConfiguration;
            }}
            valueExpr="obraId"
            displayExpr="obraIdOrigen"
          />
        </Column>

        <Column dataField="ordenTrabajoPredecesor" alignment="left" caption="Orden" dataType="number" />

        <Column
          dataField="horario"
          caption="Horario"
          alignment="left"
          allowEditing={false}
          calculateDisplayValue={(e: Trabajo) => {
            let text: string = '';

            e.horario.forEach((element: FranjaHoraria) => {
              text +=
                typeof element.inicio === 'string' && typeof element.fin === 'string'
                  ? `${element.inicio.substring(0, 5)}-${element.fin.substring(0, 5)} // `
                  : `${dayjs(element.inicio).format('HH:mm')}-${dayjs(element.fin).format('HH:mm')} // `;
            });

            return text.substring(0, text.length - 4);
          }}
        />

        <Column dataField="prioridad" caption="Pri." alignment="left" allowEditing dataType="number">
          <RangeRule message="La prioridad debe estar entre 0 y 3" max={3} />
          <RequiredRule />
        </Column>

        <Column
          dataField="numeroOficiales"
          caption="Oficiales"
          alignment="left"
          allowEditing
          dataType="number">
          <RequiredRule />
        </Column>

        <Column dataField="numeroPeones" caption="Peones" alignment="left" allowEditing dataType="number">
          <RequiredRule />
        </Column>

        <Column dataField="tipoVehiculo.id" caption="Vehiculo" alignment="left">
          <RequiredRule />

          <Lookup
            dataSource={() => {
              const dataSourceConfiguration = {
                store: props.tipoVehiculo
              };
              return dataSourceConfiguration;
            }}
            valueExpr="id"
            displayExpr="nombre"
          />
        </Column>

        <Column
          dataField="cantidadExtendido"
          caption="Extendido"
          alignment="left"
          allowEditing
          dataType="number">
          <RequiredRule />
        </Column>

        <Column
          dataField="numeroViajes"
          caption="Viajes"
          alignment="left"
          allowEditing={false}
          dataType="number"
          calculateCellValue={(e: any) => {
            if (!e.cantidadExtendido || !e.tipoVehiculo) {
              return null;
            }

            return CalcularNumeroViajes(e.cantidadExtendido, e.tipoVehiculo.capacidad);
          }}
        />

        <Column dataField="mezcla.id" caption="Paramétrico" alignment="left">
          <RequiredRule />
          <Lookup
            dataSource={() => {
              const dataSourceConfiguration = {
                store: props.mezclas
              };
              return dataSourceConfiguration;
            }}
            valueExpr="id"
            displayExpr="idOrigen"
          />
        </Column>

        <Column
          dataField="mezcla.descripcion"
          width={70}
          allowEditing={false}
          caption="Mezcla"
          alignment="left"
        />

        <Column dataField="velocidad" caption="Velocidad" alignment="left" allowEditing dataType="number">
          <RequiredRule />
        </Column>

        <Column
          dataField="tiempoTransporteMinutos"
          caption="Transporte"
          alignment="left"
          allowEditing={false}
          dataType="datetime"
          format="HH:mm"
          calculateCellValue={(e: any) => {
            return NumberToTimeFormat(e.tiempoTransporteMinutos);
          }}>
          <RequiredRule />
        </Column>

        <Column dataField="tramo.id" caption="Tramo" alignment="left">
          <RequiredRule />
          <Lookup
            dataSource={() => {
              const dataSourceConfiguration = {
                type: 'array',
                pageSize: 10,
                paginate: true,
                store: tramoService.tramos
              };
              return dataSourceConfiguration;
            }}
            valueExpr="id"
            displayExpr="nombreTramo"
          />
        </Column>

        <Column
          dataField="costeTramoTrans"
          caption="Coste Tramo"
          alignment="left"
          allowEditing={false}
          dataType="number"></Column>

        <Column
          dataField="costeMaquinas"
          caption="Coste Maq."
          alignment="left"
          allowEditing
          dataType="number">
          <RequiredRule />
        </Column>

        <Column
          dataField="maxHorasExtra"
          caption="Max H. Extra"
          alignment="left"
          allowEditing
          dataType="number"
        />

        <Scrolling mode="virtual" />
        <Sorting mode="multiple" />
        <ColumnChooser mode="select" enabled height={500} width={500} allowSearch="true" />
        <Selection mode="multiple" selectAllMode={props.showCheckBox} showCheckBoxesMode={false} />
        <GroupPanel visible />
        <Grouping expandMode="rowClick" autoExpandAll="true" />
        <SearchPanel visible />
      </DataGrid>
    </>
  );
};

export default GridPlanificacion;
