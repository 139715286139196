import BaseEntity from './Base/BaseEntity';

export default class CosteTrabajadores extends BaseEntity {
  peon: number;
  peonHorasExtras: number;
  oficial: number;
  oficialHorasExtras: number;

  constructor(obj?: any) {
    super();

    if (obj) {
      this.id = obj.id;
      this.peon = obj.peon;
      this.peonHorasExtras = obj.peonHorasExtras;
      this.oficial = obj.oficial;
      this.oficialHorasExtras = obj.oficialHorasExtras;
    } else {
      this.peon = 0;
      this.peonHorasExtras = 0;
      this.oficial = 0;
      this.oficialHorasExtras = 0;
    }
  }
}
