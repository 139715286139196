import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, ValidationGroup } from 'devextreme-react';
import SelectBox from 'devextreme-react/select-box';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Planta from '../../../01.-Domain/Entities/Planta';
import { plantaService } from '../../../02.-Application/Services/PlantaService';
import LoaderComponent from '../../Components/Loader/LoaderComponent';
import './login.scss';

type Props = {
  show: boolean;
  cerrarPopup: () => void;
};

const PopupSeleccionPlanta: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [plantas, setPlantas] = useState<Planta[]>(plantaService.plantas);
  const [plantaSeleccionada, setPlantaSeleccionada] = React.useState<Planta>(null);
  const texto = 'Selecciona Planta';

  const [setLoader, setsetLoader] = useState<boolean>(null);
  const [textoLoader, setTextoLoader] = useState<string>('');

  async function GetPlantas() {
    await plantaService.GetAll().then(() => {
      setPlantas(plantaService.plantas);
      setsetLoader(true);
    });
  }

  useEffect(() => {
    setsetLoader(false);
    setTextoLoader('Cargando Datos');
    GetPlantas();
  }, []);

  async function Acceder(e: any) {
    setOpen(true);
    if (e.preventDefault) {
      e.preventDefault();
    }

    if (plantaSeleccionada !== null) {
      plantaService.plantaActual = new Planta(plantaSeleccionada);

      sessionStorage.removeItem('plantaActual');
      sessionStorage.setItem('plantaActual', JSON.stringify(plantaService.plantaActual));
    }

    props.cerrarPopup();
  }

  async function onValueChanged(e: any) {
    setPlantaSeleccionada(e.value);
  }

  if (!setLoader) {
    return <LoaderComponent enabled texto={textoLoader} />;
  }

  return (
    <Modal onHide={props.cerrarPopup} show={props.show} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{texto}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress size={90} variant="indeterminate" color="primary" />
        </Backdrop>
        <form onSubmit={Acceder}>
          <ValidationGroup>
            <Row className="d-flex justify-content-around">
              <Col md={11} className="my-3">
                <SelectBox
                  items={plantas}
                  value={plantaSeleccionada}
                  displayExpr="nombre"
                  onValueChanged={onValueChanged}
                />
              </Col>
              <Col md={11} className="mt-4">
                <Button
                  disabled={!plantaSeleccionada}
                  useSubmitBehavior
                  text={texto}
                  type="default"
                  stylingMode="contained"
                />
              </Col>
            </Row>
          </ValidationGroup>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PopupSeleccionPlanta;
