import LoginResponse from '../../01.-Domain/Entities/Autentication/LoginResponse';
import Permisos from '../../01.-Domain/Entities/Autentication/Permisos';
import IAutenticationRepository from '../../01.-Domain/RepositoriesInterfaces/IAutenticationRepository';
import GenericRepository from '../Base/GenericRepository';
import InstalacionesResponse from '../../01.-Domain/Entities/Autentication/InstalacionesResponse';

class AutenticationRepository extends GenericRepository<LoginResponse> implements IAutenticationRepository {
  constructor() {
    super('Authentication');
  }

  async Autenticate(user: string, pass: string): Promise<LoginResponse> {
    try {
      const res = await this.post([{ value: 'authenticate' }], {
        username: user,
        password: pass,
        application: ''
      });
      return res;
    } catch (error) {
      return null;
    }
  }

  async ObtenerPermisos(jwt: string): Promise<Permisos> {
    try {
      const res = await this.post([{ value: 'permisos' }], jwt);
      return res;
    } catch (error) {
      return null;
    }
  }

  async ObtenerPermisosPlanta(jwt: string): Promise<InstalacionesResponse> {
    try {
      const res = await this.post([{ value: 'instalaciones' }], jwt);
      return res;
    } catch (error) {
      return null;
    }
  }
}

export default new AutenticationRepository();
