export default class Location {
  latitud: number;
  longitud: number;

  constructor(obj?: Location) {
    if (obj) {
      this.latitud = obj.latitud;
      this.longitud = obj.longitud;
    } else {
      this.latitud = 0;
      this.longitud = 0;
    }
  }
}
