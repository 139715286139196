import Mezcla from '../../01.-Domain/Entities/Mezcla';
import MezclaRepository from '../../03.-Infraestructure/Repositories/MezclaRepository';
import IMezclaService from '../ServicesInterfaces/IMezclaService';

class MezclaService implements IMezclaService {
  private _mezclas: Mezcla[];

  public get mezclas(): Mezcla[] {
    return this._mezclas;
  }

  public set mezclas(v: Mezcla[]) {
    this._mezclas = v;
  }

  constructor() {
    this._mezclas = [];
  }

  async GetAll(): Promise<boolean> {
    this._mezclas = [];
    const res = await MezclaRepository.GetMezclas();

    res.forEach((m: Mezcla) => {
      this._mezclas.push(new Mezcla(m));
    });

    return true;
  }
}

export default new MezclaService();
