import Data from './Data';

export default class Permisos {
  data: Data;
  errorCode: string;
  errorMessage: string;
  success: boolean;

  constructor(obj?: any) {
    if (obj) {
      this.data = new Data(obj.data);
      this.errorCode = obj.errorCode;
      this.errorMessage = obj.errorMessage;
      this.success = obj.success;
    } else {
      this.data = null;
      this.errorCode = null;
      this.errorMessage = null;
      this.success = null;
    }
  }
}
