import { plantaService } from '../../02.-Application/Services/PlantaService';
import { tramoService } from '../../02.-Application/Services/TramoService';
import BaseEntity from './Base/BaseEntity';
import FranjaHoraria from './FranjaHoraria';
import Mezcla from './Mezcla';
import Obra from './Obra';
import TipoVehiculo from './TipoVehiculo';
import Tramo from './Tramo';

export default class Trabajo extends BaseEntity {
  obra: Obra;
  capataz: string;
  codigoCapataz: string;
  trabajoPredecesor: Trabajo;
  tpObraId: number;
  tpObraDescripcion: string;
  obraId: number;
  obraDescripcion: string;
  obraIdOrigen: string;
  ordenTrabajoPredecesor: number;
  prioridad: number;
  numeroOficiales: number;
  numeroPeones: number;
  tipoVehiculo: TipoVehiculo;
  cantidadExtendido: number;
  numeroViajes: number;
  mezcla: Mezcla;
  horario: FranjaHoraria[];
  tramo: Tramo;
  velocidad: number;
  maxHorasExtra: number;
  costeMaquinas: number;
  tramoId: number;
  costeTramoTrans: number;
  costeTotal: number;
  tiempoTransporteMinutos: number;

  constructor(obj?: any) {
    super();
    this.horario = [];

    if (obj) {
      this.id = obj.id;
      this.obra = new Obra(obj.obra);
      this.capataz = obj.capataz;
      this.codigoCapataz = obj.codigoCapataz;
      this.trabajoPredecesor = obj.trabajoPredecesor ? new Trabajo(obj.trabajoPredecesor) : null;
      this.tpObraId = obj.trabajoPredecesor ? obj.trabajoPredecesor.obra.id : null;
      this.tpObraDescripcion = obj.trabajoPredecesor ? obj.trabajoPredecesor.obra.descripcion : null;
      this.obraId = obj.obra.id;
      this.obraDescripcion = obj.obra.descripcion;
      this.obraIdOrigen = obj.obra.idOrigen;
      this.ordenTrabajoPredecesor = obj.ordenTrabajoPredecesor;
      this.prioridad = obj.prioridad;
      this.numeroOficiales = obj.numeroOficiales;
      this.numeroPeones = obj.numeroPeones;
      this.tipoVehiculo = obj.tipoVehiculo ? new TipoVehiculo(obj.tipoVehiculo) : null;
      this.cantidadExtendido = obj.cantidadExtendido;
      this.numeroViajes = obj.numeroViajes;
      this.mezcla = obj.mezcla ? new Mezcla(obj.mezcla) : null;
      obj.horario.forEach((element: FranjaHoraria) => {
        this.horario.push(new FranjaHoraria(element));
      });
      this.tramo = tramoService.tramos.find((x: Tramo) => x.id === obj.tramoId);
      this.velocidad = obj.velocidad;
      this.maxHorasExtra = obj.maxHorasExtra;
      this.costeMaquinas = obj.costeMaquinas;
      this.tramoId = tramoService.tramos.find((x: Tramo) => x.id === obj.tramoId)?.id;
      this.costeTramoTrans = obj.costeTramoTrans;
      this.costeTotal = obj.costeTotal;
      this.tiempoTransporteMinutos = obj.tiempoTransporteMinutos;
    } else {
      this.obra = new Obra();
      this.capataz = '';
      this.codigoCapataz = '';
      this.trabajoPredecesor = null;
      this.tpObraId = null;
      this.tpObraDescripcion = '';
      this.obraId = null;
      this.obraDescripcion = '';
      this.obraIdOrigen = '';
      this.ordenTrabajoPredecesor = null;
      this.prioridad = 0;
      this.numeroOficiales = null;
      this.numeroPeones = null;
      this.tipoVehiculo = null;
      this.cantidadExtendido = null;
      this.numeroViajes = null;
      this.mezcla = null;
      this.horario = this.ObtenerHorarioObras();
      this.tramo = null;
      this.velocidad = null;
      this.maxHorasExtra = 0;
      this.costeMaquinas = null;
      this.tramoId = null;
      this.costeTramoTrans = null;
      this.costeTotal = 0;
      this.tiempoTransporteMinutos = null;
    }
  }

  /**
   * Devuelve un array de franjas con la configuración
   * actual del horario de obras de la planta
   * @returns FranjaHoraria[]
   */
  private ObtenerHorarioObras(): FranjaHoraria[] {
    const horario: FranjaHoraria[] = [];

    const configuracion = plantaService.plantaActual.configuracionPlanta;
    if (configuracion.horaInicioMananaObras && configuracion.horaFinMananaObras) {
      const frManana = new FranjaHoraria();
      frManana.id = 0;
      frManana.inicio = configuracion.horaInicioMananaObras;
      frManana.fin = configuracion.horaFinMananaObras;

      horario.push(frManana);
    }

    if (configuracion.horaInicioTardeObras && configuracion.horaFinTardeObras) {
      const frTarde = new FranjaHoraria();
      frTarde.id = 1;
      frTarde.inicio = configuracion.horaInicioTardeObras;
      frTarde.fin = configuracion.horaFinTardeObras;

      horario.push(frTarde);
    }

    return horario;
  }
}
