import 'dayjs/locale/es';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AutenticationService from '../../../02.-Application/Services/AutenticationService';
import logoAvi from '../../../assets/images/LogoAvi.jpg';
import logoPavasal2 from '../../../assets/images/Marca-PAVASAL.png';
import PopupLogin from './PopupLogin';
import PopupSeleccionPlanta from './PopupSeleccionPlanta';

const LoginView: React.FC = () => {
  const [verPopupLogin, setVerPopupLogin] = useState<boolean>(true);
  const [puedePlanificar, setPuedePlanificar] = useState<boolean>(false);
  const [verPopupSeleccionPlanta, setVerPopupSeleccionPlanta] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Si no tiene permisos para planificar, no se muestra el botón iniciar
    setPuedePlanificar(AutenticationService.PuedePlanificar());

    setVerPopupLogin(sessionStorage.getItem('token') === null);
  }, [sessionStorage.getItem('token')]);

  function LoginOK() {
    setVerPopupLogin(!verPopupLogin);
    setVerPopupSeleccionPlanta(!verPopupSeleccionPlanta);
  }

  function PlantaSeleccionada() {
    setVerPopupSeleccionPlanta(!verPopupSeleccionPlanta);
    navigate('/main', { replace: true });
  }

  return (
    <>
      <PopupLogin show={verPopupLogin} cerrarPopup={() => LoginOK()}></PopupLogin>

      {verPopupSeleccionPlanta && (
        <PopupSeleccionPlanta show={verPopupSeleccionPlanta} cerrarPopup={() => PlantaSeleccionada()} />
      )}

      <div className="container fluid App">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <h1 style={{ fontSize: '8em', fontWeight: 'bold' }}>IASF</h1>
          </div>
          <div className="col d-flex justify-content-center">
            <img style={{ zoom: '100%' }} src={logoPavasal2} alt="Pavasal" title="Logo_Pavasal" />
          </div>
        </div>

        {puedePlanificar && (
          <div className="row">
            <div className="col-12 d-flex justify-content-center pt-5">
              <Link to="/planificacion">
                <Button variant="success" size="lg">
                  Iniciar
                </Button>
              </Link>
            </div>
          </div>
        )}
        <div className="row d-flex" style={{ position: 'absolute', right: '2em', bottom: '2em' }}>
          <div className="col-12 d-flex justify-content-end" style={{ marginTop: 'auto' }}>
            <img src={logoAvi} alt="AVI" title="Logo_AVI" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginView;
