import Tramo from '../../01.-Domain/Entities/Tramo';
import TramoRepository from '../../03.-Infraestructure/Repositories/TramoRepository';
import ITramoService from '../ServicesInterfaces/ITramoService';
import { plantaService } from './PlantaService';

class TramoService implements ITramoService {
  private _tramos: Tramo[];
  public get tramos(): Tramo[] {
    return this._tramos;
  }
  public set tramos(v: Tramo[]) {
    this._tramos = v;
  }

  constructor() {
    this.tramos = [];
  }

  async GetTramos(): Promise<Tramo[]> {
    this.tramos = [];

    const res = await TramoRepository.getAll();
    res.forEach((x) => {
      if (
        x.instalacion.toLowerCase().trim() === plantaService.plantaActual.instalacion.toLowerCase().trim()
      ) {
        const tramo = new Tramo();
        tramo.id = x.id_Tramo;
        tramo.importe = parseFloat(x.importe.replace(',', '.'));
        tramo.instalacion = x.instalacion;
        tramo.nombreTramo = x.nombreTramo;
        tramo.km = x.km;

        this.tramos.push(tramo);

        return tramo;
      }
    });

    return this.tramos;
  }
}
export const tramoService = new TramoService();
