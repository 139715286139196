import { EstadoPlanificacionEnum } from '../../Enums/EstadoPlanificacionEnum';
import BaseEntity from '../Base/BaseEntity';
import { GridObject } from './IndicadoresGrid';

export default class IndicadoresTransporte extends BaseEntity {
  coste: number;
  costeSeguimiento: number;
  costeDiferencia: number;
  camionesGrandes: number;
  camionesGrandesSeguimiento: number;
  camionesGrandesDiferencia: number;
  camionesPequenos: number;
  camionesPequenosSeguimiento: number;
  camionesPequenosDiferencia: number;
  compensacion: number;
  compensacionSeguimiento: number;
  compensacionDiferencia: number;
  numCompensaciones: number;
  numCompensacionesSeguimiento: number;
  numCompensacionesDiferencia: number;
  salarioMinimo: number;
  salarioMinimoSeguimiento: number;
  salarioMinimoDiferencia: number;
  salarioMaximo: number;
  salarioMaximoSeguimiento: number;
  salarioMaximoDiferencia: number;
  estado: string;

  constructor(obj?: IndicadoresTransporte[]) {
    super();

    if (obj.length) {
      obj.forEach((indicadores: IndicadoresTransporte) => {
        if (EstadoPlanificacionEnum[indicadores.estado] === EstadoPlanificacionEnum[0]) {
          this.id = indicadores.id;
          this.coste = indicadores.coste;
          this.camionesGrandes = indicadores.camionesGrandes;
          this.camionesPequenos = indicadores.camionesPequenos;
          this.compensacion = indicadores.compensacion;
          this.numCompensaciones = indicadores.numCompensaciones;
          this.salarioMinimo = indicadores.salarioMinimo;
          this.salarioMaximo = indicadores.salarioMaximo;
          this.estado = EstadoPlanificacionEnum[indicadores.estado];
        }

        if (EstadoPlanificacionEnum[indicadores.estado] === EstadoPlanificacionEnum[1]) {
          this.costeSeguimiento = indicadores.coste;
          this.camionesGrandesSeguimiento = indicadores.camionesGrandes;
          this.camionesPequenosSeguimiento = indicadores.camionesPequenos;
          this.compensacionSeguimiento = indicadores.compensacion;
          this.numCompensacionesSeguimiento = indicadores.numCompensaciones;
          this.salarioMinimoSeguimiento = indicadores.salarioMinimo;
          this.salarioMaximoSeguimiento = indicadores.salarioMaximo;

          this.costeDiferencia = this.coste - this.costeSeguimiento;
          this.camionesGrandesDiferencia = this.camionesGrandes - this.camionesGrandesSeguimiento;
          this.camionesPequenosDiferencia = this.camionesPequenos - this.camionesPequenosSeguimiento;
          this.compensacionDiferencia = this.compensacion - this.compensacionSeguimiento;
          this.numCompensacionesDiferencia = this.numCompensaciones - this.numCompensacionesSeguimiento;
          this.salarioMinimoDiferencia = this.salarioMinimo - this.salarioMinimoSeguimiento;
          this.salarioMaximoDiferencia = this.salarioMaximo - this.salarioMaximoSeguimiento;
        }
      });
    } else {
      this.coste = 0;
      this.costeSeguimiento = 0;
      this.costeDiferencia = 0;
      this.camionesGrandes = 0;
      this.camionesGrandesSeguimiento = 0;
      this.camionesGrandesDiferencia = 0;
      this.camionesPequenos = 0;
      this.camionesPequenosSeguimiento = 0;
      this.camionesPequenosDiferencia = 0;
      this.compensacion = 0;
      this.compensacionSeguimiento = 0;
      this.compensacionDiferencia = 0;
      this.numCompensaciones = 0;
      this.numCompensacionesSeguimiento = 0;
      this.numCompensacionesDiferencia = 0;
      this.salarioMinimo = 0;
      this.salarioMinimoSeguimiento = 0;
      this.salarioMinimoDiferencia = 0;
      this.salarioMaximo = 0;
      this.salarioMaximoSeguimiento = 0;
      this.salarioMaximoDiferencia = 0;
      this.estado = null;
    }
  }

  GetIndicadoresTransporteDataGrid(indicadoresTransporte): any[] {
    const actuales = [];
    let contador = 0;

    Object.keys(indicadoresTransporte).forEach((actual) => {
      const o: GridObject = new GridObject();
      o.id = contador;
      contador++;

      switch (actual) {
        case 'coste': {
          o.parametro = actual;
          o.parametro = 'Coste';
          o.valor = `${indicadoresTransporte[actual].toFixed(1).toLocaleString('ES-es')} €`;

          if (indicadoresTransporte[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresTransporte[`${actual}Seguimiento`]
              .toFixed(1)
              .toLocaleString('ES-es')} €`;
            o.diferencia = `${(
              indicadoresTransporte[`${actual}Seguimiento`] - indicadoresTransporte[actual]
            )
              .toFixed(1)
              .toLocaleString()} €`;
          }

          actuales.push(o);
          break;
        }
        case 'camionesGrandes':
        case 'camionesPequenos':
        case 'numCompensaciones': {
          switch (actual) {
            case 'camionesGrandes':
              o.parametro = 'Camiones Grandes';
              break;
            case 'camionesPequenos':
              o.parametro = 'Camiones Pequeños';
              break;
            default:
              o.parametro = 'Num. Compensaciones';
              break;
          }

          o.valor = `${indicadoresTransporte[actual]}`;

          if (indicadoresTransporte[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresTransporte[`${actual}Seguimiento`]}`;
            o.diferencia = `${
              indicadoresTransporte[actual] - indicadoresTransporte[`${actual}Seguimiento`]
            }`;
          }

          actuales.push(o);
          break;
        }
        case 'compensacion':
        case 'salarioMinimo':
        case 'salarioMaximo': {
          switch (actual) {
            case 'compensacion':
              o.parametro = 'Compensación';
              break;
            case 'salarioMinimo':
              o.parametro = 'Salario Mín.';
              break;
            default:
              o.parametro = 'Salario Máx.';
              break;
          }

          o.valor = `${indicadoresTransporte[actual].toFixed(1).toLocaleString('ES-es')} €`;

          if (indicadoresTransporte[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresTransporte[`${actual}Seguimiento`]
              .toFixed(1)
              .toLocaleString('ES-es')} €`;
            o.diferencia = `${(
              indicadoresTransporte[`${actual}Seguimiento`] - indicadoresTransporte[actual]
            )
              .toFixed(1)
              .toLocaleString()} €`;
          }

          actuales.push(o);
          break;
        }
        default:
          break;
      }
    });
    return actuales;
  }
}
