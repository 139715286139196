import Planificacion from '../../01.-Domain/Entities/Planificacion';
import PlanificacionOptimizada from '../../01.-Domain/Entities/PlanificacionOptimizada';
import Planta from '../../01.-Domain/Entities/Planta';
import TipoVehiculo from '../../01.-Domain/Entities/TipoVehiculo';
import VehiculoGenerico from '../../01.-Domain/Entities/VehiculoGenerico';
import IPlantaRepository from '../../01.-Domain/RepositoriesInterfaces/IPlantaRepository';
import GenericRepository from '../Base/GenericRepository';

class PlantaRepository extends GenericRepository<Planta> implements IPlantaRepository {
  constructor() {
    super('Planta');
  }

  async GetTipoVehiculos(plantaId: number): Promise<TipoVehiculo[]> {
    const res = await this.get([{ key: plantaId, value: 'tipoVehiculos' }]);
    return res;
  }

  async GetPlanificacionProgramada(plantaId: number): Promise<Planificacion> {
    const res = await this.get([{ key: plantaId, value: 'planificacion' }, { key: 'programada' }]);
    return res;
  }

  async GetPlanificacionOptimizada(plantaId: number): Promise<PlanificacionOptimizada> {
    const res = await this.get([{ key: plantaId, value: 'planificacion' }, { key: 'optimizada' }]);
    return res;
  }

  async GetPlanificacionEnSeguimiento(plantaId: number): Promise<PlanificacionOptimizada> {
    const res = await this.get([{ key: plantaId, value: 'seguimiento' }, { key: 'actual' }]);
    return res;
  }

  async GetVehiculosPlanificacion(plantaId: number): Promise<VehiculoGenerico[]> {
    const res = await this.get([{ key: plantaId, value: 'seguimiento' }, { key: 'vehiculos' }]);
    return res;
  }

  async ActualizarConfiguracion(planta: Planta): Promise<Planta> {
    const res = await this.post([{ key: planta.id, value: 'actualizarConfiguracion' }], planta);
    return res;
  }
}

export default new PlantaRepository();
