import BaseEntity from './Base/BaseEntity';
import IndicadoresGlobales from './Indicadores/IndicadoresGlobales';
import IndicadoresObra from './Indicadores/IndicadoresObra';
import IndicadoresPlanta from './Indicadores/IndicadoresPlanta';
import IndicadoresTransporte from './Indicadores/IndicadoresTransporte';
import Operacion from './Operacion';

export default class PlanificacionOptimizada extends BaseEntity {
  public descripcion: string;

  public fechaInicio: Date;

  public operaciones: Operacion[] = [];

  public indicadoresGlobales: IndicadoresGlobales[];

  public indicadoresPlanta: IndicadoresPlanta[];

  public indicadoresTransporte: IndicadoresTransporte[];

  public indicadoresObras: IndicadoresObra[];

  constructor(obj?: PlanificacionOptimizada) {
    super();

    if (obj) {
      this.id = obj.id;
      this.descripcion = obj.descripcion;
      this.fechaInicio = obj.fechaInicio;
      if (obj.operaciones) {
        obj.operaciones.forEach((op: Operacion) => {
          this.operaciones.push(new Operacion(op));
        });
      } else {
        this.operaciones = [];
      }

      this.indicadoresGlobales = obj.indicadoresGlobales;
      this.indicadoresPlanta = obj.indicadoresPlanta;
      this.indicadoresTransporte = obj.indicadoresTransporte;
      this.indicadoresObras = obj.indicadoresObras;
    } else {
      this.descripcion = '';
      this.fechaInicio = null;
      this.operaciones = [];
    }
  }
}
