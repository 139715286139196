import ConfiguracionPlanta from '../../../01.-Domain/Entities/ConfiguracionPlanta';
import Planta from '../../../01.-Domain/Entities/Planta';
import TipoVehiculo from '../../../01.-Domain/Entities/TipoVehiculo';
import { plantaService } from '../../../02.-Application/Services/PlantaService';
import { DateBoxProps } from '../../Components/CoreComponents/DateBoxComponent';
import { NumberBoxProps } from '../../Components/CoreComponents/NumberBoxComponent';

function GetHorarioObras() {
  const configuracion: ConfiguracionPlanta = plantaService.plantaActual.configuracionPlanta;
  const HorariosObras: DateBoxProps[] = [
    {
      id: 0,
      nombre: 'horaInicioMananaObras',
      defaultValue: configuracion.horaInicioMananaObras,
      onValueChange: (e: any) => (configuracion.horaInicioMananaObras = e.value),
      tipo: 'time',
      texto: 'Inicio Mañana:'
    },
    {
      id: 1,
      nombre: 'horaFinMananaObras',
      defaultValue: configuracion.horaFinMananaObras,
      onValueChange: (e: any) => (configuracion.horaFinMananaObras = e.value),
      tipo: 'time',
      texto: 'Fin Mañana:'
    },
    {
      id: 2,
      nombre: 'horaInicioTardeObras',
      defaultValue: configuracion.horaInicioTardeObras,
      onValueChange: (e: any) => (configuracion.horaInicioTardeObras = e.value),
      tipo: 'time',
      texto: 'Inicio Tarde:'
    },
    {
      id: 3,
      nombre: 'horaFinTardeObras',
      defaultValue: configuracion.horaFinTardeObras,
      onValueChange: (e: any) => (configuracion.horaFinTardeObras = e.value),
      tipo: 'time',
      texto: 'Fin Tarde:'
    }
  ];
  return HorariosObras;
}

function GetConfiguracionGeneral() {
  const configuracion: ConfiguracionPlanta = plantaService.plantaActual.configuracionPlanta;

  const ConfiguracionGeneral: NumberBoxProps[] = [
    {
      id: 0,
      nombre: 'tiempoMinimoRetorno',
      title: 'Tiempo mínimo de retorno',
      rowClass: 'filaParametro',
      colText: 'T. Mín. retorno (Minutos)',
      defaultValue: configuracion.tiempoMinimoRetorno,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (configuracion.tiempoMinimoRetorno = e.value)
    },
    {
      id: 1,
      nombre: 'tiempoRefresco',
      title: 'Tiempo de refresco del mapa y de la tabla',
      rowClass: 'filaParametro',
      colText: 'Tiempo refresco (Minutos)',
      defaultValue: configuracion.tiempoRefrescoPosicionamientoVehiculos,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (configuracion.tiempoRefrescoPosicionamientoVehiculos = e.value)
    },
    {
      id: 2,
      nombre: 'distanciaPlanta',
      title: 'Distancia a planta para finalizar transporte',
      rowClass: 'filaParametro',
      colText: 'Distancia a Planta (Metros)',
      defaultValue: configuracion.distanciaVehiculoCercaDePlanta,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (configuracion.distanciaVehiculoCercaDePlanta = e.value)
    },
    {
      id: 3,
      title: 'Tiempo de carga de los camiones',
      rowClass: 'filaParametro',
      colText: 'Tiempo carga (Minutos)',
      nombre: 'tiempoCarga',
      defaultValue: configuracion.tiempoCargaCamiones,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (configuracion.tiempoCargaCamiones = e.value)
    },
    {
      id: 4,
      nombre: 'tiempoEvaluacion',
      title: 'Tiempo de evaluación de la planificación',
      rowClass: 'filaParametro',
      colText: 'Tiempo Evaluación (Minutos)',
      defaultValue: configuracion.tiempoEvaluacionAutomatica,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (configuracion.tiempoEvaluacionAutomatica = e.value)
    },
    {
      id: 5,
      nombre: 'tiempoSilos',
      title: 'Tiempo máximo de estancia en silos',
      rowClass: 'filaParametro',
      colText: 'T. en silos (Minutos)',
      defaultValue: configuracion.tiempoMaximoEstanciaSilos,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (configuracion.tiempoMaximoEstanciaSilos = e.value)
    }
  ];

  return ConfiguracionGeneral;
}

function GetCostesOperario(planta: Planta) {
  const CostesOperarios: NumberBoxProps[] = [
    {
      id: 0,
      nombre: 'costePeon',
      title: 'Coste Peón',
      rowClass: 'filaParametro',
      colText: 'Coste Peón (€)',
      defaultValue: planta.costesTrabajadores.peon,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (planta.costesTrabajadores.peon = e.value)
    },
    {
      id: 1,
      nombre: 'costePeonHExtra',
      title: 'Coste Peón H. Extra',
      rowClass: 'filaParametro',
      colText: 'Coste Peón H. Extra (€)',
      defaultValue: planta.costesTrabajadores.peonHorasExtras,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (planta.costesTrabajadores.peonHorasExtras = e.value)
    },
    {
      id: 2,
      nombre: 'costeOficial',
      title: 'Coste Oficial',
      rowClass: 'filaParametro',
      colText: 'Coste Oficial (€)',
      defaultValue: planta.costesTrabajadores.oficial,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (planta.costesTrabajadores.oficial = e.value)
    },
    {
      id: 3,
      nombre: 'costeOficialHExtra',
      title: 'Coste Oficial Hora Extra',
      rowClass: 'filaParametro',
      colText: 'Coste Oficial H. Extra (€)',
      defaultValue: planta.costesTrabajadores.oficialHorasExtras,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (planta.costesTrabajadores.oficialHorasExtras = e.value)
    }
  ];

  return CostesOperarios;
}

function GetCostesPlanta(planta: Planta, vehiculos: TipoVehiculo[]) {
  const CostesPlanta: NumberBoxProps[] = [
    {
      id: 0,
      nombre: 'costeArrancada',
      title: 'Coste Arrancada',
      rowClass: 'filaParametro',
      colText: 'Coste Arrancada (€)',
      defaultValue: planta.costeArrancada,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (planta.costeArrancada = e.value)
    },
    {
      id: 1,
      nombre: 'costeNormal',
      title: 'Coste Normal',
      rowClass: 'filaParametro',
      colText: 'Coste Normal (€)',
      defaultValue: planta.costeHoraNormal,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (planta.costeHoraNormal = e.value)
    },
    {
      id: 2,
      nombre: 'costePlantaHExtra',
      title: 'Coste de planta Hora Extra',
      rowClass: 'filaParametro',
      colText: 'Coste de planta H. Extra (€)',
      defaultValue: planta.costeHoraExtra,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) => (planta.costeHoraExtra = e.value)
    },
    {
      id: 3,
      nombre: 'compensacionGrande',
      title: 'Compensación Vehiculo Grande',
      rowClass: 'filaParametro',
      colText: 'Compensación Vehiculo Grande (€)',
      defaultValue: vehiculos.find((x: TipoVehiculo) => x.nombre.toLowerCase().trim() === 'grande')
        .compensacion,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) =>
        (vehiculos.find((x: TipoVehiculo) => x.nombre.toLowerCase().trim() === 'grande').compensacion =
          e.value)
    },
    {
      id: 4,
      nombre: 'compensacionPequeño',
      title: 'Compensación Vehiculo Pequeño',
      rowClass: 'filaParametro',
      colText: 'Compensación Vehiculo Pequeño (€)',
      defaultValue: vehiculos.find((x: TipoVehiculo) => x.nombre.toLowerCase().trim() === 'pequeño')
        .compensacion,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) =>
        (vehiculos.find((x: TipoVehiculo) => x.nombre.toLowerCase().trim() === 'pequeño').compensacion =
          e.value)
    },
    {
      id: 5,
      nombre: 'costeHoraGrande',
      title: 'Coste Hora Vehículo Grande',
      rowClass: 'filaParametro',
      colText: 'Coste Hora Vehículo Grande (€)',
      defaultValue: vehiculos.find((x: TipoVehiculo) => x.nombre.toLowerCase().trim() === 'grande')
        .costeHora,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) =>
        (vehiculos.find((x: TipoVehiculo) => x.nombre.toLowerCase().trim() === 'grande').costeHora =
          e.value)
    },
    {
      id: 6,
      nombre: 'costeHoraPequeño',
      title: 'Coste Hora Vehículo Pequeño',
      rowClass: 'filaParametro',
      colText: 'Coste hora vehículo pequeño (€)',
      defaultValue: vehiculos.find((x: TipoVehiculo) => x.nombre.toLowerCase().trim() === 'pequeño')
        .costeHora,
      min: 1,
      showSpinButton: true,
      onValueChange: (e: any) =>
        (vehiculos.find((x: TipoVehiculo) => x.nombre.toLowerCase().trim() === 'pequeño').costeHora =
          e.value)
    }
  ];

  return CostesPlanta;
}

export { GetHorarioObras, GetConfiguracionGeneral, GetCostesPlanta, GetCostesOperario };
