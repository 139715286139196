import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PlanificacionOptimizada from '../../../01.-Domain/Entities/PlanificacionOptimizada';
import { EventoEnum } from '../../../01.-Domain/Enums/EventoEnum';
import toastService from '../../../02.-Application/Services/Base/ToastService';
import EstadoGridService from '../../../02.-Application/Services/EstadoGridService';
import IndicadoresService from '../../../02.-Application/Services/IndicadoresService';
import { plantaService } from '../../../02.-Application/Services/PlantaService';
import SeguimientoService from '../../../02.-Application/Services/SeguimientoService';
import ConfirmPopUp from '../../Components/ConfirmPopUp/ConfirmPopUp';
import PopupIndicadores from '../../Components/Indicadores/PopupIndicadores';
import LoaderComponent from '../../Components/Loader/LoaderComponent';
import MapComponent from '../../Components/Map/MapComponent';
import AccionesToolbar from './AccionesToolbar';
import GridSeguimiento from './GridSeguimiento';
import PopupFinExtendido from './PopupFinExtendido';
import PopupInicioExtendido from './PopupInicioExtendido';
import PopUpModificarTm from './PopUpModificarTm';
import PopupRechazarCarga from './PopupRechazarCarga';
import PopupSeleccionObra from './PopupSeleccionObra';
import PopupVehiculos from './PopupVehiculos';
import './Seguimiento.scss';

export interface Props {
  permisos: boolean;
}

const SeguimientoView: React.FC<Props> = (props) => {
  const [setLoader, setsetLoader] = useState<boolean>(false);
  const [textoLoader, setTextoLoader] = useState<string>('');
  const [verPopupIndicadores, setVerPopupIndicadores] = useState<boolean>(false);
  const [verPopupVehiculos, setverPopupVehiculos] = useState<boolean>(false);
  const [horaActual, setHoraActual] = useState<string>(dayjs().toString());
  const [planificacion, setPlanificacion] = useState<PlanificacionOptimizada>(null);

  const [verPopupSeleccionObra, setVerPopupSeleccionObra] = useState<boolean>(false);
  const [obraSeleccionada, setObraSeleccionada] = useState<number>(null);
  const [intervaloConfiguracion, setIntervaloConfiguracion] = useState<number>(1);

  // Popup de confirmación de replanificación
  const [popupReplanificar, setpopupReplanificar] = useState<boolean>(false);
  // Popup modificar toneladas
  const [popupModToneladas, setPopupModToneladas] = useState<boolean>(false);

  const [marcadoresObra, setMarcadoresObra] = useState(null);
  const [marcadoresPlanta, setMarcadoresPlanta] = useState(null);
  const [marcadoresVehiculo, setMarcadoresVehiculo] = useState(null);

  // Popup inicio/fin extendido
  const [createPopupExtendido, setcreatePopupExtendido] = useState(true);
  const [extendido, setExtendido] = useState<EventoEnum>(null);

  const [verPopupInicioExtendido, setVerPopupInicioExtendido] = useState(false);
  const [verPopupFinExtendido, setverPopupFinExtendido] = useState(false);
  const [verPopupRechazarCarga, setverPopupRechazarCarga] = useState(false);

  const [sinPlanificacion, setSinPlanificacion] = useState(true);
  let interval = null;
  const GetData = async () => {
    await SeguimientoService.GetPlanificacionEnSeguimiento(obraSeleccionada)
      .then((planificacion) => {
        setSinPlanificacion(!planificacion);
        setPlanificacion(SeguimientoService.planificacionEnSeguimiento);
        setMarcadoresObra(SeguimientoService.marcadoresObras);
        setMarcadoresPlanta(SeguimientoService.marcadoresPlanta);
        setMarcadoresVehiculo(SeguimientoService.marcadoresVehiculos);
        setIntervaloConfiguracion(
          plantaService.plantaActual.configuracionPlanta.tiempoRefrescoPosicionamientoVehiculos
        );
      })
      .catch(() => {
        setSinPlanificacion(true);
        toastService.error('Error cargando la planificación');
      })
      .finally(() => {
        setsetLoader(true);
      });
  };

  useEffect(() => {
    if (!props.permisos) {
      return;
    }

    setTextoLoader('Cargando Planificación en Seguimiento');
    GetData();

    interval = setInterval(() => {
      setHoraActual(dayjs().toString());
      GetData();
    }, intervaloConfiguracion * 60000);

    return () => {
      clearInterval(interval);
    };
  }, [obraSeleccionada]);

  async function Replanificar() {
    setpopupReplanificar(false);
    setPopupModToneladas(false);
    setsetLoader(false);
    setTextoLoader('Replanificación en marcha');

    await SeguimientoService.Replanificar()
      .then(async (res) => {
        if (!res) {
          toastService.error('Ha habido un error en la replanificación');
          return;
        }
        await GetData();
        toastService.success('Replanificación realizada correctamente');
      })
      .finally(() => {
        setsetLoader(true);
      });
  }

  function Actions(accion: string) {
    switch (accion) {
      case 'replanificar': {
        setpopupReplanificar(true);
        break;
      }
      case 'indicadores':
        setVerPopupIndicadores(!verPopupIndicadores);
        break;
      case 'vehiculos': {
        setverPopupVehiculos(!verPopupVehiculos);
        break;
      }
      case 'finalizarPlan':
        FinalizarPlanificacion();
        break;
      case 'iniciarExtendido': {
        setExtendido(EventoEnum.InicioExtendido);
        setVerPopupInicioExtendido(!verPopupInicioExtendido);
        break;
      }
      case 'finalizarExtendido': {
        setExtendido(EventoEnum.FinExtendido);
        setverPopupFinExtendido(!verPopupFinExtendido);
        break;
      }
      case 'rechazarCarga':
        setExtendido(EventoEnum.RechazarCarga);
        setverPopupRechazarCarga(!verPopupRechazarCarga);
        break;
      case 'seleccionObra':
        setVerPopupSeleccionObra(true);
        break;
      case 'guardarEstado':
        guardarEstado();
        break;
      case 'popupModificarToneladas':
        setPopupModToneladas(true);
        break;
    }
  }

  async function guardarEstado() {
    const estado = sessionStorage.getItem('state');
    const res = await EstadoGridService.GuardarEstadoActual(estado);

    if (res) {
      toastService.success('Configuración del grid guardada');
    }
  }

  const cambiarPrioridadTrabajo = async (e: any) => {
    if (e.changes.length > 0) {
      const idTrabajo = e.changes[0].data.trabajoId;
      const nuevaPrioridad = e.changes[0].data.prioridadTrabajo;

      await SeguimientoService.ModificarPrioridadTrabajo(idTrabajo, nuevaPrioridad);

      GetData();
    }
  };

  function CerrarPopupExtendidoSinReplanificar() {
    setVerPopupInicioExtendido(false);
    setverPopupFinExtendido(false);
    setverPopupRechazarCarga(false);
    GetData();
  }

  async function FinalizarPlanificacion() {
    setTextoLoader('Finalizando planificación');
    setsetLoader(false);
    const res = await SeguimientoService.FinalizarPlanificacion().finally(() => {
      setsetLoader(true);
      setSinPlanificacion(true);
    });

    if (!res) {
      toastService.error('Ha habido un error finalizando la planficación');
      return;
    }

    LimpiarDatosPlanificacion();

    toastService.success('La planificación ha finalizado');
  }

  function LimpiarDatosPlanificacion() {
    setMarcadoresObra([]);
    setMarcadoresPlanta([]);
    setMarcadoresVehiculo([]);
    setPlanificacion(null);
  }

  function FiltrarPorObra(obraId: number) {
    setcreatePopupExtendido(false);
    setObraSeleccionada(obraId);
    setVerPopupSeleccionObra(false);
    setcreatePopupExtendido(true);
  }

  function ClosePopupEliminarVehiculos(accion: boolean) {
    if (accion) {
      setcreatePopupExtendido(false);
      setverPopupVehiculos(false);
      setcreatePopupExtendido(true);
    } else {
      setverPopupVehiculos(false);
    }
  }

  if (!setLoader) {
    return <LoaderComponent enabled texto={textoLoader} />;
  }

  return (
    <>
      {popupModToneladas && (
        <PopUpModificarTm
          source={SeguimientoService.obras}
          obrasPlan={IndicadoresService.indicadoresObraSeguimiento}
          show={popupModToneladas}
          texto="Pinchar sobre la celda para editar las toneladas de la obra"
          titulo="Modificar Toneladas"
          confirmar={Replanificar}
          cancelar={() => setPopupModToneladas(false)}
        />
      )}

      <ConfirmPopUp
        show={popupReplanificar}
        texto={'¿Desea replanificar?'}
        titulo="Replanificar"
        confirmar={Replanificar}
        cancelar={() => setpopupReplanificar(false)}
      />

      {verPopupSeleccionObra && (
        <PopupSeleccionObra
          show={verPopupSeleccionObra}
          ObraSeleccionada={(id: number) => FiltrarPorObra(id)}
          CerrarPopup={() => setVerPopupSeleccionObra(false)}
        />
      )}

      {verPopupVehiculos && (
        <PopupVehiculos
          show={verPopupVehiculos}
          cerrarPopup={(accion: boolean) => ClosePopupEliminarVehiculos(accion)}
          vehiculos={SeguimientoService.vehiculos}
        />
      )}

      {createPopupExtendido && (
        <PopupRechazarCarga
          show={verPopupRechazarCarga}
          extendido={extendido}
          cerrarPopup={() => CerrarPopupExtendidoSinReplanificar}
          cerrarSinCambios={() => setverPopupRechazarCarga(false)}
        />
      )}

      {createPopupExtendido && (
        <PopupFinExtendido
          show={verPopupFinExtendido}
          extendido={extendido}
          cerrarPopup={() => CerrarPopupExtendidoSinReplanificar()}
          cerrarSinCambios={() => setverPopupFinExtendido(false)}
        />
      )}

      {createPopupExtendido && (
        <PopupInicioExtendido
          show={verPopupInicioExtendido}
          extendido={extendido}
          cerrarPopup={() => CerrarPopupExtendidoSinReplanificar()}
          cerrarSinCambios={() => setVerPopupInicioExtendido(false)}
        />
      )}

      {verPopupIndicadores && (
        <PopupIndicadores
          indicadoresGlobales={IndicadoresService.indicadoresGlobalesSeguimiento}
          indicadoresPlanta={IndicadoresService.indicadoresPlantaSeguimiento}
          indicadoresTransporte={IndicadoresService.indicadoresTransporteSeguimiento}
          indicadoresObra={IndicadoresService.indicadoresObraSeguimiento}
          graficas={SeguimientoService.graficasPlanSeguimiento}
          show={verPopupIndicadores}
          cerrarPopup={() => setVerPopupIndicadores(!verPopupIndicadores)}
        />
      )}

      {sinPlanificacion && (
        <Row>
          <Col className="mx-3">
            <h2>
              No hay planificaciones en seguimiento. Para crear una, vaya a{' '}
              <Link to="/planificacion">Planificar</Link>
            </h2>
          </Col>
        </Row>
      )}
      <Container fluid>
        <Row>
          <Col>
            <AccionesToolbar
              horaActualizacion={dayjs(horaActual).format('HH:mm')}
              AccionBtn={(accion: string) => Actions(accion)}
              time={horaActual}
            />
          </Col>
        </Row>
        <Row className="p-3">
          <Col md={7}>
            <GridSeguimiento
              operaciones={planificacion?.operaciones}
              altura={160}
              onSaved={cambiarPrioridadTrabajo}
              updateTime={() => setHoraActual(dayjs().toString())}
            />
          </Col>
          <Col md={5} className="map-container">
            <MapComponent
              marcadoresObras={marcadoresObra}
              marcadoresPlanta={marcadoresPlanta}
              marcadoresVehiculos={marcadoresVehiculo}
              altura={160}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SeguimientoView;
