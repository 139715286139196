import { Button, Popup, SelectBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Obra from '../../../01.-Domain/Entities/Obra';
import SeguimientoService, { ObrasSeguimiento } from '../../../02.-Application/Services/SeguimientoService';

type Props = {
  show: boolean;
  CerrarPopup: () => void;
  ObraSeleccionada: (obraId: number) => void;
};

const PopupSeleccionObra: React.FC<Props> = (props) => {
  const [obrasPlan, setObrasPlan] = useState<ObrasSeguimiento[]>([]);
  const [obraSeleccionada, setObraSeleccionada] = useState<number>(null);

  useEffect(() => {
    setObrasPlan(SeguimientoService.obrasSeguimiento);
  }, []);

  function CambiarObra(e: any) {
    setObraSeleccionada(e.value);
  }

  return (
    <Popup
      visible={props.show}
      onHiding={props.CerrarPopup}
      dragEnabled={false}
      showCloseButton={true}
      showTitle={true}
      title="Selección de Obra"
      container=".dx-viewport"
      width="40em"
      height="15em">
      <Row>
        <Col md={11} className="p-3">
          <SelectBox
            onValueChanged={CambiarObra}
            showClearButton
            items={obrasPlan}
            displayExpr="idOrigen"
            valueExpr="id"
            placeholder="Selecciona una obra"
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-around">
        <Col md={8}>
          <Button
            text="Ver Seleccionada"
            type="default"
            stylingMode="contained"
            onClick={(e: any) => props.ObraSeleccionada(obraSeleccionada)}
          />
        </Col>
        <Col md={4}>
          <Button
            text="Ver Todas"
            type="default"
            stylingMode="contained"
            onClick={(e: any) => props.ObraSeleccionada(null)}
          />
        </Col>
      </Row>
    </Popup>
  );
};

export default PopupSeleccionObra;
