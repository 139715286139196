import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Trabajo from '../../../01.-Domain/Entities/Trabajo';
import toastService from '../../../02.-Application/Services/Base/ToastService';
import MezclaService from '../../../02.-Application/Services/MezclaService';
import { obraService } from '../../../02.-Application/Services/ObraService';
import PlanificacionService from '../../../02.-Application/Services/PlanificacionService';
import { plantaService } from '../../../02.-Application/Services/PlantaService';
import TipoVehiculoService from '../../../02.-Application/Services/TipoVehiculoService';
import TrabajoService from '../../../02.-Application/Services/TrabajoService';
import { tramoService } from '../../../02.-Application/Services/TramoService';
import ConfirmPopUp from '../../Components/ConfirmPopUp/ConfirmPopUp';
import LoaderComponent from '../../Components/Loader/LoaderComponent';
import FormularioPlan from './FormularioPlan';
import GridPlanificacion from './GridPlanificacion';
import PopupObras from './PopupObras';

export interface Props {
  permisos: boolean;
}

const PlanificacionView: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [popupObras, setPopupObras] = useState<boolean>(false);
  const [trabajos, setTrabajos] = useState<Trabajo[]>([]);
  const [editandoGrid, setEditandoGrid] = useState<boolean>(false);

  const [setLoader, setsetLoader] = useState<boolean>(null);
  const [textoLoader, setTextoLoader] = useState<string>('');

  const [optimizarHabilitado, setOptimizarHabilitado] = useState<boolean>(true);

  const [popUpConfirmDelete, setPopUpConfirmDelete] = useState<boolean>(false);
  const [trabajoSeleccionado, setTrabajoSeleccionado] = useState<Trabajo>(null);

  const GetData = async (): Promise<void> => {
    let success: Boolean = true;
    setTextoLoader('Cargando Datos');
    let error = '';

    await tramoService.GetTramos().catch(() => {
      success = false;
      error = 'Tramos';
    });

    await obraService.GetAll().catch(() => {
      success = false;
      error = 'Obras';
    });

    await MezclaService.GetAll().catch(() => {
      success = false;
      error = 'Mezclas';
    });

    await PlanificacionService.GetPlanificacionProgramada()
      .then(() => {
        setTrabajos(trabajos.concat(TrabajoService.trabajoPlanificado));
      })
      .catch(() => {
        TrabajoService.trabajoPlanificado = [];
        toastService.error('Algo ha fallado en la carga de datos');
        setsetLoader(true);
      });

    await TipoVehiculoService.GetAll().catch(() => {
      success = false;
      error = 'Vehículos';
    });

    if (!success) {
      toastService.error(`Algo ha fallado en la carga de datos. No se han podido obtener ${error} `);
    }

    setsetLoader(true);
  };

  useEffect(() => {
    if (!props.permisos) {
      return;
    }

    setTextoLoader('');
    GetData();
  }, []);

  useEffect(() => {
    setTrabajos(TrabajoService.trabajoPlanificado);
  }, [TrabajoService.trabajoPlanificado]);

  /**
   * Añade las obras seleccionadas del popup de obras
   * @param e Row
   * @returns Promise<boolean>
   */
  const SetObrasSeleccionadas = async (e: any): Promise<boolean> => {
    setsetLoader(false);
    setTextoLoader('Añadiendo obras a la planificación');

    if (e.length) {
      await TrabajoService.AddTrabajo(e);
      setTrabajos([]);
      setTrabajos(TrabajoService.trabajoPlanificado);

      toastService.success(`Añadidas ${e.length} obras`);
    }

    setPopupObras(false);
    setsetLoader(true);
    return true;
  };

  const EnviarPlan = async (): Promise<void> => {
    if (editandoGrid) {
      toastService.warning('Guarde o deshaga los cambios para optimizar');
      return;
    }

    setsetLoader(false);
    setTextoLoader('Optimizando Planificación');

    const valida = await PlanificacionService.ComprobarCampos();
    if (!valida) {
      setsetLoader(true);
      return;
    }

    const res = await PlanificacionService.Optimizar();

    if (res) {
      setsetLoader(true);
      toastService.success('Planificación optimizada');
      navigate('/PlanificacionOptimizada', { replace: true });
    } else {
      toastService.error('Ha habido un error guardando la planificación');
      setsetLoader(true);
    }
  };

  const EliminarTrabajo = async (e: any) => {
    if (e.columnIndex === 0) {
      setTrabajoSeleccionado(e.data);
      setPopUpConfirmDelete(true);
    }
  };

  const ConfirmarBorrado = async () => {
    setPopUpConfirmDelete(false);

    const trabajoEliminado = await TrabajoService.EliminarTrabajo(trabajoSeleccionado);

    if (trabajoEliminado.length === 1) {
      setTrabajos(trabajos.filter((x) => x.id !== trabajoEliminado[0].id));
      PlanificacionService.planificacionActual.trabajos = TrabajoService.trabajoPlanificado;
      toastService.success('Trabajo eliminado correctamente');
    }
  };

  const CancelarBorrado = () => {
    setPopUpConfirmDelete(false);
  };

  if (!setLoader) {
    return <LoaderComponent enabled texto={textoLoader} />;
  }

  if (!plantaService.plantaActual) {
    return (
      <h2>
        No hay planificaciones en seguimiento. Para crear una, vaya a{' '}
        <Link to="/planificacion">Planificar</Link>
      </h2>
    );
  }

  return (
    <>
      {popupObras && setLoader && (
        <PopupObras
          show={popupObras}
          CerrarPopup={() => setPopupObras(!popupObras)}
          IncluirObras={SetObrasSeleccionadas}
        />
      )}

      {popUpConfirmDelete && setLoader && (
        <ConfirmPopUp
          show={popUpConfirmDelete}
          titulo="Eliminar trabajo"
          texto="Esta acción eliminará el trabajo. ¿Continuar?"
          confirmar={ConfirmarBorrado}
          cancelar={CancelarBorrado}
        />
      )}

      <Container fluid>
        <Row className="px-4">
          <Col md={2} className="mt-3">
            <Button color="default" onClick={() => setPopupObras(true)}>
              Añadir Obras
            </Button>
          </Col>
          <Col md={10}>
            <FormularioPlan />
          </Col>
        </Row>
        <Row className="p-4">
          <Col md="12">
            <GridPlanificacion
              sourceTrabajos={trabajos}
              editable
              tipoVehiculo={TipoVehiculoService.tipoVehiculos}
              mezclas={MezclaService.mezclas}
              habilitarOptimizado={(habilitar) => setOptimizarHabilitado(habilitar)}
              cellClicked={(e: any) => EliminarTrabajo(e)}
              Editando={(value) => setEditandoGrid(value)}
            />
          </Col>
        </Row>
        <div className="row">
          <div className="col d-flex justify-content-end px-4">
            <Button
              color="default"
              onClick={EnviarPlan}
              disabled={!optimizarHabilitado || !trabajos.length}>
              Optimizar
            </Button>
          </div>
        </div>
        {/* <Row>
          <Col md={{ span: 1, offset: 11 }} style={{ position: 'absolute', bottom: '3em', right: '3em' }}>
            <Button
              color="default"
              onClick={EnviarPlan}
              disabled={!optimizarHabilitado || !trabajos.length}>
              Optimizar
            </Button>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default PlanificacionView;
