export const columnas = [
  {
    dataField: 'id',
    caption: 'Id',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'etapa',
    caption: 'Etapa',
    dataType: 'string'
  },
  {
    dataField: 'obraId',
    caption: 'ObraFK',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'obraDescripcion',
    caption: 'Nombre Obra',
    dataType: 'string',
    width: 200,
    visible: false
  },
  {
    dataField: 'obraIdOrigen',
    caption: 'Obra',
    dataType: 'string'
  },
  {
    dataField: 'mezclaIdOrigen',
    caption: 'Mezcla',
    dataType: 'string',
    width: 120
  },
  {
    dataField: 'mezclaDescripcion',
    caption: 'Descripción',
    dataType: 'string',
    width: 200,
    visible: false
  },
  {
    dataField: 'vehiculoGenerico.id',
    caption: 'VehiculoFK',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'costeTramo',
    caption: 'Coste Tramo',
    dataType: 'number',
    alignment: 'left',
    visible: false
  },
  {
    dataField: 'costeMaquinaria',
    caption: 'Coste Maquinaria',
    dataType: 'number',
    alignment: 'left',
    visible: false
  },
  {
    dataField: 'costePersonal',
    caption: 'Coste Personal',
    dataType: 'number',
    alignment: 'left',
    visible: false
  },
  {
    dataField: 'vehiculoGenerico.codigo',
    caption: 'Código',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'vehiculoGenerico.tipoVehiculo.descripcion',
    caption: 'Tipo Vehículo',
    dataType: 'string'
  },
  {
    dataField: 'posicion',
    caption: 'Pos. OP.',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'posicionViaje',
    caption: 'Pos. Viaje',
    dataType: 'number',
    alignment: 'left',
    allowEditing: false
  },
  {
    dataField: 'inicioProgramado',
    caption: 'Ini. Programado',
    dataType: 'datetime',
    format: 'HH:mm'
  },
  {
    dataField: 'finProgramado',
    caption: 'Fin Programado',
    dataType: 'datetime',
    format: 'HH:mm'
  },
  {
    caption: 'Vehículo',
    alignment: 'center',
    columns: [
      {
        dataField: 'inicioParadaVehiculo',
        caption: 'Ini. Para. Veh.',
        dataType: 'datetime',
        format: 'HH:mm'
      },
      {
        dataField: 'finParadaVehiculo',
        caption: 'Fin Para. Veh.',
        dataType: 'datetime',
        format: 'HH:mm'
      }
    ]
  },
  {
    caption: 'Obra',
    alignment: 'center',
    columns: [
      {
        dataField: 'inicioParadaObra',
        caption: 'In. Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'finParadaObra',
        caption: 'Fin Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      }
    ]
  },
  {
    caption: 'Planta',
    alignment: 'center',
    columns: [
      {
        dataField: 'inicioParadaPlanta',
        caption: 'In. Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'finParadaPlanta',
        caption: 'Fin Par.',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'inicioCambio',
        caption: 'Ini. Cbio',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      },
      {
        dataField: 'finCambio',
        caption: 'Fin Cbio',
        dataType: 'datetime',
        format: 'HH:mm',
        allowEditing: false
      }
    ]
  },
  {
    dataField: 'viajeToneladas',
    caption: 'Toneladas (Tn)',
    dataType: 'number',
    alignment: 'left'
  },
  {
    dataField: 'inicioReal',
    caption: 'Ini. Real.',
    dataType: 'datetime',
    format: 'HH:mm',
    visible: false
  },
  {
    dataField: 'finReal',
    caption: 'Fin Real',
    dataType: 'datetime',
    format: 'HH:mm',
    visible: false
  },
  {
    dataField: 'estado',
    caption: 'Estado',
    dataType: 'string',
    width: 80,
    visible: false
  }
];
