export default class Ventana {
  nombreVentana: string;
  permisoEntrada: string;
  permisoModificacion: string;
  permisoBaja: string;
  permisoAlta: string;
  permisoEspecial: string;

  constructor(obj?: any) {
    if (obj) {
      this.nombreVentana = obj.nombreVentana;
      this.permisoEntrada = obj.permisoEntrada;
      this.permisoModificacion = obj.permisoModificacion;
      this.permisoBaja = obj.permisoBaja;
      this.permisoAlta = obj.permisoAlta;
      this.permisoEspecial = obj.permisoEspecial;
    } else {
      this.nombreVentana = null;
      this.permisoEntrada = null;
      this.permisoModificacion = null;
      this.permisoBaja = null;
      this.permisoAlta = null;
      this.permisoEspecial = null;
    }
  }
}
