import dayjs from 'dayjs';

export default class ElementoGrafica {
  public x: Date;
  public y: number;

  constructor(obj?: any) {
    if (obj) {
      this.x = dayjs(obj.x).toDate();
      this.y = obj.y.toFixed(2);
    } else {
      this.x = null;
      this.y = null;
    }
  }
}
