import BaseEntity from './Base/BaseEntity';
import ConfiguracionPlanta from './ConfiguracionPlanta';
import CosteTrabajadores from './CosteTrabajadores';
import FranjaHoraria from './FranjaHoraria';
import Location from './Location';

export default class Planta extends BaseEntity {
  nombre: string;
  location: Location;
  instalacion: string;
  configuracionPlanta: ConfiguracionPlanta;
  horario: FranjaHoraria[] = [];
  costesTrabajadores: CosteTrabajadores;
  costeHoraNormal: number;
  costeHoraExtra: number;
  costeArrancada: number;

  constructor(obj?: any) {
    super();

    if (obj) {
      this.id = obj.id;
      this.nombre = obj.nombre;
      this.location = new Location(obj.location);
      this.instalacion = obj.instalacion;
      this.configuracionPlanta = new ConfiguracionPlanta(obj.configuracionPlanta);
      if (obj.horario) {
        obj.horario.forEach((element: FranjaHoraria) => {
          this.horario.push(new FranjaHoraria(element));
        });
      }
      this.costesTrabajadores = new CosteTrabajadores(obj.costesTrabajadores);
      this.costeHoraNormal = obj.costeHoraNormal;
      this.costeHoraExtra = obj.costeHoraExtra;
      this.costeArrancada = obj.costeArrancada;
    } else {
      this.nombre = '';
      this.location = null;
      this.configuracionPlanta = null;
      this.instalacion = '';
      this.horario = [];
      this.costesTrabajadores = null;
      this.costeHoraNormal = null;
      this.costeHoraExtra = null;
      this.costeArrancada = null;
    }
  }
}
