import Way from '../../01.-Domain/Entities/Way';
import GISRepository from '../../03.-Infraestructure/Repositories/GISRepository';
import IGISService from '../ServicesInterfaces/IGISService';

class GISService implements IGISService {
  async GetETA(locations: Way): Promise<number> {
    return await GISRepository.post([{ value: 'eta' }], locations);
  }
}

export default new GISService();
