import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Marcador from '../../../01.-Domain/Entities/Marcador';
import './Map.scss';
import { attribution, GetIcon, GetPlantaIcon, GetVehiculoIcon, url } from './MapUtils';

interface Props {
  altura: number;
  center?: Marcador;
  marcadoresObras: Marcador[];
  marcadoresVehiculos: Marcador[];
  marcadoresPlanta: Marcador[];
  locationClick?: (e: any) => void;
}

interface MarcadoresProps {
  layerObras: L.FeatureGroup;
  layerPlanta: L.FeatureGroup;
  layerVehiculos: L.FeatureGroup;
  obras: Marcador[];
  vehiculos: Marcador[];
  planta: Marcador[];
}

function SituarMarcadores(props: MarcadoresProps) {
  const map = useLeafletContext().map;

  const featureGroupBounds: L.FeatureGroup = new L.FeatureGroup();
  map.removeLayer(props.layerObras);
  map.removeLayer(props.layerVehiculos);
  map.removeLayer(props.layerPlanta);

  // Obra
  props.layerObras.clearLayers();
  let indexObraVehiculo = 0;

  props.obras.forEach((marcador: Marcador) => {
    const marker: L.Marker = new L.Marker(marcador.position, {
      icon: GetIcon(marcador.clase, marcador.clase),
      title: `Obra: - ${marcador.descripcion}`
    });

    var popup = L.popup().setContent(`Obra: - ${marcador.descripcion}`);
    marker.bindPopup(popup).openPopup();

    indexObraVehiculo = indexObraVehiculo === 4 ? 0 : indexObraVehiculo++;
    props.layerObras.addLayer(marker);
    featureGroupBounds.addLayer(marker);
  });

  // Planta
  props.layerPlanta.clearLayers();
  if (props.planta.length > 0) {
    const marker: L.Marker = new L.Marker([props.planta[0].position[0], props.planta[0].position[1]], {
      icon: GetPlantaIcon(require(`../../../assets/images/factory.png`)),
      title: `${props.planta[0].descripcion}`
    });

    var popup = L.popup().setContent(`${props.planta[0].descripcion}`);
    marker.bindPopup(popup).openPopup();

    props.layerPlanta.addLayer(marker);
    featureGroupBounds.addLayer(marker);
  }

  // Vehículos
  props.layerVehiculos.clearLayers();

  props.vehiculos.forEach((vehiculo: Marcador) => {
    if (vehiculo.position) {
      const marker: L.Marker = new L.Marker([vehiculo.position[0], vehiculo.position[1]], {
        icon: GetVehiculoIcon(vehiculo.clase, vehiculo.clase),
        title: vehiculo.descripcion
      });

      var popup = L.popup().setContent(vehiculo.descripcion);
      marker.bindPopup(popup).openPopup();

      props.layerVehiculos.addLayer(marker);
      featureGroupBounds.addLayer(marker);
    }
  });

  props.layerObras.addTo(map);
  props.layerPlanta.addTo(map);
  props.layerVehiculos.addTo(map);

  const bounds: L.LatLngBounds = featureGroupBounds.getBounds();
  if (bounds.isValid()) {
    map.fitBounds(bounds);
  }

  return null;
}

const MapComponent: React.FC<Props> = (props) => {
  const [obraLocation, setobraLocation] = useState<Marcador[]>(null);
  const [plantaLocation, setPlantaLocation] = useState<Marcador[]>(null);
  const [vehiculoLocation, setVehiculoLocation] = useState<Marcador[]>(null);
  const [layerVehiculos, setlayerVehiculos] = useState<L.FeatureGroup>(null);
  const [layerPlantas, setlayerPlantas] = useState<L.FeatureGroup>(null);
  const [layerObras, setlayerObras] = useState<L.FeatureGroup>(null);

  useEffect(() => {
    setlayerVehiculos(layerVehiculos ? layerVehiculos : new L.FeatureGroup());
    setlayerPlantas(layerPlantas ? layerPlantas : new L.FeatureGroup());
    setlayerObras(layerObras ? layerObras : new L.FeatureGroup());
    setobraLocation(props.marcadoresObras);
    setPlantaLocation(props.marcadoresPlanta);
    setVehiculoLocation(props.marcadoresVehiculos);
  }, [props.marcadoresVehiculos]);

  return (
    obraLocation && (
      <MapContainer style={{ height: window.innerHeight - (props.altura ? props.altura : 200) }}>
        <TileLayer attribution={attribution} url={url} />

        {/* Situar marcadores de obras, planta y vehículos */}
        <SituarMarcadores
          layerObras={layerObras}
          layerVehiculos={layerVehiculos}
          layerPlanta={layerPlantas}
          obras={obraLocation}
          vehiculos={vehiculoLocation}
          planta={plantaLocation}
        />
      </MapContainer>
    )
  );
};

export default MapComponent;
