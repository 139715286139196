import Location from '../../01.-Domain/Entities/Location';
import Obra from '../../01.-Domain/Entities/Obra';
import IObraRepository from '../../01.-Domain/RepositoriesInterfaces/IObraRepository';
import GenericRepository from '../Base/GenericRepository';

class ObraRepository extends GenericRepository<Obra> implements IObraRepository {
  constructor() {
    super('Obra');
  }

  async GetAll(): Promise<Obra[]> {
    const res = await this.get([{ value: 'activas' }]);
    return res;
  }

  async GuardarUbicacionObra(idObra: number, location: Location): Promise<Obra> {
    const res = await this.post([{ key: idObra, value: 'posicion' }], location);
    return res;
  }

  async ModificarCantidadObra(idObra: number, cantidad: number): Promise<any> {
    const res = await this.post([{ key: idObra, value: 'cambiarCantidad' }], null, [
      { key: 'cantidad', value: cantidad }
    ]);

    return res;
  }
}

export default new ObraRepository();
