import { DataGrid } from 'devextreme-react';
import {
  ColumnChooser,
  Editing,
  Grouping,
  GroupPanel,
  Scrolling,
  SearchPanel,
  Selection,
  Sorting,
  StateStoring,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import React, { useCallback, useEffect, useState } from 'react';
import EstadoGrid from '../../../01.-Domain/Entities/EstadoGrid';
import Operacion from '../../../01.-Domain/Entities/Operacion';
import AutenticationService from '../../../02.-Application/Services/AutenticationService';
import EstadoGridService from '../../../02.-Application/Services/EstadoGridService';
import { columnas } from './ColumnasSeguimiento';

export type GridProps = {
  operaciones: Operacion[];
  altura?: number;
  onSaved?: (event: any) => void;
  updateTime: () => void;
};

const GridSeguimiento: React.FC<GridProps> = (props) => {
  const [data, setData] = useState<Operacion[]>(null);
  const [alturaGrid, setAlturaGrid] = useState<number>(
    window.innerHeight - (props.altura ? props.altura : 200)
  );

  useEffect(() => {
    setData(props.operaciones);
  }, [props.operaciones]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setAlturaGrid(window.innerHeight - (props.altura ? props.altura : 200));
    });
  }, [window.innerHeight]);

  const headerFilterConfig = { visible: true, allowSearch: true };
  const exportConfig = {
    enabled: true,
    fileName: 'Planificación'
  };
  const filterPanelConfig = { visible: true };

  const Saved = (e: any) => {
    if (props.onSaved) {
      props.onSaved(e);
    }
  };

  function GetColumnas(): any[] {
    return columnas;
  }

  const loadState = useCallback(async () => {
    const res: EstadoGrid = await EstadoGridService.ObtenerEstadoByRol(
      AutenticationService.permisos.data.grupoAutorizacion
    );
    if (res) {
      return JSON.parse(res.estado);
    }
  }, []);

  const saveState = useCallback(async (state) => {
    if (state) {
      sessionStorage.setItem('state', JSON.stringify(state));
    }
  }, []);

  function CellPrepared(e: CellPreparedEvent<any, unknown>) {
    if (e.rowType === 'data' && e.data.inicioProgramado === null) {
      e.cellElement.style.background = '#FCE4EC';
    }
  }

  return (
    <DataGrid
      height={alturaGrid}
      dataSource={data}
      columns={GetColumnas()}
      columnAutoWidth
      allowColumnResizing
      columnResizingMode="widget"
      allowColumnReordering
      rowAlternationEnabled
      showBorders
      headerFilter={headerFilterConfig}
      filterPanel={filterPanelConfig}
      export={exportConfig}
      onCellPrepared={CellPrepared}
      onSaved={Saved}>
      <Scrolling mode="virtual" />
      <Selection mode="single" />
      <Editing mode="cell" allowUpdating={false} allowAdding={false} allowDeleting={false} useIcons />
      <Sorting mode="multiple" />
      <GroupPanel visible />
      <Grouping expandMode="rowClick" autoExpandAll="true" />
      <ColumnChooser mode="select" enabled height={500} width={500} allowSearch="true" />

      <StateStoring
        enabled={true}
        type="custom"
        storageKey="storage"
        customLoad={loadState}
        customSave={saveState}
      />

      <SearchPanel visible />

      <Summary>
        <TotalItem column="etapa" summaryType="count" />
      </Summary>
    </DataGrid>
  );
};

export default GridSeguimiento;
