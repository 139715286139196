import Ventana from './Ventana';

export default class Data {
  grupoAutorizacion: string;
  modulo: string;
  nombreProgramas: string[];
  ventanas: Ventana[] = [];

  constructor(obj?: any) {
    if (obj) {
      this.grupoAutorizacion = obj.grupoAutorizacion;
      this.modulo = obj.modulo;
      this.nombreProgramas = obj.nombreProgramas;
      obj.ventanas.forEach((ventana) => {
        this.ventanas.push(new Ventana(ventana));
      });
    } else {
      this.grupoAutorizacion = null;
      this.modulo = null;
      this.nombreProgramas = null;
      this.ventanas = [];
    }
  }
}
