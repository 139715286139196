import dayjs from 'dayjs';
import BaseEntity from './Base/BaseEntity';
import TipoVehiculo from './TipoVehiculo';

export default class VehiculoGenerico extends BaseEntity {
  conductor: string;

  matricula: string;

  tipoVehiculo: TipoVehiculo;

  hora: Date;

  latitud: number;

  longitud: number;

  codigo: number;

  constructor(obj?: VehiculoGenerico) {
    super();

    if (obj) {
      this.id = obj.id;
      this.conductor = obj.conductor;
      this.matricula = obj.matricula;
      this.tipoVehiculo = obj.tipoVehiculo;
      this.hora = dayjs(obj.hora).toDate();
      this.latitud = obj.latitud;
      this.longitud = obj.longitud;
      this.codigo = obj.codigo;
    } else {
      this.conductor = '';
      this.matricula = '';
      this.tipoVehiculo = null;
      this.hora = null;
      this.latitud = null;
      this.longitud = null;
      this.codigo = null;
    }
  }
}
