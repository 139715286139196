import Graficas from '../../01.-Domain/Entities/Graficas/Graficas';
import Planificacion from '../../01.-Domain/Entities/Planificacion';
import PlanificacionOptimizada from '../../01.-Domain/Entities/PlanificacionOptimizada';
import IPlanificacionRepository from '../../01.-Domain/RepositoriesInterfaces/IPlanificacionRepository';
import GenericRepository from '../Base/GenericRepository';

class PlanificacionRepository extends GenericRepository<Planificacion> implements IPlanificacionRepository {
  constructor() {
    super('Planificacion');
  }

  async GetGraficas(id: number): Promise<Graficas> {
    return await this.get([{ key: id, value: 'datosgrafica' }]);
  }

  async Optimizar(planificacion: Planificacion): Promise<PlanificacionOptimizada> {
    return await this.post([{ value: 'optimizar' }], planificacion);
  }

  async ConfirmarPlanificacion(id: number, idPlanta: number): Promise<PlanificacionOptimizada> {
    return this.post(
      [{ value: 'confirmar' }],
      [],
      [
        { key: 'id', value: id },
        { key: 'idPlanta', value: idPlanta }
      ]
    );
  }
}

export default new PlanificacionRepository();
