import EventoVehiculo from '../../01.-Domain/Entities/EventoVehiculo';
import TipoVehiculo from '../../01.-Domain/Entities/TipoVehiculo';
import VehiculoGenerico from '../../01.-Domain/Entities/VehiculoGenerico';
import IVehiculoRepository from '../../01.-Domain/RepositoriesInterfaces/IVehiculoRepository';
import GenericRepository from '../Base/GenericRepository';

class VehiculoRepository extends GenericRepository<VehiculoGenerico> implements IVehiculoRepository {
  constructor() {
    super('Vehiculo');
  }

  async InsertarEvento(matricula: string, evento: EventoVehiculo) {
    const res = await this.post([{ key: matricula, value: 'eventos' }], evento);
    return res;
  }

  async ActualizarVehiculos(vehiculos: TipoVehiculo[]) {
    const res = await this.post([{ key: 'actualizarVehiculos' }], vehiculos);
    return res;
  }
}

export default new VehiculoRepository();
