import dayjs from 'dayjs';
import FranjaHoraria from '../../../01.-Domain/Entities/FranjaHoraria';
import ToastService from '../../../02.-Application/Services/Base/ToastService';

const ValidacionFechas = (dateStart: Date, dateEnd: Date, franjas: FranjaHoraria[]): boolean => {
  let error = false;
  const nuevoInicio = dayjs(dateStart);
  const nuevoFinal = dayjs(dateEnd);

  if (!dateStart || !dateEnd) {
    ToastService.error('La franja tiene que tener hora de inicio y de final');
    error = true;
  }

  if (nuevoFinal.isBefore(nuevoInicio)) {
    ToastService.error('La hora de fin no puede ser anterior a la de inicio');
    error = true;
  }

  if (nuevoInicio.isAfter(nuevoFinal)) {
    ToastService.error('La hora de comienzo no puede ser posterior a la de final');
    error = true;
  }

  if (nuevoInicio.isSame(nuevoFinal)) {
    ToastService.error('La hora de inicio no puede ser la misma que la de fín');
    error = true;
  }

  franjas.forEach((element: FranjaHoraria) => {
    const inicioFranja = dayjs(element.inicio);
    const finFranja = dayjs(element.fin);

    if (
      nuevoInicio.isBetween(inicioFranja, finFranja, null, '()') ||
      nuevoFinal.isBetween(inicioFranja, finFranja, null, '()') ||
      inicioFranja.isBetween(nuevoInicio, nuevoFinal, null, '()') ||
      finFranja.isBetween(nuevoInicio, nuevoFinal, null, '()')
    ) {
      error = true;
      ToastService.error('La fecha de inicio se solapa con otra franja');
    }

    if (dateStart === element.inicio && dateEnd === element.fin) {
      error = true;
      ToastService.error('Ya existe una franja con el mismo horario');
    }
  });

  return error;
};

export { ValidacionFechas };
