import DataGrid, { Column, Editing, RequiredRule, RangeRule } from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import IndicadoresObra from '../../../01.-Domain/Entities/Indicadores/IndicadoresObra';
import ToastService from '../../../02.-Application/Services/Base/ToastService';
import { obraService } from '../../../02.-Application/Services/ObraService';
import ConfirmPopUp from '../../Components/ConfirmPopUp/ConfirmPopUp';

export interface Props {
  source: any[];
  obrasPlan: IndicadoresObra[];
  show: boolean;
  texto: string;
  titulo: string;
  confirmar: () => void;
  cancelar: () => void;
}

const PopUpModificarTm: React.FC<Props> = (props) => {
  const [verPopupConfirmar, setVerPopupConfirmar] = useState<boolean>(false);
  const [obrasEditadas, setObrasEditadas] = useState<any[]>([]);
  const [obraTm, setObraTm] = useState([]);

  useEffect(() => {
    return () => {
      setObrasEditadas([]);
    };
  }, [props.show]);

  useEffect(() => {
    const obraTmReales: any[] = [];
    if (!props.source) {
      return;
    }

    props.obrasPlan.forEach((obra: IndicadoresObra) => {
      if (obra.obra.idOrigen !== '') {
        obraTmReales.push({
          id: obra.obra.id,
          obra: obra.obra.idOrigen,
          tm: obraService.ToneladasTotalesObra(obra.obra.id)
        });
      }
    });

    setObraTm(obraTmReales);
  }, [props.source]);

  function AbrirConfirmacion() {
    if (obraTm.some((x) => x.tm > 0)) {
      setVerPopupConfirmar(true);
    } else {
      ToastService.error('No se pueden dejar todas las obras sin carga');
    }
  }

  async function GuardarCambios() {
    await obraService
      .ModificarCantidadObras(obrasEditadas)
      .then(() => {
        props.confirmar();
      })
      .catch(() => {
        ToastService.error('No se han podido modificar las cantidades de la obra');
      });

    setVerPopupConfirmar(false);
  }

  function HandleRowUpdating(e: any) {
    setObrasEditadas(obrasEditadas.concat(e.key));
  }

  return (
    <>
      <ConfirmPopUp
        show={verPopupConfirmar}
        texto={'¿Desea guardar los cambios?'}
        titulo="Guardar Cambios"
        confirmar={GuardarCambios}
        cancelar={() => setVerPopupConfirmar(false)}
      />

      <Modal style={{ top: '10em' }} show={props.show} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>{props.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataGrid
            dataSource={obraTm}
            columnAutoWidth
            allowColumnResizing
            columnResizingMode="widget"
            allowColumnReordering
            rowAlternationEnabled
            showBorders
            onRowUpdating={HandleRowUpdating}>
            <Editing
              refresh-mode="repaint"
              mode="row"
              allowUpdating={true}
              allowAdding={false}
              allowDeleting={false}
              useIcons
            />

            <Column caption="Obra" allowEditing={false} dataField="obra"></Column>
            <Column caption="Toneladas" dataField="tm" alignment="left" width="200">
              <RequiredRule />
              <RangeRule min="0" message="La cantidad no puede ser negativa" />
            </Column>
          </DataGrid>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={obrasEditadas.length === 0} onClick={AbrirConfirmacion}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={props.cancelar}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PopUpModificarTm;
