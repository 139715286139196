import IGenericRepository from '../../01.-Domain/RepositoriesInterfaces/Base/IGenericRepository';
import { HttpService } from '../../02.-Application/Services/Base/HttpService';
import IHttpService from '../../02.-Application/ServicesInterfaces/Base/IHttpService';
import ConfigProvider from '../../Config/ConfigProvider';

export default class GenericRepository<T> implements IGenericRepository<T> {
  private readonly entity: string;

  private _url: string;

  public get url(): string {
    return this._url;
  }

  public set url(value: string) {
    this._url = value;
  }

  private _httpService: IHttpService = new HttpService();

  public get httpService(): IHttpService {
    return this._httpService;
  }

  public set httpService(v: IHttpService) {
    this._httpService = v;
  }

  constructor(entity: string) {
    this.entity = entity;
    this._url = `/${this.entity}/`;
    this.httpService.root = ConfigProvider.Base_URL;
  }

  // PUBLIC METHODS
  public async getAll(): Promise<any> {
    return this.httpService.get(this.entity);
  }

  public async getById(id: number): Promise<any> {
    return this.httpService.get(`${this.entity}/${id}`);
  }

  public async insert(obj: T): Promise<any> {
    return this.httpService.post(this.entity, obj);
  }

  public async update(obj: any): Promise<any> {
    return this.httpService.put(this.entity, obj);
  }

  public async deleteById(id: number): Promise<any> {
    return this.httpService.delete(`${this.entity}/${id}`);
  }

  /**
   * Obtiene un listado de objetos
   * de la clase entity
   * @param methods nombre de las funciones del controlador Ej:
   * [{key: NombreFuncion, value: Valor}]
   * @param queries array de parametros que se pasan por URL Ej: {Param1:
   * Parametro1, Param2: Parametro2}
   */
  public async get(methods: any = [], queries: any = []): Promise<any> {
    let { url } = this;

    methods.forEach((param: any) => {
      url += param && param.key !== undefined ? `${param.key}/` : '';
      url += param && param.value !== undefined ? `${param.value}/` : '';
    });

    url = queries ? url.substring(0, url.length - 1) : url;

    queries.forEach((query: any) => {
      if (query && query.key) {
        url += url.charAt(url.length - 1) !== '&' ? '?' : '';
        url += `${query.key}=${query.value}&`;
      }
    });

    url =
      url.charAt(url.length - 1) === '/' || url.charAt(url.length - 1) === '&'
        ? url.substring(0, url.length - 1)
        : url;

    return this.httpService.get(`${this.httpService.root}${url}`);
  }

  /**
   * Envia una petición POST a la API con datos en el body
   * @param methods de la función del controlador. Ej: [{key: NombreFuncion, value: Valor}]
   * @param bodyParams enviados por Body Ej: {Param1: Parametro1, Param2: Parametro2}
   */
  public async post(methods: any = [], bodyParams: any = [], queries: any = []): Promise<any> {
    let { url } = this;

    methods.forEach((param: any) => {
      url += param && param.key ? `${param.key}/` : '';
      url += param && param.value ? `${param.value}/` : '';
    });

    url = queries ? url.substring(0, url.length - 1) : url;

    queries.forEach((query: any) => {
      if (query && query.key) {
        url += url.charAt(url.length - 1) !== '&' ? '?' : '';
        url += `${query.key}=${query.value}&`;
      }
    });

    url =
      url.charAt(url.length - 1) === '/' || url.charAt(url.length - 1) === '&'
        ? url.substring(0, url.length - 1)
        : url;

    return this.httpService.post(`${this.httpService.root}${url}`, bodyParams);
  }
}
