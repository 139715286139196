import notify from 'devextreme/ui/notify';

class ToastService {
  private _time = 5000;

  get time() {
    return this._time;
  }

  set time(time: number) {
    this._time = time;
  }

  public error(text: string, closeOnClick = true) {
    notify({ message: text, closeOnClick, shadding: true }, 'error', this._time);
  }

  public warning(text: string, closeOnClick = true) {
    notify({ message: text, closeOnClick, shadding: true }, 'warning', this._time);
  }

  public info(text: string, closeOnClick = true) {
    notify({ message: text, closeOnClick, shadding: true }, 'info', this._time);
  }

  public success(text: string, closeOnClick = true) {
    notify({ message: text, closeOnClick, shadding: true }, 'success', this._time);
  }
}

export default new ToastService();
