import BaseEntity from './Base/BaseEntity';
import Location from './Location';
import Mezcla from './Mezcla';

export default class Obra extends BaseEntity {
  idOrigen: string;

  descripcion: string;

  plantaId: number;

  toneladas: number;

  mezcla: Mezcla;

  location: Location;

  constructor(obj?: Obra) {
    super();

    if (obj) {
      this.id = obj.id;
      this.idOrigen = obj.idOrigen;
      this.descripcion = obj.descripcion.replace(/\s\s+/g, ' ');
      this.plantaId = obj.plantaId;
      this.toneladas = obj.toneladas;
      this.mezcla = obj.mezcla;
      this.location = new Location(obj.location);
    } else {
      this.idOrigen = '';
      this.descripcion = '';
      this.plantaId = null;
      this.toneladas = 0;
      this.mezcla = null;
      this.location = null;
    }
  }
}
