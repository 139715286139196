import EstadoGrid from '../../01.-Domain/Entities/EstadoGrid';
import EstadoGridRepository from '../../03.-Infraestructure/Repositories/EstadoGridRepository';
import IEstadoGridService from '../ServicesInterfaces/IEstadoGridService';
import AutenticationService from './AutenticationService';

class EstadoGridService implements IEstadoGridService {
  async ObtenerEstadoByRol(rol: string): Promise<EstadoGrid> {
    const estadoDevuelto = await EstadoGridRepository.ObtenerEstadoByRol(rol);

    return estadoDevuelto;
  }

  async GuardarEstadoActual(estadoActual: string): Promise<EstadoGrid> {
    var estado: EstadoGrid = new EstadoGrid();
    estado.rol = AutenticationService.permisos.data.grupoAutorizacion;
    estado.estado = estadoActual;

    const res = await EstadoGridRepository.GuardarEstado(estado);
    if (res) {
      return estado;
    }
  }
}

export default new EstadoGridService();
