import { ConvertTimeSpanToDate } from '../../02.-Application/Services/DateTimeService';
import { plantaService } from '../../02.-Application/Services/PlantaService';
import BaseEntity from './Base/BaseEntity';
import Planta from './Planta';

export default class ConfiguracionPlanta extends BaseEntity {
  horaInicioMananaObras: Date | string;
  horaFinMananaObras: Date | string;
  horaInicioTardeObras: Date | string;
  horaFinTardeObras: Date | string;
  tiempoRefrescoPosicionamientoVehiculos: number;
  tiempoMaximoEstanciaSilos: number;
  tiempoEvaluacionAutomatica: number;
  tiempoCargaCamiones: number;
  planta: Planta;
  plantaId: number;
  distanciaVehiculoCercaDePlanta: number;
  tiempoMinimoRetorno: number;

  constructor(obj?: ConfiguracionPlanta) {
    super();

    if (obj) {
      this.id = obj.id;
      this.horaInicioMananaObras = ConvertTimeSpanToDate(obj.horaInicioMananaObras);
      this.horaFinMananaObras = ConvertTimeSpanToDate(obj.horaFinMananaObras);
      this.horaInicioTardeObras = ConvertTimeSpanToDate(obj.horaInicioTardeObras);
      this.horaFinTardeObras = ConvertTimeSpanToDate(obj.horaFinTardeObras);
      this.tiempoRefrescoPosicionamientoVehiculos = obj.tiempoRefrescoPosicionamientoVehiculos;
      this.tiempoMaximoEstanciaSilos = obj.tiempoMaximoEstanciaSilos;
      this.tiempoEvaluacionAutomatica = obj.tiempoEvaluacionAutomatica;
      this.tiempoCargaCamiones = obj.tiempoCargaCamiones;
      this.planta = plantaService.plantas.find((x: Planta) => x.id === obj.plantaId);
      this.plantaId = obj.plantaId;
      this.distanciaVehiculoCercaDePlanta = obj.distanciaVehiculoCercaDePlanta;
      this.tiempoMinimoRetorno = obj.tiempoMinimoRetorno;
    } else {
      this.horaInicioMananaObras = '08:00';
      this.horaFinMananaObras = '14:00';
      this.horaInicioTardeObras = '15:00';
      this.horaFinTardeObras = '18:00';
      this.tiempoRefrescoPosicionamientoVehiculos = 5;
      this.tiempoMaximoEstanciaSilos = 10;
      this.tiempoEvaluacionAutomatica = 5;
      this.tiempoCargaCamiones = 10;
      this.planta = null;
      this.plantaId = null;
      this.distanciaVehiculoCercaDePlanta = 1000;
      this.tiempoMinimoRetorno = 30;
    }
  }
}
