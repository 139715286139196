import dayjs from 'dayjs';
import BaseEntity from './Base/BaseEntity';
import Trabajo from './Trabajo';

export default class Planificacion extends BaseEntity {
  descripcion: string;

  estado: number;

  fechaInicio: Date;

  plantaId: number;

  numeroVehiculosPequenos: number;

  numeroVehiculosGrandes: number;

  trabajos: Trabajo[] = [];

  constructor(obj?: Planificacion) {
    super();

    if (obj) {
      this.id = obj.id;
      this.descripcion = obj.descripcion;
      this.estado = obj.estado;
      this.fechaInicio = obj.fechaInicio;
      this.plantaId = obj.plantaId;
      this.numeroVehiculosPequenos = obj.numeroVehiculosPequenos;
      this.numeroVehiculosGrandes = obj.numeroVehiculosGrandes;

      if (obj.trabajos) {
        obj.trabajos.forEach((element: Trabajo) => {
          this.trabajos.push(new Trabajo(element));
        });
      }
    } else {
      this.descripcion = '';
      this.estado = 0;
      this.fechaInicio = dayjs().add(1, 'day').toDate();
      this.plantaId = null;
      this.numeroVehiculosPequenos = 0;
      this.numeroVehiculosGrandes = 0;
      this.trabajos = [];
    }
  }
}
