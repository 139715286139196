import { EstadoPlanificacionEnum } from '../../Enums/EstadoPlanificacionEnum';
import BaseEntity from '../Base/BaseEntity';
import { GridObject } from './IndicadoresGrid';

export default class IndicadoresPlanta extends BaseEntity {
  coste: number;
  costeSeguimiento: number;
  costeDiferencia: number;
  arrancadas: number;
  arrancadasSeguimiento: number;
  arrancadasDiferencia: number;
  toneladasHora: number;
  toneladasHoraSeguimiento: number;
  toneladasHoraDiferencia: number;
  productivo: number;
  productivoSeguimiento: number;
  productivoDiferencia: number;
  cambio: number;
  cambioSeguimiento: number;
  cambioDiferencia: number;
  parada: number;
  paradaSeguimiento: number;
  paradaDiferencia: number;
  estado: string;

  constructor(obj?: IndicadoresPlanta[]) {
    super();

    if (obj.length) {
      obj.forEach((indicadores: IndicadoresPlanta) => {
        if (EstadoPlanificacionEnum[indicadores.estado] === EstadoPlanificacionEnum[0]) {
          this.id = indicadores.id;
          this.coste = indicadores.coste;
          this.arrancadas = indicadores.arrancadas;
          this.toneladasHora = indicadores.toneladasHora;
          this.productivo = indicadores.productivo;
          this.cambio = indicadores.cambio;
          this.parada = indicadores.parada;
          this.estado = EstadoPlanificacionEnum[indicadores.estado];
        }

        if (EstadoPlanificacionEnum[indicadores.estado] === EstadoPlanificacionEnum[1]) {
          this.costeSeguimiento = indicadores.coste;
          this.arrancadasSeguimiento = indicadores.arrancadas;
          this.toneladasHoraSeguimiento = indicadores.toneladasHora;
          this.productivoSeguimiento = indicadores.productivo;
          this.cambioSeguimiento = indicadores.cambio;
          this.paradaSeguimiento = indicadores.parada;

          this.costeDiferencia = this.coste - this.costeSeguimiento;
          this.arrancadasDiferencia = this.arrancadas - this.arrancadasSeguimiento;
          this.toneladasHoraDiferencia = this.toneladasHora - this.toneladasHoraSeguimiento;
          this.productivoDiferencia = this.productivo - this.productivoSeguimiento;
          this.cambioDiferencia = this.cambio - this.cambioSeguimiento;
          this.paradaDiferencia = this.parada - this.paradaSeguimiento;
        }
      });
    } else {
      this.coste = 0;
      this.costeSeguimiento = 0;
      this.costeDiferencia = 0;
      this.arrancadas = 0;
      this.arrancadasSeguimiento = 0;
      this.arrancadasDiferencia = 0;
      this.toneladasHora = 0;
      this.toneladasHoraSeguimiento = 0;
      this.toneladasHoraDiferencia = 0;
      this.productivo = 0;
      this.productivoSeguimiento = 0;
      this.productivoDiferencia = 0;
      this.cambio = 0;
      this.cambioSeguimiento = 0;
      this.cambioDiferencia = 0;
      this.parada = 0;
      this.paradaSeguimiento = 0;
      this.paradaDiferencia = 0;
      this.estado = null;
    }
  }

  GetIndicadoresPlantaDataGrid(indicadoresPlanta): any[] {
    const actuales = [];
    let contador = 0;

    Object.keys(indicadoresPlanta).forEach((actual) => {
      const o: GridObject = new GridObject();
      o.id = contador;
      contador++;

      switch (actual) {
        case 'coste': {
          o.parametro = actual;
          o.parametro = 'Coste';
          o.valor = `${indicadoresPlanta[actual].toFixed(1).toLocaleString('ES-es')} €`;

          if (indicadoresPlanta[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresPlanta[`${actual}Seguimiento`]
              .toFixed(1)
              .toLocaleString('ES-es')} €`;
            o.diferencia = `${(indicadoresPlanta[`${actual}Seguimiento`] - indicadoresPlanta[actual])
              .toFixed(1)
              .toLocaleString()} €`;
          }

          actuales.push(o);
          break;
        }
        case 'arrancadas': {
          o.parametro = 'Arrancadas';
          o.valor = `${indicadoresPlanta[actual]}`;

          if (indicadoresPlanta[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresPlanta[`${actual}Seguimiento`]}`;
            o.diferencia = `${indicadoresPlanta[`${actual}Seguimiento`] - indicadoresPlanta[actual]}`;
          }

          actuales.push(o);
          break;
        }
        case 'cambio': {
          o.parametro = 'Cambio';
          o.valor = `${indicadoresPlanta[actual]}`;

          if (indicadoresPlanta[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresPlanta[`${actual}Seguimiento`]}`;
            o.diferencia = `${(
              indicadoresPlanta[`${actual}Seguimiento`] - indicadoresPlanta[actual]
            ).toFixed(1)}`;
          }
          actuales.push(o);
          break;
        }
        case 'productivo':
        case 'parada': {
          o.parametro = actual === 'productivo' ? 'Productivo' : 'Parada';
          o.valor = `${indicadoresPlanta[actual].toFixed(1)} %`;

          if (indicadoresPlanta[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresPlanta[`${actual}Seguimiento`].toFixed(1)} %`;
            o.diferencia = `${(
              indicadoresPlanta[`${actual}Seguimiento`] - indicadoresPlanta[actual]
            ).toFixed(1)} %`;
          }

          actuales.push(o);
          break;
        }
        case 'toneladasHora': {
          o.parametro = 'Toneladas Hora';
          o.valor = `${indicadoresPlanta[actual].toFixed(1)} Tm`;

          if (indicadoresPlanta[`${actual}Seguimiento`]) {
            o.valorSeguimiento = `${indicadoresPlanta[`${actual}Seguimiento`].toFixed(1)} Tn`;
            o.diferencia = `${(
              indicadoresPlanta[`${actual}Seguimiento`] - indicadoresPlanta[actual]
            ).toFixed(1)} Tn`;
          }

          actuales.push(o);
          break;
        }

        default:
          break;
      }
    });
    return actuales;
  }
}
