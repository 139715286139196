/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';
import Graficas from '../../01.-Domain/Entities/Graficas/Graficas';
import Operacion from '../../01.-Domain/Entities/Operacion';
import Planificacion from '../../01.-Domain/Entities/Planificacion';
import PlanificacionOptimizada from '../../01.-Domain/Entities/PlanificacionOptimizada';
import Trabajo from '../../01.-Domain/Entities/Trabajo';
import PlanificacionRepository from '../../03.-Infraestructure/Repositories/PlanificacionRepository';
import PlantaRepository from '../../03.-Infraestructure/Repositories/PlantaRepository';
import IPlanificacionService from '../ServicesInterfaces/IPlanificacionService';
import ToastService from './Base/ToastService';
import { FormatearHorario } from './DateTimeService';
import IndicadoresService from './IndicadoresService';
import { plantaService } from './PlantaService';
import SeguimientoService from './SeguimientoService';
import TrabajoService from './TrabajoService';

class PlanificacionService implements IPlanificacionService {
  private _planificacionActual: Planificacion;

  public get planificacionActual(): Planificacion {
    return this._planificacionActual;
  }

  public set planificacionActual(v: Planificacion) {
    this._planificacionActual = v;
  }

  private _planificacionOptimizada: PlanificacionOptimizada;

  public get planificacionOptimizada(): PlanificacionOptimizada {
    return this._planificacionOptimizada;
  }

  public set planificacionOptimizada(v: PlanificacionOptimizada) {
    this._planificacionOptimizada = v;
  }

  private _graficasPlanificacionProgramada: Graficas;
  public get graficasPlanificacionProgramada(): Graficas {
    return this._graficasPlanificacionProgramada;
  }
  public set graficasPlanificacionProgramada(v: Graficas) {
    this._graficasPlanificacionProgramada = v;
  }

  constructor() {
    this.planificacionActual = new Planificacion();
    this.planificacionOptimizada = null;
    this.graficasPlanificacionProgramada = null;
  }

  /**
   * Obtiene las gráficas de la planificación
   * @returns Promise<boolean>
   */
  async GetGraficasPlanificacionProgramada(): Promise<boolean> {
    try {
      const res = await PlanificacionRepository.GetGraficas(this.planificacionOptimizada.id);
      this.graficasPlanificacionProgramada = new Graficas(res);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async SetPlantaActual() {
    if (!plantaService.plantaActual) {
      await plantaService.GetAll();
    }
  }

  /**
   * Obtiene la planificación programada
   * Si existe la planificación, se devuelve
   * si no, se hace la llamada a la API para obtenerla
   * @returns Promise<Planificacion>
   */
  async GetPlanificacionProgramada(): Promise<Planificacion> {
    let trabajosOrdenados = [];
    await this.SetPlantaActual();

    if (
      this.planificacionActual.id !== 0 &&
      this.planificacionActual.plantaId === plantaService.plantaActual.id
    ) {
      trabajosOrdenados = this.ReordenarTrabajoPredecesor(this.planificacionActual.trabajos);
      TrabajoService.trabajoPlanificado = trabajosOrdenados;
      this.planificacionActual.trabajos = TrabajoService.trabajoPlanificado;

      return this.planificacionActual;
    }

    try {
      const res = await PlantaRepository.GetPlanificacionProgramada(plantaService.plantaActual.id);
      if (res) {
        trabajosOrdenados = this.ReordenarTrabajoPredecesor(res.trabajos);
      }

      this.planificacionActual = new Planificacion(res);
    } catch (error) {
      console.log(error);
    }

    TrabajoService.trabajoPlanificado = trabajosOrdenados;
    this.planificacionActual.trabajos = TrabajoService.trabajoPlanificado;

    return this.planificacionActual;
  }

  /**
   * Situa los trabajos en relación a si tienen o son trabajos predecesores
   * @param tr Trabajo[]
   * @returns Trabajo[]
   */
  private ReordenarTrabajoPredecesor(tr: Trabajo[]): Trabajo[] {
    const trabajos: Trabajo[] = [];

    tr.forEach((element: Trabajo) => {
      if (tr.filter((x) => x.obra.id === element.obra.id).length > 1) {
        const ordenados = tr
          .filter((x: Trabajo) => x.obra.id === element.obra.id)
          .sort((op1, op2) => {
            return op1.id > op2.id ? 1 : -1;
          });

        for (let index = 0; index < ordenados.length; index++) {
          const tr = ordenados[index];
          tr.ordenTrabajoPredecesor = index + 1;
          if (tr.trabajoPredecesor && tr.ordenTrabajoPredecesor > 1) {
            tr.trabajoPredecesor = null;
            tr.tpObraDescripcion = null;
            tr.tpObraId = null;
          }
        }
      }

      trabajos.push(new Trabajo(element));
    });

    return trabajos;
  }

  /**
   * Obtiene la última planificación optimizada
   * Si esiste, se devuelve, si no, se llama a la API para obtenerla
   * @returns Promise<PlanificacionOptimizada>
   */
  async GetPlanificacionOptimizada(): Promise<PlanificacionOptimizada> {
    try {
      await this.SetPlantaActual();

      const res = await PlantaRepository.GetPlanificacionOptimizada(plantaService.plantaActual.id);
      if (typeof res !== 'string') {
        this.planificacionOptimizada = new PlanificacionOptimizada(res);

        this.planificacionOptimizada.operaciones = this.planificacionOptimizada.operaciones.sort(
          (a: Operacion, b: Operacion) =>
            a.inicioProgramado === null || a.inicioProgramado > b.inicioProgramado ? 1 : -1
        );

        IndicadoresService.CargaIndicadores(res);

        await this.GetGraficasPlanificacionProgramada();

        return res;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * Optimiza una planificación creada
   * @returns Promise<PlanificacionOptimizada>
   */
  async Optimizar(): Promise<boolean> {
    // Se actualizan los trabajos de la planificación por si hay
    // diferencias entre los actuales y lo mostrado en el grid
    this.planificacionActual.trabajos = TrabajoService.trabajoPlanificado;

    /**
     * Se crea una copia de la planificación para formatear horarios
     * para que se pueda enviar al back con los datos con formato correcto
     */
    const copiaParaOptimizar = JSON.parse(JSON.stringify(this.planificacionActual));

    copiaParaOptimizar.id = 0;
    copiaParaOptimizar.plantaId = plantaService.plantaActual.id;
    copiaParaOptimizar.trabajos.forEach((trabajo: Trabajo) => {
      trabajo.maxHorasExtra = trabajo.maxHorasExtra ? trabajo.maxHorasExtra : 0;
      /**
       * Si hay trabajo predecesor se formatea el horario y se
       * elimina un trabajo predecesor que pueda existir ya que
       * si no, crea un bucle y da un error.
       * En caso de que no exista trabajo predecesor, se actualiza el trabajo
       */
      if (trabajo.tpObraId) {
        // se añade el trabajo predecesor correspondiente
        trabajo.trabajoPredecesor = TrabajoService.trabajoPlanificado.find(
          (x: Trabajo) => x.obraId === trabajo.tpObraId
        );

        // Se elimina un posible trabajo predecesor anidado para evitar el error por un bucle
        trabajo.trabajoPredecesor.trabajoPredecesor = null;
        trabajo.trabajoPredecesor.horario = FormatearHorario(trabajo.trabajoPredecesor.horario);
      } else {
        trabajo.trabajoPredecesor = null;
      }

      // Se formatea el horario para el back
      trabajo.horario = FormatearHorario(trabajo.horario);
    });

    try {
      const res: PlanificacionOptimizada = await PlanificacionRepository.Optimizar(copiaParaOptimizar);
      this.planificacionOptimizada = new PlanificacionOptimizada(res);

      const nuevaPlanificacion = await PlanificacionRepository.getById(res.id);
      this.planificacionActual = nuevaPlanificacion;

      TrabajoService.trabajoPlanificado = [];
      nuevaPlanificacion.trabajos.forEach((element: Trabajo) => {
        TrabajoService.trabajoPlanificado.push(new Trabajo(element));
      });

      IndicadoresService.CargaIndicadores(res);
      await this.GetGraficasPlanificacionProgramada();

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * Elimina la planificación y sus datos
   * tras confirmar
   */
  public LimpiarPlanTrasconfirmar() {
    this.planificacionActual = null;
    this.planificacionOptimizada = null;
    this.graficasPlanificacionProgramada = null;
  }

  /**
   * Confirma una planificación
   * El retorno de la API es la planificación en seguimiento
   * @returns Promise<boolean>
   */
  async ConfirmarPlanificacion(): Promise<boolean> {
    const res = await PlanificacionRepository.ConfirmarPlanificacion(
      this.planificacionOptimizada.id,
      plantaService.plantaActual.id
    );

    if (res) {
      SeguimientoService.planificacionEnSeguimiento = res;
      IndicadoresService.CargaIndicadores(res);
      this.LimpiarPlanTrasconfirmar();

      return true;
    }

    return false;
  }

  /**
   * Comprueba los campos de la planificación
   * @param datosPlan any
   * @param trabajos Trabajo
   * @returns boolean
   */
  ComprobarCampos(): Promise<boolean> {
    const datosPlan = this.planificacionActual;

    if (datosPlan.descripcion === '') {
      this.MostrarError('El nombre de la programación no puede estar vacio');
      return Promise.resolve(false);
    }

    if (!datosPlan.fechaInicio || dayjs(datosPlan.fechaInicio).isBefore(dayjs().subtract(1, 'day'))) {
      this.MostrarError('La fecha no es correcta. Debe ser una fecha posterior al día de hoy');
      return Promise.resolve(false);
    }

    const nGrandes = this.planificacionActual.trabajos.filter((x: Trabajo) =>
      x.tipoVehiculo?.descripcion.toLowerCase().includes('grande')
    ).length;
    const nPequenos = this.planificacionActual.trabajos.filter((x: Trabajo) =>
      x.tipoVehiculo?.descripcion.toLowerCase().includes('peq')
    ).length;

    if (nGrandes > 0 && !datosPlan.numeroVehiculosGrandes) {
      this.MostrarError('Error en el número de vehiculos Grandes');
      return Promise.resolve(false);
    }

    if (nPequenos > 0 && !datosPlan.numeroVehiculosPequenos) {
      this.MostrarError('Error en el número de vehiculos Pequeños');
      return Promise.resolve(false);
    }

    if (datosPlan.trabajos.length === 0) {
      this.MostrarError('Debe incluir trabajos para realizar la planificación');
      return Promise.resolve(false);
    }

    return Promise.resolve(this.ComprobarTrabajos(datosPlan.trabajos));
  }

  private MostrarError(campo: string) {
    ToastService.error(campo);
  }

  /**
   * Comprueba los campos de un trabajo
   * @param tr Trabajo
   * @returns boolean
   */
  ComprobarTrabajos(trabajos: Trabajo[]) {
    let error = '';
    let errorTrabajoPredecesor = false;
    let errorTramosSinOrden = false;

    trabajos.forEach((tr) => {
      const repetido = trabajos.filter((x: Trabajo) => x.obra.id === tr.obra.id);
      if (repetido.length > 1) {
        if (!tr.ordenTrabajoPredecesor) {
          this.MostrarError('Los tramos tienen que ordenarse mediante la columna "orden"');
          errorTramosSinOrden = true;
          return;
        }
      }

      if (
        !tr.obra.location.latitud ||
        !tr.obra.location.longitud ||
        tr.obra.location.latitud === plantaService.plantaActual.location.latitud ||
        tr.obra.location.longitud === plantaService.plantaActual.location.longitud
      ) {
        error = 'Localización Obra';
        return;
      }

      if (
        tr.tpObraId &&
        tr.ordenTrabajoPredecesor &&
        tr.ordenTrabajoPredecesor > 1 &&
        tr.tpObraId !== tr.obra.id
      ) {
        this.MostrarError('El trabajo predecesor no puede ser diferente cuando se ordenan los tramos');
        errorTrabajoPredecesor = true;
        return;
      }

      if (!tr.tipoVehiculo) {
        error = 'Vehículo';
        return;
      }

      if (!tr.capataz || tr.capataz === '') {
        error = 'Capataz';
        return;
      }

      if (tr.cantidadExtendido < 1) {
        error = 'Cantidad Extendido';
        return;
      }

      if (!tr.mezcla) {
        error = 'Mezcla';
        return;
      }

      if (tr.prioridad < 0 && tr.prioridad > 3) {
        error = 'Prioridad';
        return;
      }

      if (!tr.tipoVehiculo) {
        error = 'Tipo Vehículo';
        return;
      }

      if (tr.velocidad < 1) {
        error = 'Velocidad';
        return;
      }

      if (tr.numeroOficiales === null) {
        error = 'Nº Oficiales';
        return;
      }

      if (!tr.numeroPeones === null) {
        error = 'Nº Peones';
        return;
      }

      if (tr.maxHorasExtra && tr.maxHorasExtra < 0) {
        error = 'Max. Horas Extras';
        return;
      }

      if (tr.costeMaquinas === null) {
        error = 'Coste Máquina';
        return;
      }

      if (tr.costeTramoTrans === null) {
        error = 'Coste Tramo';
        return;
      }

      if (tr.tiempoTransporteMinutos === null) {
        error = 'Tiempo Transporte';
        return;
      }
    });

    if (errorTrabajoPredecesor || errorTramosSinOrden) {
      return false;
    }

    if (error !== '') {
      this.MostrarError(`El siguiente campo no puede ser nulo: ${error}`);
      return false;
    }

    return true;
  }
}

export default new PlanificacionService();
