import dayjs from 'dayjs';
import FranjaHoraria from '../../01.-Domain/Entities/FranjaHoraria';

/**
 * Convierte un TimeSpan en una fecha
 * con el formato 'HH:mm:ss'
 * @param timespan string
 * @returns Date
 */
function ConvertTimeSpanToDate(timespan: Date | string): Date {
  let fecha;
  if (typeof timespan === 'string') {
    fecha = dayjs(timespan);
    if (fecha.isValid()) {
      return fecha.toDate();
    }
    const hora = timespan.substring(0, 2);
    const minuto = timespan.substring(3, 5);
    const segundos = timespan.substring(6);

    return dayjs().hour(Number(hora)).minute(Number(minuto)).second(Number(segundos)).toDate();
  } else if (timespan instanceof Date) {
    fecha = dayjs(timespan);
    return fecha.toDate();
  }
}

/**
 * Convirte un listado de franjas en formato texto a
 * un listado de objetos
 * @param franjas FranjaDTO[]
 */
function ConvertirTextoEnFranja(franjas: string): FranjaHoraria[] {
  const f: FranjaHoraria[] = [];
  const fr: string[] = franjas.split('//');

  fr.forEach((comp: string) => {
    const inicio = ConvertTimeSpanToDate(comp.trim().split('-')[0]);
    const fin = ConvertTimeSpanToDate(comp.trim().split('-')[1]);

    f.push({ id: 0, inicio, fin });
  });

  return f;
}

/**
 * Convierte un formato fecha en un formato string
 * para adaptarlo al TimeSpan
 * @param date Date
 * @returns string
 */
function ConvertDateToTimeSpan(date: Date | string): string {
  return typeof date === 'string' ? date : dayjs(date).format('HH:mm:ss');
}

/**
 * Convierte un objeto Date en un formato que se pueda enviar
 * a un TimeSpan en la API
 * @param fr FranjaHoraria[]
 * @returns FranjaHoraria[]
 */
function FormatearHorario(fr: FranjaHoraria[]): FranjaHoraria[] {
  fr.forEach((fr: FranjaHoraria) => {
    fr.id = 0;
    fr.inicio =
      typeof fr.inicio === 'string' && fr.inicio.indexOf('-') === -1
        ? fr.inicio
        : dayjs(fr.inicio).format('HH:mm:ss');
    fr.fin =
      typeof fr.fin === 'string' && fr.fin.indexOf('-') === -1 ? fr.fin : dayjs(fr.fin).format('HH:mm:ss');
  });

  return fr;
}

/**
 * Devuelve un texto en formato de HH:mm a partir de un número
 * @param number number
 * @returns string
 */
function NumberToTimeFormat(number: number) {
  return (
    Math.floor(number / 60) +
    ':' +
    Math.ceil(number % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
  );
}

export {
  ConvertTimeSpanToDate,
  ConvertirTextoEnFranja,
  ConvertDateToTimeSpan,
  FormatearHorario,
  NumberToTimeFormat
};
