import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';

interface Props {
  enabled: boolean;
  texto: string;
}

export const LoaderComponent: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(props.enabled);
  }, []);

  return (
    <Backdrop
      open={open}
      style={{
        display: 'grid',
        justifyContent: 'center',
        justifyItems: 'center',
        alignContent: 'center'
      }}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress size={90} variant="indeterminate" color="primary" />
      <h2 style={{ textAlign: 'center', marginTop: '30px' }}>{props.texto}</h2>
    </Backdrop>
  );
};

export default LoaderComponent;
