import { LatLngExpression } from 'leaflet';
import BaseEntity from './Base/BaseEntity';

export default class Marcador extends BaseEntity {
  id: number;
  matricula: string;
  position: LatLngExpression;
  descripcion?: string;
  clase: string;

  constructor(
    id: number,
    position: LatLngExpression,
    clase?: string,
    matricula?: string,
    descripcion?: string
  ) {
    super();

    this.id = id;
    this.matricula = matricula;
    this.position = position;
    this.clase = clase;
    this.descripcion = descripcion;
  }
}
