import { Button, ValidationGroup, Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FranjaHoraria from '../../../01.-Domain/Entities/FranjaHoraria';
import Planta from '../../../01.-Domain/Entities/Planta';
import TipoVehiculo from '../../../01.-Domain/Entities/TipoVehiculo';
import ToastService from '../../../02.-Application/Services/Base/ToastService';
import { plantaService } from '../../../02.-Application/Services/PlantaService';
import TipoVehiculoService from '../../../02.-Application/Services/TipoVehiculoService';
import VehiculoService from '../../../02.-Application/Services/VehiculoService';
import ConfirmPopUp from '../../Components/ConfirmPopUp/ConfirmPopUp';
import DateBoxComponent, { DateBoxProps } from '../../Components/CoreComponents/DateBoxComponent';
import NumberBoxComponent, { NumberBoxProps } from '../../Components/CoreComponents/NumberBoxComponent';
import LoaderComponent from '../../Components/Loader/LoaderComponent';
import PopupJornada from '../Planificacion/PopupJornada';
import './ConfiguracionView.scss';
import ControlHorario from './ControlHorario';
import {
  GetConfiguracionGeneral,
  GetCostesOperario,
  GetCostesPlanta,
  GetHorarioObras
} from './GetComponentData';

export interface Props {
  permisos: boolean;
}

const ConfiguracionPlantaView: React.FC<Props> = (props) => {
  const [setLoader, setsetLoader] = useState<boolean>(false);
  const [textoLoader, setTextoLoader] = useState<string>('');
  const [planta, setPlanta] = useState<Planta>(null);
  const [vehiculos, setVehiculos] = useState<TipoVehiculo[]>([]);
  const [horarioPlanta, setHorarioPlanta] = useState(null);
  const mensajeRequerido: string = 'El campo no puede ser nulo';

  const [verPopupJornadas, setverPopupJornadas] = useState<boolean>(false);
  const [verPopupConfirmar, setVerPopupConfirmar] = useState<boolean>(false);
  const [event, setEvent] = useState(null);

  async function GetData() {
    setsetLoader(false);

    if (!plantaService.plantaActual) {
      await plantaService.GetAll().catch((error) => {
        console.log(error);
        ToastService.error('No se ha podido obtener la planta');
      });
    }

    await TipoVehiculoService.GetAll().catch((error) => {
      console.log(error);
      ToastService.error('No se han podido obtener los vehículos');
    });

    setPlanta(plantaService.plantaActual);
    setHorarioPlanta(plantaService.plantaActual.horario);
    setVehiculos(TipoVehiculoService.tipoVehiculos);

    setsetLoader(true);
  }

  useEffect(() => {
    if (!props.permisos) {
      return;
    }

    setTextoLoader('Cargando datos iniciales');
    GetData();
  }, []);

  async function AplicarCambios() {
    const result = event.validationGroup.validate();

    if (horarioPlanta.length === 0) {
      ToastService.error('La planta debe tener como mínimo una franja horaria');
      return;
    }

    if (!result.isValid) {
      ToastService.error('Todos los campos deben tener un valor');
      return;
    }

    await plantaService.ActualizarPlanta().then(async (res) => {
      if (!res) {
        ToastService.error('Ha habido un error actualizando la planta');
      } else {
        await VehiculoService.ActualizarVehiculos(vehiculos).then((res) => {
          if (!res) {
            ToastService.error('Ha habido un error actualizando las compensaciones');
          } else {
            setPlanta(plantaService.plantaActual);
            ToastService.success('Configuración aplicada correctamente');
            setVerPopupConfirmar(false);
          }
        });
      }
    });
  }

  /**
   * Localiza el trabajo que se está editando y se modifica el horario
   * @param franjas FranjaHoraria[]
   */
  function GuardaFranjaHorarias(franjas: FranjaHoraria[]) {
    setHorarioPlanta(franjas);
    planta.horario = franjas;
  }

  function ValidarCampo(e: any) {
    if (!e.isValid) {
      console.log(`El campo ${e.component.option('name')} no puede ser nulo`);
      return;
    }
  }

  if (!setLoader) {
    return <LoaderComponent enabled texto={textoLoader} />;
  }

  return (
    <>
      {verPopupJornadas && (
        <PopupJornada
          show={verPopupJornadas}
          cerrarPopup={() => setverPopupJornadas(!verPopupJornadas)}
          GuardarFranja={GuardaFranjaHorarias}
        />
      )}

      <ConfirmPopUp
        show={verPopupConfirmar}
        texto={'¿Desea guardar la configuración?'}
        titulo="Guardar Configuración"
        confirmar={AplicarCambios}
        cancelar={() => setVerPopupConfirmar(false)}
      />

      {setLoader && (
        <ValidationGroup>
          <Container fluid>
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              <Col md={12}>
                <Container fluid>
                  <Row>
                    <Col title="Horario de las obras por defecto" md={5} sm={6}>
                      <Container fluid>
                        <Row>
                          <Col className="titulo">Horario de las obras</Col>
                        </Row>
                        <Row>
                          {GetHorarioObras().map((item: DateBoxProps) => {
                            return (
                              <Col key={item.id} md={6}>
                                <DateBoxComponent
                                  nombre={item.nombre}
                                  texto={item.texto}
                                  defaultValue={item.defaultValue}
                                  onValueChange={item.onValueChange}
                                  tipo={item.tipo}
                                />
                              </Col>
                            );
                          })}
                        </Row>
                      </Container>
                    </Col>
                    <Col title="Horario de la planta por defecto" md={6} sm={6}>
                      {/* Horario de la planta */}
                      <Container fluid>
                        <Row>
                          <Col className="titulo">Horario de la planta</Col>
                        </Row>
                        <Row>
                          <Col md={8}>
                            {horarioPlanta.map((fr: FranjaHoraria) => (
                              <ControlHorario key={fr.id} horario={fr} />
                            ))}
                          </Col>
                          <Col md={3} className="mt-4">
                            <button
                              style={{ border: 0, backgroundColor: '#FFF' }}
                              title="Editar Configuración de Planta"
                              onClick={() => setverPopupJornadas(true)}>
                              <span className="material-icons green600">edit</span>
                            </button>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>

            {/* Configuración de la planta */}

            <Row className="contenedorPrincipal">
              <Col md={4}>
                <Container fluid>
                  {GetConfiguracionGeneral().map((item: NumberBoxProps) => {
                    return (
                      <Row key={item.id} className={item.rowClass} title={item.title}>
                        <Col md={7}>
                          <span>{item.colText}</span>
                        </Col>
                        <Col md={5}>
                          <NumberBoxComponent
                            nombre={item.nombre}
                            onValueChange={(e: any) => item.onValueChange(e)}
                            defaultValue={item.defaultValue}
                            min={item.min}
                            showSpinButton={item.showSpinButton}>
                            <Validator onValidated={ValidarCampo} name="Distancia Planta">
                              <RequiredRule message={mensajeRequerido} />
                            </Validator>
                          </NumberBoxComponent>
                        </Col>
                      </Row>
                    );
                  })}
                </Container>
              </Col>

              <Col md={4}>
                <Container fluid>
                  {GetCostesOperario(planta).map((item: NumberBoxProps) => {
                    return (
                      <Row key={item.id} className={item.rowClass} title={item.title}>
                        <Col md={7}>
                          <span>{item.colText}</span>
                        </Col>
                        <Col md={5}>
                          <NumberBoxComponent
                            nombre={item.nombre}
                            onValueChange={(e: any) => item.onValueChange(e)}
                            defaultValue={item.defaultValue}
                            min={item.min}
                            showSpinButton={item.showSpinButton}>
                            <Validator onValidated={ValidarCampo} name="Distancia Planta">
                              <RequiredRule message={mensajeRequerido} />
                            </Validator>
                          </NumberBoxComponent>
                        </Col>
                      </Row>
                    );
                  })}
                </Container>
              </Col>

              <Col md={4}>
                <Container fluid>
                  {GetCostesPlanta(planta, vehiculos).map((item: NumberBoxProps) => {
                    return (
                      <Row key={item.id} className={item.rowClass} title={item.title}>
                        <Col md={7}>
                          <span>{item.colText}</span>
                        </Col>
                        <Col md={5}>
                          <NumberBoxComponent
                            nombre={item.nombre}
                            onValueChange={(e: any) => item.onValueChange(e)}
                            defaultValue={item.defaultValue}
                            min={item.min}
                            showSpinButton={item.showSpinButton}>
                            <Validator onValidated={ValidarCampo} name="Distancia Planta">
                              <RequiredRule message={mensajeRequerido} />
                            </Validator>
                          </NumberBoxComponent>
                        </Col>
                      </Row>
                    );
                  })}
                </Container>
              </Col>

              <Col>
                <Container fluid>
                  <Row>
                    <Col md="12" sm="5" className="my-4">
                      <Button
                        text="Guardar Configuración"
                        type="default"
                        stylingMode="contained"
                        onClick={(e: any) => {
                          setEvent(e);
                          setVerPopupConfirmar(true);
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </ValidationGroup>
      )}
    </>
  );
};

export default ConfiguracionPlantaView;
