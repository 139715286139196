import EventNoteIcon from '@mui/icons-material/EventNote';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewListIcon from '@mui/icons-material/ViewList';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { SelectBox } from 'devextreme-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Ventana from '../../../01.-Domain/Entities/Autentication/Ventana';
import Planta from '../../../01.-Domain/Entities/Planta';
import AutenticationService from '../../../02.-Application/Services/AutenticationService';
import { plantaService } from '../../../02.-Application/Services/PlantaService';
import { AppBar, Main } from './AppBar';
import DrawerDocker from './DrawerDocker';
import DrawerHeader from './DrawerHeader';
import ListItemComponent from './ListItemComponent';

const drawerWidth = 300;

type BarItem = {
  id: number;
  name: string;
  url: string;
  primaryText: string;
  optionSelected: () => void;
  icon: any;
};

const DrawerComponent: React.FC = () => {
  const [plantaActual, setPlantaActual] = React.useState<Planta>(plantaService.plantaActual);
  const [plantas, setPlantas] = useState<Planta[]>([]);
  const [items, setItems] = useState<BarItem[]>([]);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setPlantaActual(plantaService.plantaActual);
    setPlantas(plantaService.plantas);
  }, [plantaService.plantaActual]);

  function HandleDrawerOpen() {
    const ventanas = AutenticationService.permisos;
    GetItems(ventanas.data.ventanas);
    setOpen(true);
  }

  function HandleDrawerClose() {
    setOpen(false);
  }

  function GoToInit() {
    navigate('/', { replace: true });
  }

  function LogOut() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('permisos');
    sessionStorage.removeItem('plantaActual');
    sessionStorage.removeItem('plantas');
    plantaService.plantas = [];
    plantaService.plantaActual = null;
    GoToInit();
  }

  function CambiarPlanta(e: any) {
    plantaService.SeleccionarPlanta(e.value);
    GoToInit();
  }

  function GetItems(ventanas?: Ventana[]) {
    const retorno: BarItem[] = [
      {
        id: 0,
        name: 'inicio',
        url: '/',
        primaryText: 'Inicio',
        optionSelected: HandleDrawerClose,
        icon: <HomeIcon fontSize="large" />
      }
    ];

    let items = retorno;
    items = [
      {
        id: 1,
        name: 'CONF_PLANTA',
        url: '/configuracionPlanta',
        primaryText: 'Configuración Planta',
        optionSelected: HandleDrawerClose,
        icon: <SettingsIcon fontSize="large" />
      },
      {
        id: 2,
        name: 'PLANIF',
        url: '/planificacion',
        primaryText: 'Planificación',
        optionSelected: HandleDrawerClose,
        icon: <EventNoteIcon fontSize="large" />
      },
      {
        id: 3,
        name: 'PLANIF_OPT',
        url: '/PlanificacionOptimizada',
        primaryText: 'Planificación Optimizada',
        optionSelected: HandleDrawerClose,
        icon: <ViewListIcon fontSize="large" />
      },
      {
        id: 4,
        name: 'SEGUIMIENTO',
        url: '/seguimiento',
        primaryText: 'Seguimiento',
        optionSelected: HandleDrawerClose,
        icon: <LocalShippingIcon fontSize="large" />
      }
    ];

    if (!ventanas) {
      setItems(items);
      return;
    }

    items.forEach((element) => {
      if (ventanas.some((x) => x.nombreVentana === element.name)) {
        if (AutenticationService.EsResponsablePlanta() && element.name === 'PLANIF') {
          return;
        }

        retorno.push(element);
      }
    });

    setItems(retorno);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ background: '#E9E9E9' }}>
        <Toolbar sx={{ color: '#000' }}>
          <Row className="d-flex justify-content-around" style={{ width: '100%' }}>
            <Col>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={HandleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}>
                <MenuIcon />
              </IconButton>
            </Col>
            <Col>
              <Button style={{ backgroundColor: '#E9E9E9', border: 0, float: 'right' }} onClick={LogOut}>
                <span title="Cerrar Sesión" className="material-icons">
                  logout
                </span>
              </Button>
            </Col>
          </Row>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#E9E9E9'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}>
        <DrawerDocker setOpen={HandleDrawerClose} />
        <Divider />
        <List>
          <Col className="px-4 py-2">
            <SelectBox
              onValueChanged={CambiarPlanta}
              defaultValue={plantaActual?.id}
              items={plantas}
              displayExpr="nombre"
              valueExpr="id"
              placeholder="Selecciona una planta"
              showClearButton={false}
            />
          </Col>

          {items.map((item: BarItem) => (
            <ListItemComponent
              key={item.id}
              name={item.name}
              url={item.url}
              primaryText={item.primaryText}
              optionSelected={item.optionSelected}
              icon={item.icon}
            />
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
};
export default DrawerComponent;
