import 'dayjs/locale/es';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AutenticationService from '../../../02.-Application/Services/AutenticationService';
import logoAvi from '../../../assets/images/LogoAvi.jpg';
import logoPavasal2 from '../../../assets/images/Marca-PAVASAL.png';
import LoaderComponent from '../../Components/Loader/LoaderComponent';
import './main.scss';

const Main: React.FC = () => {
  const [setLoader, setsetLoader] = useState<boolean>(true);
  const [textoLoader, setTextoLoader] = useState<string>('');
  const [puedePlanificar, setPuedePlanificar] = useState<boolean>(false);

  useEffect(() => {
    setTextoLoader('Cargando datos iniciales');
    if (sessionStorage.getItem('token') !== null) {
      GetData();
    }
  }, [sessionStorage.getItem('token')]);

  async function GetData() {
    setsetLoader(false);
    // Si no tiene permisos para planificar, no se muestra el botón iniciar
    setPuedePlanificar(AutenticationService.PuedePlanificar());
    await AutenticationService.ObtenerPermisos(sessionStorage.getItem('token'));
    setsetLoader(true);
  }

  if (!setLoader) {
    return <LoaderComponent enabled texto={textoLoader} />;
  }

  return (
    <>
      <div className="container fluid App">
        <div className="row">
          <div className="col d-flex justify-content-center">
            <h1 style={{ fontSize: '8em', fontWeight: 'bold' }}>IASF</h1>
          </div>
          <div className="col d-flex justify-content-center">
            <img style={{ zoom: '100%' }} src={logoPavasal2} alt="Pavasal" title="Logo_Pavasal" />
          </div>
        </div>

        {puedePlanificar && (
          <div className="row">
            <div className="col d-flex justify-content-center pt-5">
              <Link to="/planificacion">
                <Button variant="success" size="lg">
                  Iniciar
                </Button>
              </Link>
            </div>
          </div>
        )}
        <div className="row d-flex" style={{ position: 'absolute', right: '2em', bottom: '2em' }}>
          <div className="col-12 d-flex justify-content-end" style={{ marginTop: 'auto' }}>
            <img src={logoAvi} alt="AVI" title="Logo_AVI" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
