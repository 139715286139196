import { EstadoPlanificacionEnum } from '../../Enums/EstadoPlanificacionEnum';
import BaseEntity from '../Base/BaseEntity';
import Obra from '../Obra';

export default class IndicadoresObra extends BaseEntity {
  coste: number;
  costeSeguimiento: number;
  costeDiferencia: number;
  paradas: number;
  paradasSeguimiento: number;
  paradasDiferencia: number;
  horasExtra: number;
  horasExtraSeguimiento: number;
  horasExtraDiferencia: number;
  productivo: number;
  productivoSeguimiento: number;
  productivoDiferencia: number;
  parada: number;
  paradaSeguimiento: number;
  paradaDiferencia: number;
  obra: Obra;
  estado: string;

  constructor(obj?: IndicadoresObra[]) {
    super();

    if (obj.length) {
      obj.forEach((indicador: IndicadoresObra) => {
        if (EstadoPlanificacionEnum[indicador.estado] === EstadoPlanificacionEnum[0]) {
          this.id = indicador.id;
          this.coste = indicador.coste;
          this.paradas = indicador.paradas;
          this.horasExtra = Number(Math.ceil(indicador.horasExtra).toFixed(1));
          this.productivo = indicador.productivo;
          this.parada = indicador.parada;
          this.estado = EstadoPlanificacionEnum[indicador.estado];
          this.obra = new Obra(indicador.obra);
        }

        if (EstadoPlanificacionEnum[indicador.estado] === EstadoPlanificacionEnum[1]) {
          this.costeSeguimiento = indicador.coste;
          this.paradasSeguimiento = isNaN(indicador.paradas) ? null : indicador.paradas;
          this.horasExtraSeguimiento = Number(Math.ceil(indicador.horasExtra).toFixed(1));
          this.productivoSeguimiento = indicador.productivo;
          this.paradaSeguimiento = indicador.parada;
          this.costeDiferencia = this.costeSeguimiento - this.coste;
          this.paradasDiferencia = isNaN(this.paradasSeguimiento - this.paradas)
            ? null
            : this.paradasSeguimiento - this.paradas;
          this.horasExtraDiferencia = Number(
            Math.ceil(this.horasExtraSeguimiento - this.horasExtra).toFixed(1)
          );
          this.productivoDiferencia = this.productivoSeguimiento - this.productivo;
          this.paradaDiferencia = this.paradaSeguimiento - this.parada;
        }
      });
    } else {
      this.coste = 0;
      this.costeSeguimiento = 0;
      this.costeDiferencia = 0;
      this.paradas = 0;
      this.paradasSeguimiento = 0;
      this.paradasDiferencia = 0;
      this.horasExtra = 0;
      this.horasExtraSeguimiento = 0;
      this.horasExtraDiferencia = 0;
      this.productivo = 0;
      this.productivoSeguimiento = 0;
      this.productivoDiferencia = 0;
      this.parada = 0;
      this.paradaSeguimiento = 0;
      this.paradaDiferencia = 0;
      this.estado = null;
      this.obra = null;
    }
  }
}
