const columnas = [
  {
    dataField: 'id',
    caption: 'Id',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'matricula',
    caption: 'Matrícula',
    dataType: 'string'
  },
  {
    dataField: 'tipoVehiculo.nombre',
    caption: 'Tipo',
    dataType: 'string'
  }
];

const columnasInicioFinExtendido = [
  {
    dataField: 'id',
    caption: 'Obra FK',
    dataType: 'number',
    visible: false
  },
  {
    dataField: 'idOrigen',
    caption: 'Obra Id',
    dataType: 'string'
  },
  {
    dataField: 'descripcion',
    caption: 'Descripción',
    dataType: 'string'
  }
];

export { columnas, columnasInicioFinExtendido };
