import React, { useEffect, useState } from 'react';
import { Button } from 'devextreme-react';
import { Link, useNavigate } from 'react-router-dom';
import AutenticationService from '../../../02.-Application/Services/AutenticationService';
import toastService from '../../../02.-Application/Services/Base/ToastService';
import IndicadoresService from '../../../02.-Application/Services/IndicadoresService';
import PlanificacionService from '../../../02.-Application/Services/PlanificacionService';
import Grid from '../../Components/DataGrid/Grid';
import PopupIndicadores from '../../Components/Indicadores/PopupIndicadores';
import LoaderComponent from '../../Components/Loader/LoaderComponent';
import { columnas } from './ColumnasPlanificacion';
import './PlanificacionOptimizada.scss';

export interface Props {
  permisos: boolean;
}

const PlanificacionOptimizadaView: React.FC<Props> = (props) => {
  const [setLoader, setsetLoader] = useState<boolean>(false);
  const [textoLoader, setTextoLoader] = useState<string>('');
  const [verPopupIndicadores, setVerPopupIndicadores] = useState<boolean>(false);
  const [permisoEspecial, setPermisoEspecial] = useState<boolean>(
    AutenticationService.EsResponsablePlanta()
  );

  const navigate = useNavigate();

  const AbrirPopupIndicadores = () => {
    setVerPopupIndicadores(!verPopupIndicadores);
  };

  const GetData = async () => {
    setsetLoader(false);
    setTextoLoader('Cargando Planificación Optimizada');

    await PlanificacionService.GetPlanificacionOptimizada().catch(() => {
      toastService.error('Error cargando la planificación');
    });

    setsetLoader(true);
  };

  useEffect(() => {
    if (!props.permisos) {
      return;
    }

    GetData();

    setPermisoEspecial(AutenticationService.EsResponsablePlanta());
  }, []);

  const ConfirmarPlan = async () => {
    await PlanificacionService.ConfirmarPlanificacion()
      .then(() => {
        navigate('/seguimiento', { replace: true });
      })
      .catch(() => {
        toastService.error(
          'No se puede confirmar la planificación. Compruebe que no hay ningún plan por finalizar'
        );
      });
  };

  if (!setLoader) {
    return <LoaderComponent enabled texto={textoLoader} />;
  }

  const btnEnabled = () => {
    return PlanificacionService.planificacionOptimizada !== null && !permisoEspecial;
  };

  return (
    <>
      {verPopupIndicadores && (
        <PopupIndicadores
          indicadoresGlobales={IndicadoresService.indicadoresGlobales}
          indicadoresPlanta={IndicadoresService.indicadoresPlanta}
          indicadoresTransporte={IndicadoresService.indicadoresTransporte}
          indicadoresObra={IndicadoresService.indicadoresObra}
          graficas={PlanificacionService.graficasPlanificacionProgramada}
          show={verPopupIndicadores}
          cerrarPopup={() => setVerPopupIndicadores(!verPopupIndicadores)}
        />
      )}
      <div className="row">
        <div className="col-8 m-2">
          <Link to={'/planificacion'}>
            <Button
              text="Modificar"
              visible={btnEnabled()}
              type="default"
              className="btnAccion"
              stylingMode="contained"
              aria-hidden
            />
          </Link>
          <Button
            text="Confirmar"
            type="default"
            className="btnAccion"
            stylingMode="contained"
            aria-hidden
            visible={btnEnabled()}
            onClick={ConfirmarPlan}
          />

          <Button
            text="Ver indicadores"
            type="default"
            className="btnAccion"
            stylingMode="contained"
            aria-hidden
            onClick={AbrirPopupIndicadores}
          />
        </div>
        <div className="col-3">
          <h2 className="mr-5">{PlanificacionService.planificacionOptimizada?.descripcion}</h2>
        </div>
      </div>
      <div className="row p-4">
        <div className="col">
          <Grid
            source={PlanificacionService.planificacionOptimizada?.operaciones}
            altura={190}
            columns={columnas}
            editable={false}
            enableColumnChooser
            summaryColumnCount="etapa"
          />
        </div>
      </div>
    </>
  );
};

export default PlanificacionOptimizadaView;
