import { ConvertTimeSpanToDate } from '../../02.-Application/Services/DateTimeService';
import BaseEntity from './Base/BaseEntity';

export default class FranjaHoraria extends BaseEntity {
  inicio: Date | string;

  fin: Date | string;

  constructor(obj?: any) {
    super();

    if (obj) {
      this.id = obj.id;
      this.inicio = typeof obj.inicio === 'string' ? ConvertTimeSpanToDate(obj.inicio) : obj.inicio;
      this.fin = typeof obj.fin === 'string' ? ConvertTimeSpanToDate(obj.fin) : obj.fin;
    } else {
      this.inicio = null;
      this.fin = null;
    }
  }
}
